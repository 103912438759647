import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import BonusInfo from "../../../bonus/BonusInfo";
import { BonusContext, CatalogContext, UserContext } from "../../../../store";
import { Item as BonusItem, calculateOrderBonusInfo } from "../../../../../ts-shared/bonus";

type Props = {
    readonly useBonuses: boolean;
    readonly onUseBonusChange: (useBonuses: boolean) => void;
    readonly items: BonusItem[];
}

const OrderApprovalBonus: FC<Props> = (p) => {
    const bonus = useContext(BonusContext);
    const user = useContext(UserContext);
    const catalog = useContext(CatalogContext);

    const bonusesEnabled = bonus.status?.enabled;
    const context = catalog.promoContext;
    const balance = bonus.status?.balance;
    const u = user.user;

    if(bonusesEnabled === undefined || context === undefined || balance === undefined || u === undefined) return <></>;

    const passUser = {
        id: u.id, type: u.type, matrixCode: u.matrixCode, 
        parentPriceCode: u.parentPriceCode, bonusesEnabled
    };

    const info = calculateOrderBonusInfo(p.items, balance, passUser, context);

    return (
        <BonusInfo
            mode="cart"
            available={info.available}
            toGive={info.toGive}
            useBonuses={p.useBonuses}
            onUseBonusesChange={p.onUseBonusChange}
        />
    );
}

export default observer(OrderApprovalBonus);