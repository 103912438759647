import {observer} from "mobx-react-lite";
import ContextPopover from "../../../ContextPopover";
import {
    Box, Button,
    Fab,
    List as MuiList,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import TemplateIcon from "@mui/icons-material/BrowserUpdated";
import ExcelUploadIcon from "@mui/icons-material/FileUpload";
import * as React from "react";
import {AxiosError} from "axios";
import { Item } from "../../../../common/item";
import {useContext, useRef, useState} from "react";
import {AppContext, CartContext, UserContext} from "../../../../store";

type Props = {
    readonly onUploadBegin?: () => void;
    readonly onEndUploadEnd?: (ok: boolean) => void;
}

const b64toBlob = (base64, type) =>
    fetch(`data:${type};base64,${base64}`).then(res => res.blob());

const UploadResultDialog = (p: {
    found: number,
    total: number,
    onShowErrorsClick: () => void,
    onUploadFoundClick: () => void,
}) => {

    return (<>
        <Stack spacing={1}>
            <Typography variant="subtitle1">В файле найдены ошибки</Typography>
            <Typography>
                Всего товаров: {p.total}<br/>
                Найдено: {p.found} <br/>
            </Typography>
            <Button variant="outlined" size="large" onClick={p.onShowErrorsClick}>
                Показать ошибки
            </Button>
            <Button variant="contained"  size="large" onClick={p.onUploadFoundClick}>
                Добавить найденные товары
            </Button>
        </Stack>
    </>);
}
let _isSending = false;

const UploadXLSFloatingButton = (p: Props) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);

    const fileRef = useRef<HTMLInputElement>();
    const [isSending, setIsSending] = useState(_isSending);

    return (
        <>
            <Box
                ref={fileRef}
                component="input"
                type="file"
                name="file"
                sx={{ position: 'absolute', top: -99999 }}
                onChange={e => {
                    const file: File = e.target.files[0];
                    if(!file) return;
                    e.target.value = null;

                    (() => {
                        p.onUploadBegin && p.onUploadBegin();
                        _isSending = true;
                        setIsSending(_isSending);
                        app.alert('Идёт отправка...', { time: null });
                        cart.uploadXLS(file, user.user?.id)
                            .then(() => {
                                p.onEndUploadEnd && p.onEndUploadEnd(true);
                                app.alert('Товары из файла добавлены в корзину', { severity: 'success' })
                            })
                            .catch(async (e: AxiosError) => {
                                p.onEndUploadEnd && p.onEndUploadEnd(false);

                                if((e.response?.data as any)?.data) {
                                    const data = (e.response.data as any).data as {
                                        markedFile: string,
                                        foundRows: number,
                                        totalRows: number,
                                        foundItems: { item: Item, amount: number }[],
                                    };

                                    app.closeSnackbar();

                                    app.showDialog(
                                        <UploadResultDialog
                                            found={data.foundRows}
                                            total={data.totalRows}
                                            onShowErrorsClick={async () => {
                                                app.closeDialog();
                                                const blob = await b64toBlob(data.markedFile as any, e.response.headers['content-type']);
                                                const link = document.createElement('a');
                                                link.href = window.URL.createObjectURL(blob);
                                                link.download = 'errors.xls';

                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                window.URL.revokeObjectURL(link.href);
                                                app.alert('Откройте скачанный файл', { severity: 'info' });
                                            }}
                                            onUploadFoundClick={async () => {
                                                app.alert('Добавляем...', { time: null });
                                                await cart.addManyWithAmount(data.foundItems, user.user?.id);
                                                app.closeDialog();
                                                app.alert('Часть товаров добавлена в корзину', { severity: 'success' });
                                            }}
                                        />
                                    );
                                }
                                else {
                                    app.alert((e.response?.data as any)?.meta?.message || 'Ошибка отправки файла', { severity: 'error' });
                                }
                            })
                            .finally(() => {
                                _isSending = false;
                                setIsSending(_isSending);
                            })
                        ;
                    })();

                }}
            />
            <Box sx={{position: 'absolute', right: 0}}>
                <ContextPopover
                    content={
                        <MuiList>
                            <ListItem disablePadding>
                                <ListItemButton
                                    href="/cart/spreadsheet-template"
                                >
                                    <ListItemIcon>
                                        <TemplateIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Скачать шаблон" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    disabled={isSending}
                                    onClick={() => {
                                        fileRef.current.click();
                                    }}
                                >
                                    <ListItemIcon>
                                        <ExcelUploadIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Загрузить XLS" />
                                </ListItemButton>
                            </ListItem>
                        </MuiList>
                    }
                    sx={{
                        position: 'fixed',
                        bottom: 80,
                        transform: 'translateX(-100%)',
                        zIndex: '999'
                    }}
                >
                    <Fab
                        color="primary"
                        aria-label="upload"
                    >
                        <ExcelUploadIcon />
                    </Fab>
                </ContextPopover>
            </Box>
        </>
    );
}

export default observer(UploadXLSFloatingButton);