import { Collapse, Link, ListItemButton } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import * as React from "react";
import { FC, PropsWithChildren } from "react";


type Props = PropsWithChildren<{
    readonly label: string;
    readonly opened?: boolean;
    readonly clearable?: boolean;
    readonly onCollapseClick?: (e: React.MouseEvent) => void;
    readonly onClearClick?: (e: React.MouseEvent) => void;
}>;

const Expander: FC<Props> = (props) => {
    const { label, opened, clearable, onCollapseClick, onClearClick, children, ...rest } = props;

    return <>
        <ListItemButton
            onClick={props.onCollapseClick}
            {...rest}
        >
            <ListItemText sx={{ '& .MuiTypography-root': { fontSize: 14 } }} primary={label} />
            {clearable && (
                <Link
                    component="button"
                    onClick={props.onClearClick}
                >Сбросить
                </Link>
            )}
            {opened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
            in={opened}
            timeout="auto"
            unmountOnExit
            {...rest}
        >
            {children}
        </Collapse>
    </>;
};

export default Expander;