import { themed } from "../../../../theme"
import { Button, Stack, Typography } from "@mui/material";
import CallMeDialog, { Data } from "../../../../components/CallMeDialog";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../store";

type Props = {
    readonly variant?: 'login' | 'menu';
}

const CallMeButton = (p: { onClick: () => void }) => {

    return (
        <Button
            onClick={p.onClick}
            color="secondary"
            variant="outlined"
            sx={{ width: '100%', mt: 1, fontWeight: "bold", borderColor: "#3f8cff", py: "6px" }}
            size="small">
            Заказать звонок
        </Button>
    );
}

const ExtraInfo = (p: Props) => {
    const variant = p.variant || 'menu';

    const user = useContext(UserContext);

    const [callDialogOpened, setCallDialogOpened] = useState(false);
    const [callProcessing, setCallProcessing] = useState(false);

    useEffect(() => {
        if (!callProcessing) {
            setCallDialogOpened(false);
        }

    }, [callProcessing]);

    const submitCallRequest = (data: Data) => {
        setCallProcessing(true);
        user.requestCall(data)
            .finally(() => setCallProcessing(false))
            ;
    }

    return (<>
        {variant === 'login' && <CallMeButton onClick={() => setCallDialogOpened(true)} />}
        <Stack pt={1} alignItems="center" gap={1}>
            <Typography component="a" href="tel:+78005504370" variant="subtitle2" fontWeight="bold" color="#3F8CFF">
                +7-800-550-43-70
            </Typography>
            <Typography component="a" href="tel:+7(8332)735-770" variant="subtitle2" fontWeight="bold" color="#3F8CFF">
                +7-833-273-57-70
            </Typography>
        </Stack>
        <CallMeDialog
            isLoading={callProcessing}
            onDataSend={submitCallRequest}
            open={callDialogOpened}
            onClose={() => setCallDialogOpened(false)}
        />
        {variant === 'menu' && <CallMeButton onClick={() => setCallDialogOpened(true)} />}
    </>);
}

export default themed('ExtraInfo', ExtraInfo);