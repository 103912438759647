import * as React from 'react';
import List from '@mui/material/List';
import { FC, useEffect, useState } from "react";
import {
    Button,
    ClickAwayListener,
    Fab,
    Popper, Stack, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import ShowFiltersIcon from '@mui/icons-material/Visibility';
import CheckBox, { CheckboxProp } from "./Property/CheckBox";
import { Prop } from "./Property";
import Range, { RangeProp } from "./Property/Range";
import Expander from "./Property/Common/Expander";

type Props = {
    readonly props: Prop[];
    readonly selected: any;
    readonly onSelect: (selected: any) => void;
};

const stateFromSelected = (props: Props["props"], selected?: Props["selected"]) => {
    if (!selected) selected = {};
    const state = [...props];
    for (const id in selected) {
        const prop = state.find(p => p.id == +id);
        prop.opened = true;
        switch (prop.type) {
            case 'checkbox':
                const sl = (selected[id] as number[]).reduce((s, p) => (s[p] = true, s), {});
                prop.isSet = !!Object.keys(sl).length;
                for (const value of prop.values)
                    value.checked = !!sl[value.id];
                break;
            case 'range':
                prop.isSet = Object.keys(selected[id]).length == 2;
                if (prop.isSet)
                    [prop.from, prop.to] = selected[id];
                break;
        }
        prop.opened = prop.isSet;

    }

    return state;
};

const FiltersBlock: FC<Props> = ({ props, selected, onSelect }) => {
    const [anchorEl, setAnchorEl] = useState(undefined as Element);
    const [popoverIsShown, setPopoverIsShown] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const [state, setState] = useState<Prop[]>(undefined);

    useEffect(() => {
        setState(stateFromSelected(props, selected));
    }, [selected, props]);

    const getFiltersObject = () => {
        let filters = {};

        for (const prop of state) {
            if (!prop.isSet) continue;

            const selected: number[] = [];
            switch (prop.type) {
                case 'checkbox':
                    selected.push(...prop.values.filter(v => v.checked).map(v => v.id));
                    break;
                case 'range':
                    selected.push(prop.from, prop.to);
                    break;
            }

            if (!selected.length) continue;

            filters[prop.id] = selected;
        }
        if (!Object.keys(filters).length)
            filters = undefined;

        return filters;
    };

    const selectProp = (prop: Prop) => {
        const { id } = prop;

        if (prop.type == 'checkbox') {
            return <CheckBox
                key={id}
                prop={prop}
                onItemClick={e => {
                    e.stopPropagation();

                    const checkboxPropIdx = state.findIndex(p => p.id == id);
                    const checkboxProp = state[checkboxPropIdx] as CheckboxProp;

                    const { values } = checkboxProp;
                    const valueIdx = values.findIndex(v => v.id == e.itemId);
                    const newValue = {
                        ...values[valueIdx],
                        checked: !values[valueIdx].checked,
                    };

                    const newValues = [...values];
                    newValues[valueIdx] = newValue;
                    const newProp = {
                        ...checkboxProp,
                        values: newValues
                    } as CheckboxProp;

                    if (newValue.checked)
                        newProp.isSet = true;
                    else
                        newProp.isSet = !!newProp.values.filter(v => v.checked).length;

                    const newState = [...state];
                    newState[checkboxPropIdx] = newProp;
                    setState(newState);
                    setAnchorEl(e.currentTarget);
                    setPopoverIsShown(true);
                }}
            />;
        }

        if (prop.type == 'range') {
            return <Range
                key={id}
                prop={prop}
                onChange={(e, f, t) => {

                    const from = f == prop.settings.min ? undefined : f;
                    const to = t == prop.settings.max ? undefined : t;

                    const rangePropIdx = state.findIndex(p => p.id == id);
                    const checkboxProp = state[rangePropIdx] as RangeProp;

                    const newState = [...state];
                    newState[rangePropIdx] = {
                        ...checkboxProp,
                        isSet: !!(from || to),
                        from,
                        to,
                    };
                    setState(newState);
                }}
            />;
        }

        return <></>;
    };

    const applyFilters = (e?: React.UIEvent) => {
        e?.stopPropagation();
        onSelect(getFiltersObject() as any);
        setPopoverIsShown(false);
    };

    if (!state) return <></>;

    return (<>
        <Typography sx={{ p: 1 }} variant="subtitle1">Фильтр</Typography>
        <List dense>
            {state.map(p => (
                <Expander
                    key={p.id}
                    label={p.name}
                    opened={p.opened}
                    clearable={p.isSet}
                    onCollapseClick={() => {

                        const propIdx = state.findIndex(_p => _p.id == p.id);
                        const prop = state[propIdx] as Prop;
                        const newState = [...state];
                        newState[propIdx] = {
                            ...prop,
                            opened: !prop.opened
                        };
                        setState(newState);
                    }}
                    onClearClick={e => {
                        e.stopPropagation();

                        const propIdx = state.findIndex(_p => _p.id == p.id);
                        const newProp = { ...state[propIdx] };
                        newProp.isSet = false;
                        switch (newProp.type) {
                            case 'checkbox':
                                newProp.values = [...newProp.values];
                                for (const value of newProp.values)
                                    value.checked = false;
                                break;
                            case 'range':
                                delete newProp.from;
                                delete newProp.to;
                                break;
                        }

                        const newState = [...state];
                        newState[propIdx] = { ...newProp };
                        setState(newState);
                        applyFilters();
                    }}
                >
                    {selectProp(p)}
                </Expander>
            ))}
        </List>
        <Stack sx={{ p: 2 }}>
            <Button sx={{ backgroundColor: theme.palette.info.contrastText }} onClick={applyFilters} variant="contained" size="medium">Применить</Button>
        </Stack>
        <Popper
            open={popoverIsShown && !isMobile}
            anchorEl={anchorEl}
            placement="left"
            disablePortal
            style={{ zIndex: 1000 }}
        >
            <ClickAwayListener onClickAway={() => {
                setPopoverIsShown(false);
            }}>
                <Fab
                    color="warning"
                    variant="extended"
                    sx={{ transform: 'translateX(-10px)' }}
                    onClick={applyFilters}
                >
                    <ShowFiltersIcon sx={{ mr: 1 }} />
                    Показать
                </Fab>
            </ClickAwayListener>
        </Popper>
    </>);
};

export default FiltersBlock;