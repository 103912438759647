import * as React from "react";
import { FC, useContext, useEffect } from "react";
import {
    Box, Grid, Paper, Stack, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    AppContext, CartContext, FavoritesContext, NotificationsContext, RecommendedContext, UserContext, WaitListContext,
} from "../../../store";
import Heading from "../HeadingView";
import banner from "../../../images/recommended_banner.png";
import LoadingPaper from "../../LoadingPaper";
import List from "../../List";
import {  useSearchParams } from "react-router-dom";
import CategorySelector from "../../CategorySelector";
import { RecommendedWideCard } from "../../Item";
import NotificationEmailSetup from "../../NotificationEmailSetup";

type Props = {

};

const MyWideCard = observer((item: { item }) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const notif = useContext(NotificationsContext);
    const user = useContext(UserContext);
    const waitList = useContext(WaitListContext);
    const notify = notif.notify;
    const [count, setCount] = React.useState(1);

    const favorite = favorites.includes(user.user?.id, item.item.code);

    return (
        <RecommendedWideCard
            item={item.item}
            inCart={cart.includes(user.user?.id, item.item.code)}
            favorite={favorite}
            count={count}
            onCountChange={setCount}
            onFavoritesClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                if (favorite) {
                    await favorites.remove(cartItem, user.user?.id);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem, user.user?.id);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                await cart.addWithAmount(cartItem, count, user.user?.id);
                setCount(1);

                if (cartItem.count <= 0 && user.user?.matrixCode != 1) {
                    await waitList.add(cartItem, user.user?.id);
                    if (!notify.email)
                        app.showDialog(<NotificationEmailSetup />);
                    app.alert(`Добавлено в корзину и лист ожидания`);
                }
                else {
                    app.alert(`Добавлено в корзину`);
                }
            }}
        />
    );

});

const RecommendedView: FC<Props> = () => {
    const recommended = useContext(RecommendedContext);
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const mdPlus = useMediaQuery(theme.breakpoints.up('md'));

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Мой ассортимент');
    });

    const catId = +searchParams.get('cat');

    const categories = recommended.categories;
    const items = recommended.getItems(catId);

    return (<>
        <Heading />
        <Stack
            alignItems="center"
            direction={{ xs: 'column', md: 'row' }}
            spacing={4}
            sx={{ mb: 4 }}
        >
            <Box
                component="img"
                src={banner}
            />
            <Typography variant="h5" sx={{ lineHeight: '2.2rem' }}>
                В этом разделе мы собрали персональные рекомендации специально для вас на основании ваших предыдущих заказов
            </Typography>
        </Stack>
        <Grid container spacing={1} flexDirection={{ xs: 'column-reverse', md: 'row', }}>
            <Grid item xs={12} md={9}>
                {items ? (
                    <List
                        key={catId}
                        component={MyWideCard}
                        items={items}
                        loadNext={() => recommended.loadNextPage(catId)}
                    />
                ) : (
                    <LoadingPaper />
                )}
            </Grid>
            <Grid item sx={{ mb: { xs: 8, md: 0 } }} xs={12} md={3}>
                <Box sx={mdPlus ? {
                    position: 'sticky',
                    top: 80,
                    overflow: 'auto',
                    maxHeight: '100vh',
                } : {}}>
                    {categories ? (
                        <Paper elevation={4} sx={{ mb: 1 }}>
                            <CategorySelector
                                categories={categories}
                                selectedId={catId}
                                onSelect={cat => {
                                    const current = Object.fromEntries(searchParams.entries());
                                    delete current.page;

                                    if (cat) current.cat = `${cat.id}`;
                                    else delete current.cat;

                                    setSearchParams(current);
                                }}
                            />
                        </Paper>
                    ) : (
                        <LoadingPaper />
                    )}
                </Box>
            </Grid>
        </Grid>
    </>);
};

export default observer(RecommendedView);