import { FC } from "react";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import LineThrough from "../../../../components/LineThrough";
import BonusBadge from "../../../../components/bonus/BonusInfo/BonusBadge";
import BatchItemsPrice from "../../../../components/Item/BatchItemsPrice";

type Props = {
    readonly isPromotional?: boolean;
    readonly inCart: boolean;
    readonly count: number;
    readonly oldCount?: number;
    readonly outOfStock?: boolean;
    readonly price: number;
    readonly oldPrice?: number;
    readonly bonus?: {
        value: number;
        deactivated: boolean;
    };
    readonly onAddToCartClick?: () => void;
};

const WideCardOverviewAction: FC<Props> = (p) => {
    const bonusValue = p.bonus?.value;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Box sx={{ display: 'flex', alignItems: "center", pr: 1 }}>
                {p.oldCount && (
                    <LineThrough sx={{ px: 1, mr: 1 }}>
                        <Typography variant="body1">
                            {p.oldCount}
                        </Typography>
                    </LineThrough>
                )}
                {!bonusValue ? undefined : (
                    <BonusBadge
                        mode="catalogItem"
                        customMessage={p.bonus.deactivated ? `Баллы начислятся после оплаты заказа` : `Начислено за товар`}
                        faded={p.bonus.deactivated}
                        value={bonusValue}
                    />
                )}
                <Box display="flex" alignItems="center" gap="5px">
                    <Chip label={p.count} />
                    <Typography>шт.</Typography>
                </Box>

            </Box>
            <Box sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
                {p.isPromotional && (<>
                    <Chip label="АКЦИЯ" color="warning" sx={{ color: 'white' }} />
                </>)}
                {!p.isPromotional && (
                    <BatchItemsPrice
                        count={p.count}
                        price={p.price}
                        oldPrice={p.oldPrice}
                    />
                )}
            </Box>
        </Stack>
    );
}

export default WideCardOverviewAction;