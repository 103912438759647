import { FC, useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment, OutlinedInput,
    Paper,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { AppContext, AuthContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import { APIError } from "../../../store/app";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../../Logo";
import LoginBackground from "..//LoginView/LoginBackground";
import ExtraInfo from "..//LoginView/ExtraInfo";
import PasswordResetSuccess from "./PasswordResetSuccess";
import ErrorView from "../ErrorView";

type Props = {

};

const PasswordApprovalView: FC<Props> = () => {
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const [progress, setProgress] = useState(false);

    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const passwordsEqual = password && password === passwordCheck;

    if (app.error)
        return <ErrorView />;

    if(isEmailSent)
        return <PasswordResetSuccess />;

    return (
        <LoginBackground>
            <Container fixed>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <Logo size="large" disableLink />
                    <Paper sx={{ p: 2, mt: 2, width: 257 }} elevation={1}>
                        <Stack
                            component="form"
                            aria-autocomplete="both"
                            spacing={2}
                            onSubmit={async e => {
                                e.preventDefault();
                                if(!passwordsEqual) return;

                                setProgress(true);
                                auth
                                    .resetPassword(password)
                                    .then(() => setIsEmailSent(true))
                                    .catch(e => app.alert(e.response.data?.meta?.message || 'Непредвиденная ошибка', { severity: 'error' }))
                                    .finally(() => setProgress(false))
                                ;
                            }}
                        >
                            <h2>Сброс пароля</h2>
                            <OutlinedInput required
                                type={isPasswordShown ? 'text' : 'password'}
                                placeholder="Новый пароль"
                                value={password} 
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsPasswordShown(!isPasswordShown)}
                                            edge="end"
                                        >
                                            {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <OutlinedInput required
                                placeholder="Подтверждение пароля"
                                type={isPasswordShown ? 'text' : 'password'}
                                value={passwordCheck}
                                onChange={e => setPasswordCheck(e.target.value)}
                            />
                            <LoadingButton
                                disabled={!passwordsEqual}
                                children="Сбросить пароль"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                loading={progress}
                            />
                        </Stack>
                        <ExtraInfo variant="login" />
                    </Paper>
                </Box>
            </Container>
        </LoginBackground>
    );
};

export default observer(PasswordApprovalView);