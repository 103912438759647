import React, {ReactNode} from "react";
import {FC} from "react";
import MenuItem, { Props as BaseProps } from "../MenuItem"
import ContextPopover from "../../ContextPopover";

type Props = Omit<BaseProps, 'to'> & {
    readonly content: ReactNode;
};

const PopoverMenuButton: FC<Props> = ({ content, ...p }) => {

    return (
        <ContextPopover content={content}>
            <MenuItem {...p} />
        </ContextPopover>
    );
}

export default PopoverMenuButton;