import { FC, SyntheticEvent, useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
    Alert,
    createTheme,
    CssBaseline,
    Dialog,
    DialogContent,
    Snackbar,
    ThemeProvider,
    Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { } from '@mui/x-date-pickers/themeAugmentation';

import LatoRegularWoff from "../fonts/Lato/latoregular.woff";
import LatoRegularWoff2 from "../fonts/Lato/latoregular.woff2";
import LatoRegularTtf from "../fonts/Lato/latoregular.ttf";

import LatoSemiboldWoff from "../fonts/Lato/latosemibold.woff";
import LatoSemiboldWoff2 from "../fonts/Lato/latosemibold.woff2";
import LatoSemiboldTtf from "../fonts/Lato/latosemibold.ttf";

import LatoBoldWoff from "../fonts/Lato/latobold.woff";
import LatoBoldWoff2 from "../fonts/Lato/latobold.woff2";
import LatoBoldTtf from "../fonts/Lato/latobold.ttf";
import { AppContext, AuthContext } from "../store";
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";

type Props = {
};

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xsm: true,
        smm: true,
    }
}

const App: FC<Props> = () => {
    const theme = createTheme({
        spacing: 10,
        breakpoints: {
            values: {
                xs: 0,
                xsm: 480,
                sm: 600,
                smm: 750,
                md: 900,
                lg: 1200,
                xl: 1536,
            }
        },
        palette: {
            primary: {
                main: '#3F8CFF'
            },
            secondary: {
                main: '#3f8cff'
            },
            warning: {
                main: '#FF754C'
            },
            info: {
                main: '#E7FAFF',
                contrastText: '#3F8CFF'
            },
        },
        typography: {
            fontFamily: "Lato",
            subtitle1: {
                fontWeight: 'bold',
                fontSize: '1rem',
                lineHeight: '1.2rem',
            },
            subtitle2: {
                fontWeight: 'bold',
            },
            caption: {
                fontSize: '0.5rem',
                lineHeight: '0.6rem'
            },
            body1: {
                fontSize: '0.875rem',
                lineHeight: '1.2rem',
            },
            body2: {
                fontSize: '0.625rem',
                lineHeight: '0.875rem',
            },
            h5: {
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                fontWeight: 'bold',
            },
            h6: {
                fontSize: '1.13rem',
                lineHeight: '1.13rem',
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: "12px",
                        textTransform: 'none',
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        borderRadius: "16px",
                        boxShadow: " 0px 8px 16px 0px rgba(143, 149, 178, 0.15)"
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: "12px",
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: "16px",
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: `
                @font-face {
                    font-family: 'Lato';
                    src: local('Lato Regular'), local('Lato-Regular'), url(${LatoRegularWoff2}) format('woff2'), url(${LatoRegularWoff}) format('woff'), url(${LatoRegularTtf}) format('truetype');
                    font-weight: 400;
                    font-style: normal;
                }
                @font-face {
                    font-family: 'Lato';
                    src: local('Lato Semibold'), local('Lato-Semibold'), url(${LatoSemiboldWoff2}) format('woff2'), url(${LatoSemiboldWoff}) format('woff'), url(${LatoSemiboldTtf}) format('truetype');
                    font-weight: 600;
                    font-style: normal;
                }
                @font-face {
                    font-family: 'Lato';
                    src: local('Lato Bold'), local('Lato-Bold'), url(${LatoBoldWoff2}) format('woff2'), url(${LatoBoldWoff}) format('woff'), url(${LatoBoldTtf}) format('truetype');
                    font-weight: 700;
                    font-style: normal;
                }
                `
            }
        }
    });
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);

    const snackbar = app.snackbar;
    const dialog = app.dialog;

    const dialogRef = useRef<HTMLElement>();

    const title = app.title;
    const siteName = app.siteName;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        app.setDialogRef(dialogRef);

        return () => app.setDialogRef(undefined);
    });

    const handleSnackbarClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;

        app.closeSnackbar();
    };

    const handleDialogClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;

        app.closeDialog();
    };

    useEffect(() => {
        if (!dialog) return;

        app.closeDialog();

    }, [location]);

    useEffect(() => {
        if (!title || !siteName) return;

        document.title = `${title} | ${siteName}`;

    }, [title, siteName]);

    if (app.redirect) {
        const url = app.redirect.url;
        setTimeout(() => {
            app.resetRedirect();
            navigate(url);
        });
    }

    const isLoggedIn = auth.isLoggedIn;
    const themeLoaded = app.themeLoaded;

    if (isLoggedIn === undefined || !themeLoaded) return <></>;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Outlet />
                <Dialog
                    open={dialog.opened}
                    onClose={handleDialogClose}
                    scroll="body"
                    maxWidth="lg"
                    fullWidth={dialog.fullWidth === undefined ? true : dialog.fullWidth}
                    ref={(ref) => {
                        dialogRef.current = ref?.getElementsByClassName('MuiDialog-container')[0] as HTMLElement;
                    }}
                    keepMounted
                >
                    <DialogContent>
                        {typeof dialog.content == 'string' ? (
                            <Typography variant="subtitle2" children={dialog.content} />
                        )
                            : dialog.content}
                    </DialogContent>
                </Dialog>
                <Snackbar
                    open={snackbar.opened}
                    autoHideDuration={snackbar.time}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="info"
                        sx={{
                            width: '100vw',
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            fontSize: "14px",
                            alignItems: "center",
                            backgroundColor: theme.palette.info.contrastText,
                            color: "white",
                        }}
                        action={snackbar.action}
                    >
                        {typeof snackbar.content == 'string' ? (
                            <Typography variant="subtitle2" children={snackbar.content} />
                        )
                            : snackbar.content}
                    </Alert>
                </Snackbar>
                <ScrollRestoration />
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default observer(App);