import { FC, useContext, useMemo } from "react";

import { themed } from "../../../../../theme";
import Catalog from "../../../../Catalog";
import { ViewState } from "../../../CatalogTreeView";
import { Tree } from "../../../../../common/tree";
import { FilterProps, ItemsListViewMode, Sector } from "../../../../../store/catalog";
import { CatalogContext } from "../../../../../store";
import { useLocation } from "react-router-dom";

type Props = {
    readonly tree?: Tree;
    readonly filterProps?: FilterProps;
    readonly sector?: string;
    readonly path?: string;
    readonly basePath?: string;
    readonly filters?: Record<number, any>;
    readonly onFiltersChange?: (value: Record<number, any>) => void;
    readonly search?: string;
    readonly group?: string;
    readonly sort?: string;
    readonly filterDisabled?: boolean;
    readonly loadNextPage?: () => void;
    readonly itemsMode?: ItemsListViewMode;

    readonly onViewChange?: (s: ViewState) => void;
};


const getUrlParams = (fullPath: string, sectors?: Sector[]) => {
    const sects = sectors?.map(s => s.alias).join('|') || ['_none_'];
    const [, , , sector, , path] = fullPath.match(new RegExp(`((\\/(${sects})(\\/|$))?\\/?((\\/\\w|\\w|\\d|-)+)?)?\\/?`, 'mi'));

    const basePath = '/catalog' + (sector ? '/' + sector : '') + (path ? '/' + path : '');

    return [sector || undefined, path, basePath];
}

const MenuItemsList: FC<Props> = p => {
    const catalog = useContext(CatalogContext);
    const location = useLocation();
    const [sector, path, basePath] = useMemo(() => getUrlParams(location.pathname.substring('/catalog'.length), catalog.sectors), [location.pathname, catalog.sectors]);

    return (<>
        <Catalog
            path={path}
            sector={sector}
            basePath={basePath}
            tree={catalog.tree}
        />
    </>)

}

export default MenuItemsList;