import NotFoundView from "./components/views/NotFoundView";
import App from "./components/App";
import { routeMode as $ } from "./utils";
import LayoutRetail from "./retail/view/LayoutRetail";
import ProfileRetail from "./retail/view/ProfileRetail";
import BonusesRetail from "./retail/view/BonusesRetail";
import GarageRetail from "./retail/view/GarageRetail";
import ServiceRetail from "./retail/view/ServiceRetail";
import OrderItemRetail from "./retail/view/OrderItemRetail";
import { Navigate } from "react-router-dom";
import ReceiptPageView from "./retail/view/ReceiptPageView";

const routesRetail = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: $(<LayoutRetail />),
                children: [
                    { path: 'profile', element: <ProfileRetail /> },
                    { path: 'bonus', element: <BonusesRetail /> },
                    { path: 'order/:orderId', element: <OrderItemRetail /> },
                    { path: 'order', element: <ServiceRetail /> },
                    { path: 'garage', element: <GarageRetail /> },
                    { path: '/', element: <ProfileRetail /> },
                    // { path: '/registration', element: registration },
                ]
            },
            { path: 'login', element: <Navigate to="/" /> }, // Если мы вошли в розницу через оптовый вход
            // { path: 'login/retail', element: $(<LoginViewRetail />, 'public-only') }, // Логин лежит в файле routes.tsx, потому что роуты в этом файле работают только после входа в розничный аккаунт
            { path: 'login/retail', element: <Navigate to="/" /> }, // это нужно чтобы безусловно редиректить на на главную после входа в розницу
            { path: '*', element: <NotFoundView /> },
            { path: 'receipt/:orderId', element: <ReceiptPageView /> },
        ]
    }
];

export default routesRetail;