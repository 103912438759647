import { FC, useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import Heading from "../HeadingView";
import { AppContext, BonusContext, NotificationsContext, UserContext } from "../../../store";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PromoCarousel, { PromoItem } from "../../PromoCarousel";
import Avtomasla from "../../../images/avtomaslaSu.png"
import CfMoto from "../../../images/cfMotoSu.png"
import VK from "../../../images/vk.png"
import { themed } from "../../../theme";
import { executePredicates } from "../../../../ts-shared/predicates";

type Props = {

}
const HomeView: FC<Props> = () => {

    const app = useContext(AppContext);

    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const notification = useContext(NotificationsContext);
    const banners = notification.banners;

    const bonusesEnabled = bonus.status?.enabled;

    const items = useMemo(() => {
        if (!banners || !user.user || user.user.parentPriceCode || bonusesEnabled === undefined)
            return [];  // Акции выключены для всех дочерних аккаунтов

        const items = [];
        for (const banner of banners.list) {
            const u = user.user;
            const passUser = {
                id: u.id, type: u.type, matrixCode: u.matrixCode,
                parentPriceCode: u.parentPriceCode, bonusesEnabled
            };

            if (executePredicates(passUser, banner.userChecker))
                items.push({ src: banner.image, to: banner.link });
        }

        return items;
    }, [banners, user.user]);

    useEffect(() => {
        app.setTitle('');
        app.setBreadcrumbs([]);
    });

    return (<>
        <Heading />
        <PromoCarousel variant="home" />
        <Typography variant="h5" pb={2}>Выгодные предложения</Typography>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", justifyContent: "space-between" }}>
            <PromoItem variant="home" />
        </Box>
        <Box
            sx={{
                display: 'flex',
                width: "100%",
                gap: { xs: 1, lg: 2 },
                my: { xs: 4, lg: 4 },
                flexDirection: { xs: 'column', md: 'row', },
                alignItems: "center"
            }}
        >
            <Link to="https://avtomasla.su/">
                <Box maxWidth="100%" height="auto" component='img' src={Avtomasla} />
            </Link>
            <Link to="https://cf.avtomasla.su/">
                <Box maxWidth="100%" height="auto" component='img' src={CfMoto} />
            </Link>
            <Link to="https://vk.com/d_avtomasla">
                <Box maxWidth="100%" height="auto" component='img' src={VK} />
            </Link>
        </Box>
    </>);
};
export default themed('HomeView', observer(HomeView));