import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { Box, Button, Container, Paper, Stack, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { UserContext } from "../../store";
import bg from "../../images/loginbg.png";
import LogoIcon from "../../icons/logo.svg";
import CallMeDialog, { Data } from "../CallMeDialog";

type Props = {
    readonly code: number;
    readonly message: string;
};

const ErrorPageTemplate: FC<Props> = ({ code, message }) => {
    const theme = useTheme();
    const [callDialogOpened, setCallDialogOpened] = useState(false);
    const user = useContext(UserContext);

    const [callProcessing, setCallProcessing] = useState(false);
    const submitCallRequest = (data: Data) => {
        setCallProcessing(true);
        user.requestCall(data)
            .finally(() => setCallProcessing(false))
            ;
    }

    useEffect(() => {
        if (!callProcessing) {
            setCallDialogOpened(false);
        }

    }, [callProcessing]);

    return (
        <Box
            sx={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
            }}
        >
            <Container fixed>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1, textAlign: 'center' }}>
                        <Box component={LogoIcon} width={158} height={29} sx={{ mb: 1 }} />
                        <Stack component={Paper} elevation={1} spacing={2} padding={3} alignItems="center">
                            <Typography variant="h4" fontWeight="normal" color={theme.palette.error.main}>
                                {code}
                            </Typography>
                            <Typography variant="h5" fontWeight="normal" color={theme.palette.error.main}>
                                {message}
                            </Typography>
                            <Button
                                href="/"
                                variant="contained"
                                size="large"
                            >
                                На главную
                            </Button>
                            {/* <Button
                                variant="contained"
                                size="small"
                                onClick={() => setCallDialogOpened(true)}
                            >
                                Заказать звонок
                            </Button> */}
                            <Typography component="a" href="tel:+78005504370" variant="subtitle2" fontWeight="normal" color="#3F8CFF">
                                +7-800-550-43-70
                            </Typography>
                            <Typography component="a" href="tel:+7(8332)735-770" variant="subtitle2" fontWeight="normal" color="#3F8CFF">
                                +7-833-273-57-70
                            </Typography>
                        </Stack>

                    </Box>
                </Box>
            </Container>
            <CallMeDialog
                isLoading={callProcessing}
                onDataSend={submitCallRequest}
                open={callDialogOpened}
                onClose={() => setCallDialogOpened(false)}
            />
        </Box>
    );
};

export default observer(ErrorPageTemplate);