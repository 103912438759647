import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import QRCode from "react-qr-code";
import { UserContext } from "../../../store";
import { Box, BoxProps, Skeleton } from "@mui/material";

type Props = BoxProps & {

};

const QRIdentity: FC<Props> = ({ ...boxProps }) => {
    const user = useContext(UserContext);

    const qrId = user.qrIdentity;

    return (<Box {...boxProps}>
        {qrId ? undefined : (
            <Skeleton sx={{ width: '100%', height: '100%' }} />
        )}
        {!qrId ? undefined : (
            <QRCode
                value={qrId}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />
        )}
    </Box>);
};

export default observer(QRIdentity);