import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

type ItemsRecord<T={}> = Record<number, T & {
    requiredCount: number;
}>;

export type Params = {
    readonly items: ItemsRecord;
    readonly discountPercent: number;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    const byId: Record<number, true> = {};
    for(const id in params.items)
        byId[id] = true;

    return Object.keys(params.items).map(id => ({ id }));
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {

    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;

    let minTotalCount = 0;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;
        if(item.stock < params.items[item.id].requiredCount)
            return [];

        result.push({ id: item.id, count: params.items[item.id].requiredCount });
        minTotalCount += params.items[item.id].requiredCount;
    }
    if(minTotalCount < neededCount)
        return [];

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {

    let neededCount = 0;
    for(const id in params.items)
        neededCount += params.items[id].requiredCount;

    let totalCount = 0;
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;

        const count = params.items[item.id].requiredCount;

        if(item.stock < count)
            return { maxSetsCount: 0, items: [] };

        result.push({ id: item.id, count: count });

        totalCount += count;
    }

    if(totalCount < neededCount)
        return { maxSetsCount: 0, items: [] };

    return { maxSetsCount: 1, items: result };
}

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {

    const discount = 1 - params.discountPercent / 100;
    return items.map(v => ({id: v.id, price: v.price * discount }));
}


const ItemsMinimumSetDiscount = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {
    if(!items.length) return { affected: [], untouched: [], groupsCount: 0 };

    let minNeededCount = 0;
    for(const id in params.items)
        minNeededCount += params.items[id].requiredCount;

    const untouched: Item[] = items.map(v => ({...v}));

    const countedIndices: number[] = [];

    let minTotalCount = 0;
    for(let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        if(!params.items[item.id]) continue;

        const itemCount = Math.min(item.count, item.stock);
        const baseCount = params.items[item.id].requiredCount;

        if(itemCount < baseCount) break;

        countedIndices.push(idx);
        minTotalCount += baseCount;
    }
    if(minTotalCount < minNeededCount)
        return { affected: [], untouched, groupsCount: 0 };

    const discount = 1 - params.discountPercent / 100;
    const affected: Item[] = [];
    let deletedCount = 0;
    for(let idx of countedIndices) {
        idx = idx - deletedCount;

        const [item] = untouched.splice(idx, 1);
        deletedCount++;

        affected.push({
            ...item,
            price: item.price * discount,
        });
    }

    return { affected, untouched, groupsCount: 1 };
};

export default ItemsMinimumSetDiscount;