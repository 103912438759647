import dayjs, {Dayjs} from "dayjs";
import {Algorithm, AlgorithmId, AlgorithmPassResult, getAlgorithm, getAlgorithms} from "./algorithms";
import { User, UserChecker, UserPredicate, executePredicates, getPredicates } from '../predicates';
import { intersection, keys, union } from "lodash";

export type RequiredItem = {
    id: string;
    count: number;
}

export type Item = {
    id: string;
    count: number;
    price: number;
    stock: number;
}

export type Group = {
    promoId: number;
    count: number;
    affectedItems: Item[];
}

export type Variant = 'standard' | 'groups' | 'items';
type DisplayParams = {
    variant?: Variant;
    unified?: boolean;
}

export type Rule = {
    algorithm: AlgorithmId;
    name: string;
    description?: string;
    params: Record<string, any>;
    validSince: Dayjs;
    validUntil: Dayjs;
    userChecker: UserChecker;
    display?: null|DisplayParams;
}

export type Context = {
    promoList: Record<number, Rule>;
}

export type PassResult = {
    groups: Group[];
    items: { id: string, count: number, price: number }[];
};

type PromoSummary = {
    algorithms: Record<AlgorithmId, Pick<Algorithm, 'name'|'description'>>;
    userPredicates: Record<AlgorithmId, Pick<UserPredicate, 'name'|'description'>>;
}

export function pass(items: ReadonlyArray<Readonly<Item>>, user: Readonly<User>, context: Readonly<Context>): PassResult {
    if(user.parentPriceCode || user.id === 3286) {
        // Запрещаем акции для дочерних аккаунтов иерархии и для Вятавтодора
        return { groups: [], items: items.map(v => ({ id: v.id, count: v.count, price: v.price })) };
    }

    const groups: Readonly<Group>[] = [];

    const now = dayjs();
    for(const promoId in context.promoList) {
        const promo = context.promoList[promoId];
        const promoRule = getAlgorithm(promo.algorithm);
        if(!promoRule)
            throw new Error(`Акция с типом ${promo.algorithm} не найдена`);
        if(promo.validSince.isAfter(promo.validUntil))
            throw new Error(`Акция с id ${promoId} и типом ${promo.algorithm} имеет неправильный временной промежуток`);

        if(!executePredicates(user, promo.userChecker)) continue;
        if(now.isBefore(promo.validSince) || now.isAfter(promo.validUntil)) continue;

        let res: undefined|AlgorithmPassResult;
        do {
            if(res) {
                groups.push({ promoId: +promoId, affectedItems: res.affected, count: res.groupsCount });
            }

            res = promoRule.fn(items, promo.params);
            items = res.untouched;
        } while (res.affected.length);

        if(!items.length) break;
    }

    return { groups, items: [...items] };
}

export function getIncludedItems(promoId: number, context: Readonly<Context>): { id: string }[] {
    return getAlgorithm(context.promoList[promoId].algorithm).getIncludedItems(context.promoList[promoId].params);
}

export function getSampleSet(items: ReadonlyArray<Readonly<{ id: string, price: number, stock: number }>>, promoId: number, context: Readonly<Context>): { id: string, count: number }[] {
    return getAlgorithm(context.promoList[promoId].algorithm).getSampleSet(items, context.promoList[promoId].params);
}

export function getItemsSet(promoId: number, items: ReadonlyArray<{ id: string, count: number, stock: number, price: number }>, setsCount: number, context: Readonly<Context>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} {
    return getAlgorithm(context.promoList[promoId].algorithm).getItemsSet(items, setsCount, context.promoList[promoId].params);
}

export function getTargetPrices(promoId: number, items: ReadonlyArray<{ id: string, price: number }>, context: Readonly<Context>): { id: string, price: number }[] {
    return getAlgorithm(context.promoList[promoId].algorithm).getTargetPrices(items, context.promoList[promoId].params);
}

export function getSummary(): PromoSummary {
    const algorithms: PromoSummary['algorithms'] = {} as any;
    const userPredicates: PromoSummary['userPredicates'] = {} as any;

    const srcAlgorithms = getAlgorithms();
    const srcPredicates = getPredicates();

    for(const k in srcAlgorithms) {
        const { fn, getIncludedItems, getItemsSet, getSampleSet, ...algo } = srcAlgorithms[k];
        algorithms[k] = algo;
    }
    for(const k in srcPredicates) {
        const { fn, ...predicate } = srcPredicates[k];
        userPredicates[k] = predicate;
    }

    return { algorithms, userPredicates };
}

export function getAllPromoItemIds(user: Readonly<User>, context: Readonly<Context>): string[] {
    let promoIds: string[] = [];

    const now = dayjs();
    for(const promoId in context.promoList) {
        const promo = context.promoList[promoId];
        const promoRule = getAlgorithm(promo.algorithm);
        if(!promoRule)
            throw new Error(`Акция с типом ${promo.algorithm} не найдена`);
        if(promo.algorithm === 'itemsList')
            continue; // это не акция, а по сути просто страничка с товарами
        if(promo.validSince.isAfter(promo.validUntil))
            throw new Error(`Акция с id ${promoId} и типом ${promo.algorithm} имеет неправильный временной промежуток`);

        if(now.isBefore(promo.validSince) || now.isAfter(promo.validUntil)) continue;
        if(!executePredicates(user, promo.userChecker)) continue;
        if(!promo.params.items) continue;
        
        promoIds = union(promoIds, keys(promo.params.items));
    }

    return promoIds;
}

export function getItemIds(itemIds: string[], user: Readonly<User>, context: Readonly<Context>): string[] {
    if(user.parentPriceCode || user.id === 3286) return []; // Запрещаем акции для дочерних аккаунтов иерархии и для Вятавтодора

    return intersection(itemIds, getAllPromoItemIds(user, context));
}

export function checkItems(itemIds: string[], user: Readonly<User>, context: Readonly<Context>): boolean {
    return !!getItemIds(itemIds, user, context).length;
}