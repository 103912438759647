import { Badge, Button } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext, BonusContext, FavoritesContext } from "../../../../store";
import { observer } from "mobx-react-lite";
import { themed } from "../../../../theme";

const PopoverMenuItems = () => {
    const bonus = useContext(BonusContext);
    const auth = useContext(AuthContext);
    const favorites = useContext(FavoritesContext);

    const summary = favorites.summary;
    const itemsInFavorites = summary?.totalItems || undefined;

    return (<>
        <Button component={Link} to="/profile" children="Информация" />
        <Button component={Link} to="/profile/delivery" children="Доставка" />
        <Button component={Link} to="/profile/feedback" children="Обращение" />
        <Button component={Link} to="/profile/notifications" children="Уведомления" />
        <Button component={Link} to="/profile/garage" children="Гараж" />
        <Button component={Link} to="/saleout" children="Акции" />
        <Button component={Link} to="/waitlist">
            Лист ожидания <Badge color="warning" sx={{ pl: 1, '& .MuiBadge-badge': { color: 'white', }, }} badgeContent={itemsInFavorites} />
        </Button>
        <Button component={Link} to="/pricelist" children="Прайс-лист" reloadDocument />
        <Button component={Link} to="/static/complaint.zip" children="Возврат товара" reloadDocument />
        {bonus.status?.enabled && (
            <Button component={Link} to="/profile/bonus" children="Бонусные баллы" />
        )}
        <Button component="a" onClick={() => auth.logOut()} children="Выход" />
    </>);
}

export default themed('PopoverMenuItems', observer(PopoverMenuItems));