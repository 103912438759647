import { Box } from "@mui/material";
import NoImageIcon from "../../../icons/noimage.svg";
import { themed } from "../../../theme";

type Props = {
    readonly variant: 'card' | 'list';
}

const NoImage = (p: Props) => {

    
    const sizingNoImg = p.variant == 'card' ? {
        padding: '30px 15px',
        height: {
            xs: 230,
            xsm: 150,
            sm: 240,
            md: 150,
        }
    } : {
        width: '100%',
        height: '100%',
    };

    return (
        <Box sx={sizingNoImg}>
            <NoImageIcon width="100%" height="100%" />
        </Box>
    );
}

export default themed('NoImage', NoImage);