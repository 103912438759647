import './styles/app.scss';

import { createRoot } from "react-dom/client";
import axios from "axios";
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    CartContext,
    CatalogContext,
    UserContext,
    SaleoutContext,
    OrderContext,
    FavoritesContext,
    AppContext,
    ProfileContext,
    AuthContext,
    WaitListContext,
    Context as StoreContext,
    StoreState, Store, NotificationsContext, RecommendedContext, BonusContext,
    GarageContext,
} from "./store";
import { FC, PropsWithChildren, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import routes from './routes';
import routesRetail from './routesRetail';
///

try {
    Sentry.init({ dsn: "https://afadc4e80acc4dfbb046921acdbba39a@glitchtip.avtomasla.su/2" });
}
catch (e) {
    // Не должно приложение падать из-за этого
    console.error(e);
}

axios.defaults.baseURL = '/api/v1';
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale('ru');
dayjs.tz.setDefault('Europe/Kirov');

const appRoot = document.getElementById('app-root');
const state: StoreState | undefined = JSON.parse(appRoot.dataset.initialState) ?? undefined;
setTimeout(() => appRoot.removeAttribute('data-initial-state'));

const Providers: FC<PropsWithChildren> = observer((p) => {
    const store = useContext(StoreContext);
    store.detectReset();

    return (
        <UserContext.Provider value={store.user}>
            <BonusContext.Provider value={store.bonus}>
                <CatalogContext.Provider value={store.catalog}>
                    <CartContext.Provider value={store.cart}>
                        <FavoritesContext.Provider value={store.favorites}>
                            <WaitListContext.Provider value={store.waitList}>
                                <SaleoutContext.Provider value={store.saleout}>
                                    <OrderContext.Provider value={store.order}>
                                        <NotificationsContext.Provider value={store.notifications}>
                                            <ProfileContext.Provider value={store.profile}>
                                                <AppContext.Provider value={store.app}>
                                                    <AuthContext.Provider value={store.auth}>
                                                        <RecommendedContext.Provider value={store.recommended}>
                                                            <GarageContext.Provider value={store.garage}>
                                                                {p.children}
                                                            </GarageContext.Provider>
                                                        </RecommendedContext.Provider>
                                                    </AuthContext.Provider>
                                                </AppContext.Provider>
                                            </ProfileContext.Provider>
                                        </NotificationsContext.Provider>
                                    </OrderContext.Provider>
                                </SaleoutContext.Provider>
                            </WaitListContext.Provider>
                        </FavoritesContext.Provider>
                    </CartContext.Provider>
                </CatalogContext.Provider>
            </BonusContext.Provider>
        </UserContext.Provider>
    );
});

const StoreProvider = (p: PropsWithChildren) => {
    const [store] = useState(new Store(state));

    return (
        <StoreContext.Provider value={store}>
            {p.children}
        </StoreContext.Provider>
    );
}


const router = createBrowserRouter(routes);
const retailRouter = createBrowserRouter(routesRetail);


const RouterSelectProvider: FC<PropsWithChildren> = observer((p) => {

    const userCtx = useContext(UserContext);
    const auth = useContext(AuthContext);
    const isLoggedIn = auth.isLoggedIn;
    const user = userCtx.user;

    if (!isLoggedIn || !user || user.type === 'b2b') return (
        <RouterProvider
            router={router}
        />
    );

    return (
        <RouterProvider
            router={retailRouter}
        />
    );
});

createRoot(appRoot).render(
    <StoreProvider>
        <Providers>
            <RouterSelectProvider />
        </Providers>
    </StoreProvider>
);