import { FC, useContext, useState } from "react";
import { Item } from "../../../common/item";
import { Button } from "@mui/material";
import { BonusContext } from "../../../store";
import { observer } from "mobx-react-lite";
import CardModalItem from "../CardModalItem";

type Props = {
    readonly item: Item;
    readonly inCartCount: number;
    readonly favorite: boolean;
    readonly onAddToCartClick: (count: number) => Promise<any>;
    readonly onFavoritesClick: () => void;
    readonly onClick?: () => void;
    readonly alwaysExpanded?: boolean;
}

const CardModal: FC<Props> = (p) => {
    const [count, setCount] = useState(p.item.quantity || 1);
    const [hover, setHover] = useState(false);

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: count || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    return (
        <CardModalItem
            imgSrc={p.item.imgCard}
            imgFullSrc={p.item.imgFull}
            label={p.item.label}
            count={count}
            stock={p.item.count}
            defaultStock={p.item.stockDefaultCount}
            defaultStockDate={p.item.stockDefaultDate}
            price={p.item.price}
            oldPrice={p.item.oldPrice}
            onCountChange={setCount}
            outOfStock={!p.item.count}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            favorite={p.favorite}
            expanded={hover}
            alwaysExpanded={p.alwaysExpanded}
            onHoverChange={setHover}
            inCartCount={p.inCartCount}
            onFavoritesClick={p.onFavoritesClick}
            onClick={p.onClick}
            bonusValue={bonusValue}
            action={
                <Button
                    sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: p.inCartCount > 0 && p.item.count > 0 ? '#FF754C' : (p.item.count > 0 ? '#3F8CFF' : 'red'),
                        border: "none",
                        padding: { xs: "4px 8px", sm: "8px 16px" },
                        fontSize: "14px",
                        '&:hover': {
                            color: "white",
                            border: "none",
                            backgroundColor: "#FF754C"
                        }
                    }}
                    color="info"
                    size="small"
                    variant="outlined"
                    onClick={e => {
                        e.stopPropagation();
                        p.onAddToCartClick(count)
                            .then(() => {
                                setCount(1);
                            })
                            ;
                    }}
                >
                    {p.inCartCount > 0 && p.item.count > 0 ? "В корзине" : (p.item.count ? "В корзину" : "Заказать")}
                </Button>
            }
        />
    );

}

export default observer(CardModal);