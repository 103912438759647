import {Box} from "@mui/material";
import NoImage from "./NoImage";

type Props = {
    readonly variant: 'card' | 'list';
    readonly src?: string;
    readonly alt?: string;
};

const ItemImage = (p: Props) => {

    const sizingImg = p.variant == 'card' ? {
        height: {
            xs: 230,
            xsm: 150,
            sm: 240,
            md: 150,
        }
    } : {
        width: 72,
        height: 72,
    };


    return (
        <Box
            sx={{
                flexGrow: 0,
                flexShrink: 0,
                ...sizingImg,
            }}
        >
            {p.src ? (
                <Box
                    component="img"
                    src={p.src}
                    alt={p.alt}
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            ) : (
                <NoImage variant={p.variant} />
            )}
        </Box>
    );
}

export default ItemImage;