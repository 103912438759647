import {Box, Checkbox, Divider, IconButton, Stack, Typography} from "@mui/material";
import Stepper from "../../Stepper";
import RemoveIcon from "../../../icons/delete.svg";
import {CartWideCard} from "../index";
import {useContext} from "react";
import {observer} from "mobx-react-lite";
import {AppContext, CartContext, CatalogContext, FavoritesContext, UserContext} from "../../../store";
import {Group} from "../../../store/cart";
import {Link} from "react-router-dom";
import {getItemsSet} from "../../../../ts-shared/promo";
import { Item } from "../../../common/item";

type Props = {
    readonly group: Group;
    readonly onSetsChange: (count: number) => void;
    readonly onRemoveClick: () => void;
    readonly checked?: boolean;
    readonly onCheckChange?: (checked: boolean) => void;
    readonly onItemClick?: (item: Item) => void;
}

const CartPromoGroup = (p: Props) => {
    const app = useContext(AppContext);
    const favorites = useContext(FavoritesContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const catalog = useContext(CatalogContext);


    const group = p.group;
    const context = catalog.promoContext;

    const { maxSetsCount } = context ? getItemsSet(
        p.group.promoId,
        group.items.map(v => ({ id: v.id, count: v.quantity, stock: v.count, price: v.price })),
        1,
        context,
    ) : { maxSetsCount: 1 };

    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component={Link} variant="h6" to={`/promo/${group.promoId}`}>{group.name}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: { xs: 'none', md: 'block' }}}>Комплекты:</Box>
                <Stepper
                    value={group.count}
                    max={maxSetsCount}
                    onChange={p.onSetsChange}
                />
                <IconButton
                    aria-label="remove"
                    sx={{ ml: 4 }}
                    onClick={p.onRemoveClick}
                >
                    <RemoveIcon />
                </IconButton>
                {p.checked !== undefined && (
                    <Checkbox
                        onClick={e => e.stopPropagation()}
                        checked={p.checked}
                        onChange={p.onCheckChange && (e => {
                            p.onCheckChange(e.target.checked);
                        })}
                    />
                )}
            </Box>
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack spacing={2} sx={{ pl: 2, pb: 2 }}>
            {group.items.map(item => {
                const isFavorite = favorites.includes(user.user?.id, item.code);
                return (
                    <CartWideCard
                        key={`${item.id}_${item.price?.toFixed(2) || 0}`}
                        item={item}
                        count={item.quantity}
                        favorite={isFavorite}
                        checked={p.checked}
                        onCheckChange={p.onCheckChange}
                        onClick={() => {
                            p.onItemClick && p.onItemClick(item);
                        }}
                        onFavoritesClick={async () => {
                            if(isFavorite) {
                                await favorites.remove(item, user.user?.id);
                                app.alert(`Удалено из избранного`);
                            }
                            else {
                                await favorites.add(item, user.user?.id);
                                app.alert(`Добавлено в избранное`);
                            }
                        }}
                        onRemoveFromCartClick={async () => {
                            await cart.removeWithAmount(item, item.quantity, user.user?.id);
                            app.alert(`Удалено из корзины`);
                        }}
                    />
                );
            })}
        </Stack>
    </>);
}

export default observer(CartPromoGroup);