import { TableRow as MuiTableRow } from "@mui/material";
import TableCell from "../../TableCell";
import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { BonusContext, UserContext } from "../../../../../../store";

type Props = {

}


const TableRowRetail: FC<Props> = (p) => {

    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);

    return (
        <MuiTableRow>
            <TableCell>Дата</TableCell>
            <TableCell>Сумма</TableCell>
            {!user.user.childrenCount ? undefined : (
                <TableCell>Грузополучатель</TableCell>
            )}
            {bonus.status?.enabled && (
                <TableCell>Баллы</TableCell>
            )}
            <TableCell>Обслуживаемое ТС</TableCell>
            <TableCell>Номер ТС</TableCell>
            <TableCell>Пробег</TableCell>
        </MuiTableRow>
    );
}

export default observer(TableRowRetail);