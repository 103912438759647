import * as ItemsSetDiscount from "./ItemsSetDiscount";
import * as ItemsSetPlusOne from "./ItemsSetPlusOne";
import * as SingleDiscountGroup from "./SingleDiscountGroup";
import * as DefinedAmountSet from "./DefinedAmountSet";
import * as ItemsPlusOne from "./ItemsPlusOne";
import * as DefinedPriceSet from "./DefinedPriceSet";
import * as ItemsList from "./ItemsList";
import * as ItemsMinimumSetDiscount from "./ItemsMinimumSetDiscount";
import {Item} from "../index";

export type AlgorithmPassResult = {
    groupsCount: number;
    affected: Item[];
    untouched: Item[];
}

export type Algorithm = {
    name: string;
    description?: string;
    fn: (items: ReadonlyArray<Item>, params: Record<string, any>) => AlgorithmPassResult;

    /** Полный список позиций, участвующих в акции */
    getIncludedItems: (params: Record<string, any>) => { id: string }[];
    /** Набор товаров для вывода в качестве примера на странице акции и добавления их в корзину */
    getSampleSet: (items: ReadonlyArray<{ id: string, price: number, stock: number }>, params: Record<string, any>) => { id: string, count: number }[];
    /** Рассчитывает список товаров для достижения заданного количества наборов.  */
    getItemsSet: (
        items: ReadonlyArray<{ id: string, count: number, stock: number }>,
        setsCount: number,
        params: Record<string, any>
    ) => {
        items: { id: string, count: number }[],
        maxSetsCount: number,
    };
    /** Рассчитывает цены товаров, которые могут быть присвоены после применения акции. */
    getTargetPrices: (items: ReadonlyArray<{ id: string, price: number }>, params: Record<string, any>) => { id: string, price: number }[];
}

const algorithms = {
    setDiscount: {
        name: 'Скидка на набор',
        fn: ItemsSetDiscount.default,
        getSampleSet: ItemsSetDiscount.getSampleSet,
        getIncludedItems: ItemsSetDiscount.getIncludedItems,
        getItemsSet: ItemsSetDiscount.getItemsSet,
        getTargetPrices: ItemsSetDiscount.getTargetPrices,
    },
    setPlusOne: {
        name: 'Купи набор, получи часть бесплатно',
        fn: ItemsSetPlusOne.default,
        getSampleSet: ItemsSetPlusOne.getSampleSet,
        getIncludedItems: ItemsSetPlusOne.getIncludedItems,
        getItemsSet: ItemsSetPlusOne.getItemsSet,
        getTargetPrices: ItemsSetPlusOne.getTargetPrices,
    },
    singleDiscountGroup: {
        name: 'Скидка на каждый товар в группе',
        fn: SingleDiscountGroup.default,
        getSampleSet: SingleDiscountGroup.getSampleSet,
        getIncludedItems: SingleDiscountGroup.getIncludedItems,
        getItemsSet: SingleDiscountGroup.getItemsSet,
        getTargetPrices: SingleDiscountGroup.getTargetPrices,
    },
    definedAmountSet: {
        name: 'Набор заданного количества товаров',
        fn: DefinedAmountSet.default,
        getSampleSet: DefinedAmountSet.getSampleSet,
        getIncludedItems: DefinedAmountSet.getIncludedItems,
        getItemsSet: DefinedAmountSet.getItemsSet,
        getTargetPrices: DefinedAmountSet.getTargetPrices,
    },
    itemsPlusOne: {
        name: 'Каждый N-ный товар каждой позиции по спец. цене',
        fn: ItemsPlusOne.default,
        getSampleSet: ItemsPlusOne.getSampleSet,
        getIncludedItems: ItemsPlusOne.getIncludedItems,
        getItemsSet: ItemsPlusOne.getItemsSet,
        getTargetPrices: ItemsPlusOne.getTargetPrices,
    },
    definedPriceSet: {
        name: 'При покупке товаров из списка на N-ую сумму, получаем указанные товары по спец. цене',
        fn: DefinedPriceSet.default,
        getSampleSet: DefinedPriceSet.getSampleSet,
        getIncludedItems: DefinedPriceSet.getIncludedItems,
        getItemsSet: DefinedPriceSet.getItemsSet,
        getTargetPrices: DefinedPriceSet.getTargetPrices,
    },
    itemsList: {
        name: 'Страница со списком товаров',
        fn: ItemsList.default,
        getSampleSet: ItemsList.getSampleSet,
        getIncludedItems: ItemsList.getIncludedItems,
        getItemsSet: ItemsList.getItemsSet,
        getTargetPrices: ItemsList.getTargetPrices,
    },
    itemsMinimumSetDiscount: {
        name: 'Набор с нестрогим количеством товаров',
        fn: ItemsMinimumSetDiscount.default,
        getSampleSet: ItemsMinimumSetDiscount.getSampleSet,
        getIncludedItems: ItemsMinimumSetDiscount.getIncludedItems,
        getItemsSet: ItemsMinimumSetDiscount.getItemsSet,
        getTargetPrices: ItemsMinimumSetDiscount.getTargetPrices,
    }
};

export type AlgorithmId = keyof typeof algorithms;

export function getAlgorithms(): Readonly<Record<AlgorithmId, Readonly<Algorithm>>> {
    return algorithms;
}

export function getAlgorithm(id: AlgorithmId): Readonly<Algorithm>|undefined {
    return algorithms[id];
}