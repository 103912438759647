import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import {request} from "../utils";
import {Context as PromoContext, Rule} from "../../ts-shared/promo";
import dayjs from "dayjs";
import { Cacheable, CacheableWithPagination } from "../common/caching";
import { Tree, TreeLevel } from "../common/tree";
import { Item } from "../common/item";
import getDatabase from "../services/DexieDB";


export type Sector = {
    readonly alias: string;
    readonly name: string;
}

export type FilterProps = Cacheable & {
    readonly list: any[];
}

export type ItemsListViewMode = 'grid'|'row';

export type State = {
    readonly sectors?: Sector[];
    readonly tree: Tree;
    readonly itemsByCodes: Record<string, Cacheable & {
        readonly item: Item;
        readonly similar?: CacheableWithPagination & {
            readonly list: Item[];
        };
    }>;
    readonly itemsByKey: Record<string, CacheableWithPagination & {
        readonly list: Item[];
        readonly similar?: {
            readonly list: Item[];
        }
    }>;
    readonly filterPropsByPath: Record<string, FilterProps>;

    readonly promo: {
        readonly context?: PromoContext;
        readonly itemsByPromoId: Record<number, Cacheable & {
            readonly items: Item[];
        }>;
    };
    readonly preferences: {
        readonly viewMode?: ItemsListViewMode;
    }
};

export class Catalog {
    _store: Store = undefined;
    _state: State = {
        itemsByCodes: {},
        tree: {
            bySector: {}
        },
        sectors: undefined,
        itemsByKey: {},
        filterPropsByPath: {},
        promo: {
            context: undefined,
            itemsByPromoId: {},
        },
        preferences: {},
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            sectors: computed,
            tree: computed,
            setState: action,
            promoContext: computed,
            itemsViewMode: computed,
        });
    }

    setState(state: State | undefined) {
        if(state.promo?.context) {
            const context: Mutable<PromoContext> = state.promo.context;

            const list: Record<number, Rule> = {};
            for(const id in context.promoList) {
                const it = context.promoList[id];
                list[id] = {
                    ...it,
                    validSince: dayjs(it.validSince),
                    validUntil: dayjs(it.validUntil),
                };
            }
            context.promoList = list;
        }
        if(state.itemsByKey) {
            for(const id in state.itemsByKey) {
                const items = state.itemsByKey[id]?.list || [];
                for(const item of items) {
                    const date = item.stockDefaultDate;
                    if(date) {
                        (item as any).stockDefaultDate = dayjs(date);
                    }
                }
            }
        }
        if(state.promo?.itemsByPromoId) {
            for(const id in state.promo.itemsByPromoId) {
                const items = state.promo.itemsByPromoId[id]?.items || [];
                for(const item of items) {
                    const date = item.stockDefaultDate;
                    if(date) {
                        (item as any).stockDefaultDate = dayjs(date);
                    }
                }
            }
        }

        this._state = merge(this._state, state || {});
    }

    getCategoryName(path: string[], sectorAlias?: string): string {
        let tree = this.tree.bySector[sectorAlias || 'default']?.byAlias;
        if(!tree) return '';

        let label = '';
        for(const part of path) {
            label = tree[part]?.label || '';
            tree = tree[part]?.children;
            if(!tree) return '';
        }

        return label;
    }

    getItemBreadcrumbs(path: string, sector?: string) {
        if(!path) return [];
        const breadcrumbs = this.getCategoryBreadcrumbs(path.substring(0, path.length - 1), sector);
        breadcrumbs.pop();
        return breadcrumbs;
    }

    getCategoryBreadcrumbs(path: string, sector?: string) {

        const breadcrumbs = [
            { name: 'Главная', to: '/' },
            { name: 'Каталог', to: sector || path ? '/catalog' : undefined },
        ];

        const sectorName = sector ? this.sectors.find(s => s.alias == sector).name : undefined;

        if(sector) {
            breadcrumbs.push({ name: sectorName, to: `/catalog/${sector}` });
        }
        if(path) {
            const parts = path.split('/');
            let to = `/catalog/${sector ? `${sector}/` : ''}`;
            const before = [];
            for(let i = 0; i < parts.length; i++) {
                const part = parts[i];
                to = `${to}${part}/`;
                before.push(part);
                breadcrumbs.push({ name: this.getCategoryName(before, sector), to: i < parts.length - 1 ? to : undefined });
            }
        }

        return breadcrumbs;
    }

    private getTree(sectorAlias?: string, path?: string) {

        const tree = this._state.tree.bySector[sectorAlias || 'default'];
        if(!tree) {

            request({
                method: 'GET',
                url: `/catalog/${sectorAlias || ''}`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });

            return tree as undefined;
        }

        if(!path) return tree.byAlias;

        const s = path.startsWith('/') ? 1 : 0;
        const e = path.endsWith('/') ? path.length - 1 : undefined;
        if(s || e) path = path.substring(s, e);

        const parts = path.split('/');
        let subTree = tree.byAlias;
        for(const part of parts) {
            subTree = subTree[part]?.children;
            if(!subTree) return subTree as undefined;
        }

        return subTree;
    }

    getItems(path: string) {

        const url = [ 'catalog', path ];
        const params: Record<string, string> = {};

        const key = JSON.stringify(params) + `_${path}`;

        const items = this._state.itemsByKey[key];
        if(!items) {
            request({
                method: 'GET',
                url: `/${url.join('/')}/`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
                params,
            })
            .then(res => {
                this._store.setState(res.data.state);
            });

            return items as undefined;
        }

        return {
            type: 'items',
            list: (items as any).list,
            nextPage: items.nextPage,
            similar: items.similar,
        };
    }

    getTreeLevel(sectorAlias?: string, path?: string, filters?: any, search?: string, group?: string, sort?: string): undefined|TreeLevel {
        const tree = this.getTree(sectorAlias, path);
        if(!tree) return tree as undefined;

        if(!search && !filters) {
            const roots = Object.values(tree);
            if (roots.length) {

                return {
                    type: 'categories',
                    list: tree,
                }
            }
            if(!path) return { type: 'categories', list: {} };
        }

        let items;
        const url = [ 'catalog' ];
        const params: Record<string, string> = {};

        if(search && search.length)
            params.q = search;
        else {
            if(sectorAlias) url.push(sectorAlias);
            if(path) url.push(path);

            if(filters) params.f = JSON.stringify(filters);
        }
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(sectorAlias) key += `_${sectorAlias}`;
        if(path) key += `_${path}`;

        items = this._state.itemsByKey[key];
        if(!items) {
            request({
                method: 'GET',
                url: `/${url.join('/')}/`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
                params,
            })
            .then(res => {
                this._store.setState(res.data.state);
            });

            return items as undefined;
        }

        return {
            type: 'items',
            list: items.list,
            nextPage: items.nextPage,
            similar: items.similar,
        };
    }
    loadNextPage(sectorAlias?: string, path?: string, filters?: any, search?: string, group?: string, sort?: string) {

        const url = [ 'catalog' ];
        const params: Record<string, string> = {};

        if(search && search.length) {
            params.q = search;
        }
        else {
            if(sectorAlias) url.push(sectorAlias);
            if(path) url.push(path);

            if(filters) {
                params.f = JSON.stringify(filters);
            }
        }
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(sectorAlias) key += `_${sectorAlias}`;
        if(path) key += `_${path}`;

        request({
            method: 'GET',
            url: `/${url.join('/')}/`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                ...params,
                page: this._state.itemsByKey[key].nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.state);
        });
    }

    getFilterProps(sectorAlias?: string, path?: string, filters?: any, search?: string, group?: string, sort?: string): undefined|FilterProps {
        if(!path) return undefined;

        const tree = this.getTreeLevel(sectorAlias, path, filters, search, group, sort);
        if(!tree) return tree as undefined;
        if(tree.type === 'categories') return undefined;

        const url = [];
        const params: Record<string, string> = {};

        if(sectorAlias) url.push(sectorAlias);
        if(path) url.push(path);

        if(filters) params.f = JSON.stringify(filters);
        if(sort) params.s = sort;
        if(group) params.g = group;

        let key = JSON.stringify(params);
        if(sectorAlias) key += `_${sectorAlias}`;
        if(path) key += `_${path}`;

        const props = this._state.filterPropsByPath[key];
        if(!props) {
            request({
                method: 'GET',
                url: `/catalog/filters/${url.join('/')}/`,
                headers: {'App-Data-Only': 'yes'},
                params,
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return props;
    }

    getItem(code: string) {

        if(!this._state.itemsByCodes[code]?.item) {
            request({
                method: 'GET',
                url: `/catalog/item/${code}`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return this._state.itemsByCodes[code]?.item;
    }

    promoGetIncludedItems(promoId: number) {
        if(!this._state.promo.itemsByPromoId[promoId]) {
            request({
                method: 'GET',
                url: `/promo/${promoId}`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.promo.itemsByPromoId[promoId]?.items;
    }

    getSimilar(code: string) {
        if(!this._state.itemsByCodes[code]) {
            this.getItem(code);
        }

        return this._state.itemsByCodes[code]?.similar;
    }

    getSimilarNextPage(code: string) {
        request({
            method: 'GET',
            url: `/catalog/item/${code}/similar`,
            params: {
                page: this._state.itemsByCodes[code].similar.nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.data.state);
        });
    }

    get sectors() {
        return this._state.sectors;
    }
    get tree() {
        return this._state.tree;
    }

    get promoContext() {
        if(!this._state.promo.context) {
            request({
                method: 'GET',
                url: `/promo/context`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.promo.context;
    }

    get itemsViewMode(): 'grid'|'row' {
        const user = this._store.user.user;
        if(user === undefined) return user as undefined;

        if(this._state.preferences.viewMode === undefined) {
            getDatabase(user.id).keyValue
            .get('catalogItemsViewMode')
            .then(it => {
                this._store.setState({
                    catalog: {
                        preferences: {
                            viewMode: it?.value || 'grid',
                        }
                    }
                } as any);
            });
        }

        return this._state.preferences.viewMode;
    }

   
    setItemsViewMode(value: 'grid'|'row') {
        const user = this._store.user.user;
        if(user === undefined) return user as undefined;

        this._store.setState({
            catalog: {
                preferences: {
                    viewMode: value,
                }
            }
        } as any);
        getDatabase(user.id).keyValue
        .put({ key: 'catalogItemsViewMode', value });
    }

}

export const Context = createContext<Catalog>(undefined);