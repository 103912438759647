import { MenuItem } from "../../../../MenuBar";
import CartMenuButton from "../../../../MenuBar/CartMenuButton";
import FavoritesButton from "../../../../MenuBar/FavoritesButton";
import PopoverMenuButton from "../../../../MenuBar/PopoverMenuButton";
import WaitListButton from "../../../../MenuBar/WaitListButton";


import CartIcon from '../../../../../icons/cart.svg';
import FavoritesIcon from '@mui/icons-material/FavoriteBorder';
import WaitListIcon from '@mui/icons-material/AccessTimeRounded';
import OrdersIcon from '../../../../../icons/myorders.svg';
import CatalogIcon from '../../../../../icons/catalog.svg';
import SaleoutIcon from '../../../../../icons/saleout.svg';
import ReconciliationIcon from '../../../../../icons/reconciliation.svg';
import PriceIcon from '../../../../../icons/price.svg';
import RefundIcon from '../../../../../icons/complaint.svg';
import LogOutIcon from '../../../../../icons/logout.svg';
import ReconciliationRangePicker from "../../../../ReconciliationRangePicker";
import { useContext } from "react";
import { AuthContext } from "../../../../../store";
import { themed } from "../../../../../theme";


const MenuItemsListMobile = () => {
    const auth = useContext(AuthContext);
    const logOut = () => auth.logOut();

    return (<>
        <MenuItem name="Каталог" to="/catalog" icon={<CatalogIcon />} />
        {/* <MenuItem name="Мой ассортимент" to="/recommended" icon={<RecommendedIcon />} /> */}
        <MenuItem name="Акции" to="/saleout" icon={<SaleoutIcon />} />
        <CartMenuButton name="Корзина" to="/cart" icon={<CartIcon />} />
        <FavoritesButton name="Избранное" to="/favorites" icon={<FavoritesIcon />} />
        <WaitListButton name="Лист ожидания" to="/waitlist" icon={<WaitListIcon />} />
        <MenuItem name="Мои заказы" to="/order" icon={<OrdersIcon />} end />
        <PopoverMenuButton name="Акт сверки" content={<ReconciliationRangePicker />} icon={<ReconciliationIcon />} />
        <MenuItem name="Прайс-лист" to="/pricelist" icon={<PriceIcon />} reload />
        <MenuItem name="Возврат товара" to="/static/complaint.zip" reload icon={<RefundIcon />} />
        <MenuItem name="Выход" to={logOut} icon={<LogOutIcon />} />
    </>);
}

export default themed('MenuItemsListMobile', MenuItemsListMobile);