import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "../index";
import axios from "axios";
import { Item as CatalogItem } from "../../common/item";
import dayjs, {Dayjs} from "dayjs";
import {debounce} from "lodash";
import getDbBucket from "../../services/DexieDB/itemsBucket/bucket";
import {BUCKET_TABLE_NAMES, BucketTableName} from "../../services/DexieDB";
import { Bucket as BucketDB } from '../../services/DexieDB/itemsBucket/bucket';
import {request} from "../../utils";

type Config<T> = {
    readonly endpointUrl: string;
    readonly tableName: T;
};

type Item = CatalogItem & {

}

export type State = {
    items?: {
        list: Item[];
    }
    readonly info?: {
        count: number;
    }
    readonly backend?: Record<BucketTableName, CheckStatusResponse>;
};

type CheckStatusResponse = {
    readonly updatedAt: Dayjs;
    readonly items?: Item[];
};


export abstract class Bucket<S extends State, T extends BucketTableName> {
    _store: Store;
    _state: S = {} as S;
    _addItemsProcess: Promise<any>;
    _removeItemsProcess: Promise<any>;
    _backendSyncProcess: Promise<any>;
    _getItemsProcess: Promise<any>;

    protected constructor(
        store: Store,
        protected readonly config: Config<T>,
    ) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            _itemsByCode: computed,
            setState: action,
            summary: computed,
        });
    }

    protected readonly getBucket = (userId: number) => getDbBucket(userId, this.config.tableName) as BucketDB<T>

    setState(state: State | undefined) {
        this._state = merge(this._state, state || {});
    }


    get _itemsByCode(): Record<number, Item> {
        if(!this._state.items?.list) return {};

        const res = {};
        for(const item of this._state.items.list)
            res[item.id] = item;

        return res;
    }

    protected getTimestamp(userId: number): Promise<Dayjs|null> {
        return this.getBucket(userId).getUpdatedAt();
    }

    protected async updateTimestamp(userId: number, updatedAt: Dayjs = null): Promise<Dayjs> {
        updatedAt = updatedAt || dayjs();
        await this.getBucket(userId).setUpdatedAt(updatedAt);
        return updatedAt;
    }

    protected syncWithBackend(userId: number) {
        if(this._backendSyncProcess)
            return this._backendSyncProcess;

        const doSync = async (status: CheckStatusResponse, value: Dayjs) => {

            if(status.items) {
                await this.getBucket(userId).clear();
                await this.getBucket(userId).addMany(status.items.map(it => ({
                    item: JSON.stringify(it),
                    itemId: it.id,
                    code: it.id,
                    price: it.price,
                    priceDiscount: (it as any).priceDiscount,
                    zeroLeft: it.count > 0 ? 0 : 1,
                    count: it.quantity,
                    stock: it.count,
                })));
                this.updateTimestamp(userId, status.updatedAt);
                await this.refreshState(userId);
            }
            else if(status.updatedAt.isBefore(value)) {
                await this.updateBackend(value, userId);
            }

        }

        const run = async () => {

            if(this._state.backend && this._state.backend[this.config.tableName]) {
                const time = await this.getTimestamp(userId) || dayjs(new Date(2012, 12, 12));
                await doSync(this._state.backend[this.config.tableName], time);
                return;
            }

            const params = {} as Record<string, number>;
            for(const tname of BUCKET_TABLE_NAMES) {
                params[`ts_${tname}`] = ((
                    await getDbBucket(userId, tname).getUpdatedAt()) ||
                    dayjs(new Date(2012, 12, 12)
                )).unix();
            }

            request({
                method: 'GET',
                url: '/bucket',
                params,
            }, { strategy: 'trigger-all' })
            .then(async res => {
                const data = {} as Record<BucketTableName, { backend: CheckStatusResponse }>;
                for(const tname in res.data.data) {
                    data[tname] = {
                        backend: {
                            items: res.data.data[tname].backend.items,
                            updatedAt: dayjs.unix(res.data.data[tname].backend.updatedAt),
                        }
                    };
                }
                this._store.setState(data as any);
                await doSync(data[this.config.tableName].backend, dayjs.unix(params[`ts_${this.config.tableName}`]));
            });
        };

        this._backendSyncProcess = run();

        return this._backendSyncProcess;
    };

    protected updateBackendDebounced = debounce(this.updateBackend, 3000);
    protected async updateBackend(val: Dayjs, userId: number) {
        const items = await this.getBucket(userId).getAll();

        const fd = new FormData;
        fd.append('ts', val.unix()+'');
        if(items.length) {
            for(const item of items) {
                fd.append(`items[]`, `${item.itemId}`);
            }
        }

        await axios.post(this.config.endpointUrl, fd);
    }

    protected refreshState(userId: number) {
        return this.getBucket(userId).getAll()
        .then(list => {
            this._store.setState({
                [this.config.tableName]: {
                    items: { list: list.map(item => JSON.parse(item.item)) }
                }
            });
        });
    }

    get summary() {
        const items = this.items();
        if(!items) return items as undefined;

        const calc = (items: Item[]) => {
            let price = 0;
            for(const item of items) {
                price += item.price * Math.min(item.quantity, item.count);
            }
            return [price, items.length];
        }

        const [ priceWithoutDiscount, totalItems ] = calc(items.list);

        return {
            totalItems,
            priceWithDiscount: priceWithoutDiscount,
            priceWithoutDiscount
        };
    }

    async clear(userId: number) {
        if(this._removeItemsProcess) return this._removeItemsProcess;

        this._removeItemsProcess = this.getBucket(userId)
            .clear()
            .then(() => {
                this.refreshState(userId);
            })
            .finally(() => {
                this._removeItemsProcess = undefined;
            })
        ;
        (async () => {
            this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
        })();

        return this._removeItemsProcess;
    }

    remove(item: Readonly<CatalogItem>, userId: number) {
        if(this._removeItemsProcess) return this._removeItemsProcess;

        this._removeItemsProcess = this.getBucket(userId).removeByCode(item.id)
            .then(() => {
                this.refreshState(userId);
            })
            .finally(() => {
                this._removeItemsProcess = undefined;
            })
        ;
        (async () => {
            this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
        })();

        return this._removeItemsProcess;
    }

    add(item: Readonly<CatalogItem>, userId: number) {
        if(this._addItemsProcess) return this._addItemsProcess;

        this._addItemsProcess = this.getBucket(userId).add({
            item: JSON.stringify(item),
            itemId: item.id,
            code: item.id,
            price: item.price,
            priceDiscount: (item as any).priceDiscount,
            zeroLeft: item.count > 0 ? 0 : 1,
            count: 1,
            stock: item.count,
        })
            .then(() => {
                this.refreshState(userId);
            })
            .finally(() => {
                this._addItemsProcess = undefined;
            })
        ;
        (async () => {
            this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
        })();

        return this._addItemsProcess;
    }

    includes(userId: number, itemId: string): boolean {
        this.syncWithBackend(userId);

        return !!this._itemsByCode[itemId];
    }

    items(userId: number = null) {
        if(!userId) {
            userId = this._store.user.user?.id;
            if(!userId) return userId as undefined;
        }

        this.syncWithBackend(userId);

        if(!this._state.items && !this._getItemsProcess) {
            const promise = this.refreshState(userId)
                .finally(() => this._getItemsProcess = undefined)
            ;
            this._getItemsProcess = promise;
        }

        // TODO: Напоминашка. Так делать нельзя, похоже. А то трекинг работать не будет. Нужно всегда возвращать observable
        // if(this._getItemsProcess)
        //     return undefined;

        return this._state.items;
    }
}