import { FC, ReactNode, useState } from "react";
import {
    Box, BoxProps,
    Card as MuiCard,
    CardActions,
    Chip,
    Typography, useMediaQuery, useTheme,
    Stack,
    Dialog,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { formatPrice as $ } from "../../../utils";


type Props = {
    readonly imgSrc?: string;
    readonly imgFullSrc?: string;
    readonly label: string;
    readonly count: number;
    readonly stock: number;
    readonly defaultStock?: number;
    readonly defaultStockDate?: Dayjs;
    readonly price: number;
    readonly oldPrice?: number;
    readonly packageSize: number;
    readonly articl: string;
    readonly cnpCode: string;
    readonly action: ReactNode;
    readonly inCartCount: number;
    readonly outOfStock?: boolean;
    readonly favorite?: boolean;
    readonly expanded?: boolean;
    readonly onFavoritesClick?: () => void;
    readonly onClick?: BoxProps['onClick'];
    readonly alwaysExpanded?: boolean;
    readonly hasSimilarItems?: boolean;
    readonly bonusValue?: number;

    readonly onCountChange?: (count: number) => void;
    readonly onHoverChange?: (hover: boolean) => void;
}

const CardModalItem: FC<Props> = (p) => {

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const expanded = isDesktop && !p.alwaysExpanded && p.expanded;
    const stockWarning = p.inCartCount + p.count > p.stock;

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (<>
        <Stack
            sx={{
                position: 'relative',
                cursor: p.onClick && 'pointer',
            }}
            onClick={p.onClick}
        >
            <MuiCard
                sx={{
                    zIndex: expanded ? 1 : 'initial',
                    transform: expanded ? 'translateX(0%)' : 'initial',
                    width: "100%"
                }}
            >
                <Box onClick={handleClickOpen} sx={{ display: "flex", justifyContent: "center", transition: "0.3s", '&:hover': { scale: 1.1, cursor: "pointer" } }}>
                    <Box component="img" src={p.imgSrc} alt={p.label} sx={{ p: 1, width: "310px" }} />
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box component="img" src={p.imgFullSrc} alt={p.label} sx={{ p: 1 }} />
                </Dialog>
                {p.hasSimilarItems && (
                    <Chip
                        size="small"
                        label="Есть аналоги"
                        color="info"
                        sx={{
                            cursor: 'inherit',
                            color: theme.palette.secondary.main,
                            position: 'absolute',
                            top: 0, left: 0,
                            m: 1,
                            zIndex: 50,
                        }}
                    />
                )}
                <Box sx={{ p: 1 }}>
                    <Stack gap="14px">
                        {p.packageSize ? <Box display="flex" gap={2}>
                            <Typography fontWeight="800">В упаковке:</Typography>
                            <Typography>{p.packageSize}</Typography>
                        </Box> : ''}
                        <Box display="flex" gap={2} sx={{ color: stockWarning ? theme.palette.warning.main : undefined }}>
                            <Typography fontWeight="800">На складе:</Typography>
                            <Typography>{p.stock}</Typography>
                        </Box>
                        {p.articl ? <Box display="flex" gap={2}>
                            <Typography fontWeight="800">Артикул:</Typography>
                            <Typography>{p.articl}</Typography>
                        </Box> : ''}
                        {p.cnpCode ? <Box display="flex" gap={2}>
                            <Typography fontWeight="800">Код ДА:</Typography>
                            <Typography>{p.cnpCode}</Typography>
                        </Box> : ''}
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: 3 }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "14px", sm: "24px" }, color: theme.palette.warning.main }}>{$(p.price)}</Typography>
                        <CardActions
                            sx={{
                                justifyContent: 'space-between',
                                padding: "10px 0 0 0",
                                '& .MuiButtonBase-root': {
                                    borderRadius: "12px"
                                }
                            }}
                            disableSpacing
                        >
                            {p.action}
                        </CardActions>
                    </Box>
                </Box>
            </MuiCard >
        </Stack >
    </>);
}

export default CardModalItem;