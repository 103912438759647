import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { styled, TableCell, tableCellClasses, TableRow as MuiTableRow, Box } from "@mui/material";
import { Dayjs } from "dayjs";

import Date from "../../../../components/Date";
import BonusBadge from "../../../../components/bonus/BonusInfo/BonusBadge";

type Props = {
    readonly id: number;
    readonly type: string;
    readonly deactivated: boolean;
    readonly date: Dayjs;
    readonly delta: number;
    readonly message?: string;
    readonly orderId?: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(MuiTableRow)(() => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TableRowBonusRetail: FC<Props> = (p) => {
    const navigate = useNavigate();

    return (
        <StyledTableRow hover
            sx={{ cursor: 'pointer' }}
            onClick={p.orderId ? () => navigate(`/order/${p.orderId}`) : undefined}
        >
            <StyledTableCell align="center"><Date variant="date" date={p.date} /></StyledTableCell>
            <StyledTableCell align="center">
                <Box sx={{ width: "max-content", margin: "0 auto" }}>
                    <BonusBadge
                        mode="transaction"
                        faded={p.deactivated}
                        value={p.delta}
                    />
                </Box>
            </StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 150 }} align="center">{p.message || ''}</StyledTableCell>
        </StyledTableRow>
    );

}

export default observer(TableRowBonusRetail);