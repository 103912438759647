import {observer} from "mobx-react-lite";
import {FC, useContext, useState} from "react";
import Heading from "../HeadingView";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LoadingPaper from "../../LoadingPaper";
import {CartContext, CatalogContext, UserContext} from "../../../store";
import {useNavigate, useParams} from "react-router-dom";
import Stepper from "../../Stepper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Price from "../../Item/Price";

type Props = {

}

const CatalogCardView: FC<Props> = () => {
    const theme = useTheme();
    const { code } = useParams();
    const catalog = useContext(CatalogContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const [ amount, setAmount ] = useState(1);
    const [ isAddingToCart, setIsAddingToCart ] = useState(false);
    const navigate = useNavigate();

    const item = catalog.getItem(code);

    return (<>
        <Heading/>
        <Grid columnSpacing={3} direction={{ xs: 'column', md: 'row' }} container>
            <Grid item xs={12} md={7} lg={9}>
                {item !== undefined ? (<>
                    <Typography sx={{ mb: 4 }} variant="h5">
                        {item.label}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <Paper
                                component="img"
                                src={item.imgCard}
                                alt={item.label}
                                sx={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    maxHeight: { xs: '60vw', md: 'initial' },
                                    p: 1
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{ mb: 4 }} variant="body1">
                                {item.label}
                            </Typography>
                           <Stack spacing={2}>
                               {(item.props || []).map((prop, i) => (
                                   <Accordion>
                                       <AccordionSummary
                                           expandIcon={<ExpandMoreIcon />}
                                           aria-controls={`ac-prop-${i}-content`}
                                           id={`ac-prop-${i}-header`}
                                       >
                                           <Typography>{prop.name}</Typography>
                                       </AccordionSummary>
                                       <AccordionDetails>
                                           {prop.values.map(val => (
                                               <Typography>
                                                   {val.value}
                                               </Typography>
                                           ))}
                                       </AccordionDetails>
                                   </Accordion>
                               ))}
                           </Stack>
                        </Grid>
                    </Grid>
                </>) : (
                    <LoadingPaper />
                )}
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
                {item !== undefined ? (<>
                    <Paper sx={{ p: 2, mt: { xs: 4, md: 0 } }} elevation={1}>
                        <Stack direction="column" spacing={2}>
                            <Price value={item.price} />
                            <Box>
                                <Typography sx={{ my: 2 }} variant="body2" color="text.secondary">
                                    Количество в упаковке: {item.pkg} <br/>
                                    Артикул: {item.articl} <br/>
                                    {item.cnp_code && (<>Код ДА: {item.cnp_code} <br/></>)}
                                </Typography>
                            </Box>
                            <Divider variant="fullWidth" />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Stepper value={amount} onChange={setAmount} />
                                <LoadingButton
                                    size="small"
                                    variant="contained"
                                    loading={isAddingToCart}
                                    onClick={() => {
                                        setIsAddingToCart(true);
                                        cart.addWithAmount(item, amount, user.id)
                                        .finally(() => {
                                            setAmount(1);
                                            setIsAddingToCart(false);
                                        });
                                    }}
                                >
                                    В корзину
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Paper>
                    <Box sx={{ p: 2, px: { xs: 0, md: 2 } }}>
                        <Button
                            sx={{ width: '100%' }}
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            Вернуться назад
                        </Button>
                    </Box>
                </>) : (
                    <LoadingPaper />
                )}
            </Grid>
        </Grid>
    </>);
}

export default observer(CatalogCardView);