import { Box, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import AddCarModal from "./AddCarModal";
import { GarageContext } from "../../../store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { VehicleItem } from "../../../store/garage";

type Props = {

};
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const ItemPanel: FC<{ item: VehicleItem }> = (p) => {
    const v = p.item;

    if (v.status === 'statecode_pending') {

        return (
            <TabPanel key={v.id} value={`${v.id}`}>
                <Typography fontSize={18} fontWeight="bold">В процессе обработки</Typography>
                <Box display="flex">
                    <Typography fontSize={18} fontWeight="bold">Номер:</Typography>
                    <Typography>{v.stateCode}</Typography>
                </Box>
            </TabPanel>
        );
    }
    if (v.status === 'statecode_fail') {

        return (
            <TabPanel
                sx={{ cursor: 'pointer' }}
                key={v.id}
                value={`${v.id}`}
                onClick={() => {
                    // ОКТРЫВАЙ МОДАЛКУ ДОБАВЛЕНИЯ РУКАМИ!!!!
                }}
            >
                <Box display="flex">
                    <Typography fontSize={18} fontWeight="bold">Не удалось найти автомобиль:</Typography>
                    <Typography> требуется ручной ввод</Typography>
                </Box>
                <Box display="flex">
                    <Typography fontSize={18} fontWeight="bold">Номер:</Typography>
                    <Typography>{v.stateCode}</Typography>
                </Box>
            </TabPanel>
        );
    }

    return (
        <TabPanel key={v.id} value={`${v.id}`}>
            <Box display="flex">
                <Typography fontSize={18} fontWeight="bold">Номер:</Typography>
                <Typography>{v.stateCode}</Typography>
            </Box>
            <Box display="flex">
                <Typography fontSize={18} fontWeight="bold">VIN:</Typography>
                <Typography>{v.vinCode}</Typography>
            </Box>
            <Box display="flex">
                <Typography fontSize={18} fontWeight="bold">Наименование:</Typography>
                <Typography>{v.name}</Typography>
            </Box>
            <Box display="flex">
                <Typography fontSize={18} fontWeight="bold">Год выпуска:</Typography>
                <Typography>{v.year}</Typography>
            </Box>
            <Box display="flex">
                <Typography fontSize={18} fontWeight="bold">Мощность:</Typography>
                <Typography>{v.enginePowerHp} л.с.</Typography>
            </Box>
            {/* <Box display="flex">
                        <Typography fontSize={18} fontWeight="bold">Кузов:</Typography>
                        <Typography>кузов авто</Typography>
                    </Box> */}
            {/* <Box display="flex">
                        <Typography fontSize={18} fontWeight="bold">Модификация:</Typography>
                        <Typography>модификация авто</Typography>
                    </Box> */}
        </TabPanel>
    );
}

const GarageRetail: FC<Props> = () => {
    const garage = useContext(GarageContext);
    const [value, setValue] = useState('0');

    const items = garage.items;

    useEffect(() => {
        if (!items || !items.list[0]) return;

        setValue(items.list[0].id + '');

    }, [items]);


    return (<>
        <Paper sx={{ width: '100%', my: 3 }}>
            <TabContext value={`${value}`}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant="scrollable" onChange={(e, v) => setValue(v)} aria-label="basic tabs example">
                        {items?.list.length ? items?.list.map(v => (<Tab key={v.id} label={v.name} value={v.id} />)) : <Tab label="Нет добавленных авто" />}
                        {/* <AddCarModal /> */}
                    </TabList>
                </Box>
                <TabPanel value="0" children={<></>} />
                {items?.list.map(v => (
                    <ItemPanel item={v} />
                ))}
            </TabContext>
        </Paper >
    </>);
};

export default observer(GarageRetail);