import { User } from "../predicates";
import { Context, getAllPromoItemIds } from "../promo";

export type Item = {
    id: string;
    price: number;
    quantity: number;
    outOfStock: boolean;
    bonusFactor?: number|null;
    maxDiscountFactor?: number|null;
};

export function getBonusToGiveForItem(item: Item, indexedPromoItemIds: Record<string, number>): number {
    if(indexedPromoItemIds[item.id]) return 0;

    return Math.floor(item.price * item.quantity * (item.bonusFactor || 0));
}

export function calculateOrderBonusInfo(items: Item[], userBalance: number, user: Readonly<User>, context: Readonly<Context>) {
    userBalance = Math.max(userBalance, 0);

    const promoItemIds = getAllPromoItemIds(user, context);
    const indexedPromoItemIds = Object.fromEntries(promoItemIds.map(v => [v, 1]));

    let totalPrice = 0;
    let available = 0;

    for(const item of items) {
        if(item.outOfStock || indexedPromoItemIds[item.id]) continue;

        const price = item.price * item.quantity;
        totalPrice += price;

        available += price * (item.maxDiscountFactor || 0);
    }

    available = Math.min(available, userBalance);

    const newPriceFactor = 1 - (available / totalPrice);

    const newPrices: {id: string, items: {price: number, quantity: number, initialPrice: number, toGive: number}[]}[] = [];

    let totalToGive = 0;
    for(const item of items) {
        const itemToGive = getBonusToGiveForItem(item, indexedPromoItemIds);
        const factor = indexedPromoItemIds[item.id] ? 1 : newPriceFactor;

        totalToGive += itemToGive;

        newPrices.push({
            id: item.id,
            items: [{
                initialPrice: item.price,
                price: item.price * factor,
                quantity: item.quantity,
                toGive: itemToGive,
            }],
        });
    }

    return {
        available, prices: newPrices,
        toGive: totalToGive,
    };
}