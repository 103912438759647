import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import { Fragment, ReactElement, createElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext, UserContext } from "../store";

let numberFormatter: Intl.NumberFormat;

function getNumberFormatter() {
    if(!numberFormatter) {
        numberFormatter = new Intl.NumberFormat('ru', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    return numberFormatter;
}

export function formatPrice(value: number, currency = true) {
    const formatter = getNumberFormatter();

    return formatter.format(value) + (currency ? ' ₽' : '');
}

export const routeMode = (element: ReactElement, mode: 'private'|'public'|'public-only' = null) => { // Приватность/публичность роута
    if(mode === null) mode = 'private';

    if(mode === 'public')
        return element; 

    if(mode === 'private') {
        return createElement(() => {
            const auth = useContext(AuthContext);
            const user = useContext(UserContext);
            if(auth.isLoggedIn === undefined) return createElement(Fragment);
    
            if(!auth.isLoggedIn) 
                return createElement(Navigate, { to: user.lastUserType === 'retail' ? '/login/retail' : '/login' });
        
            return element;
        })
    }

    return createElement(() => {
        const auth = useContext(AuthContext);
        if(auth.isLoggedIn === undefined) return createElement(Fragment);

        if(auth.isLoggedIn) 
            return createElement(Navigate, { to: '/' });
    
        return element;
    })
};

type Config = {
    readonly strategy?: 'trigger-first' | 'trigger-all';
};

const requests: Record<string, {
    axiosConfig: AxiosRequestConfig,
    promise: Promise<AxiosResponse>,
}> = {};

export function request(axiosConfig: AxiosRequestConfig, config: Config = undefined): Promise<AxiosResponse> {
    if(axiosConfig.params === undefined)
        axiosConfig.params = {};

    const key = JSON.stringify(axiosConfig); // TODO: Учитывать FormData

    const prev = requests[key];
    if(prev) {

        switch (config?.strategy) {
            case "trigger-all":
                return prev.promise;
            default:
                // Должен срабатывать только первый обработчик ответа, а не все дубликаты
                return new Proxy(prev.promise, {
                    get: function (target, prop) {
                        if (prop === 'then') return () => {};
                        if (prop === 'catch') return () => {};
                        return Reflect.get.apply(null, arguments);
                    },
                });
        }
    }

    const promise = axios.request(axiosConfig)
        .finally(() => {
            delete requests[key];
        })
    ;
    requests[key] = { axiosConfig, promise };

    return promise;
}

export type ToFormDataArg<T extends Record<string|number, any>={}> = T|FormData|HTMLFormElement;
export function toFormData<T>(data: ToFormDataArg<T>): FormData {
    if(data instanceof FormData) return data;
    if(data instanceof HTMLFormElement) return new FormData(data);

    const fd = new FormData;
    for(const k in data) 
        fd.append(k, String(data[k]));

    return fd;
}