import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Dayjs } from "dayjs";

import Date from "../../../../components/Date";
import BonusBadge from "../../../../components/bonus/BonusInfo/BonusBadge";

type Props = {
    readonly id: number;
    readonly type: string;
    readonly deactivated: boolean;
    readonly date: Dayjs;
    readonly delta: number;
    readonly message?: string;
    readonly orderId?: string;
};

const CardBonusRetail: FC<Props> = (p) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{ ...{ display: 'flex', width: '100%' } }}
            onClick={p.orderId ? () => navigate(`/order/${p.orderId}`) : undefined}
        >
            <Box sx={{ background: '#F0EFFB', p: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography component={Stack} spacing={1} variant="body1" color="text.secondary">
                    <span>Дата</span>
                    <span>Баллы</span>
                    <span>Комментарий</span>
                </Typography>
            </Box>
            <Paper elevation={0} sx={{ p: 2, flexGrow: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography
                    component={Stack}
                    spacing={1}
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        wordWrap: 'wrap',
                    }}
                >
                    <span><Date date={p.date} /></span>
                    <BonusBadge
                        mode="transaction"
                        faded={p.deactivated}
                        value={p.delta}
                    />
                    <span>{p.message || '-'}</span>
                </Typography>
            </Paper>
        </Box>
    );
}

export default CardBonusRetail;