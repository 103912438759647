import * as React from "react";
import { FC, useContext, useEffect } from "react";
import { AppContext, GarageContext, OrderContext } from "../../../store";
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography, useMediaQuery, useTheme } from "@mui/material";
import List from "../../../components/List";
import { OrdersCard, OrdersTableRow } from "../../components/Order";
import { useSearchParams } from "react-router-dom";
import { indexBy } from "../../../../ts-shared/utils";

type Props = {

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ServiceRetail: FC<Props> = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
    const order = useContext(OrderContext);
    const garage = useContext(GarageContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedVehicles: string[] = searchParams.getAll('vehicle[]');

    const items = order.items(selectedVehicles?.map(Number));

    const vehicleItems = garage.items;
    const vehicleItemsById = indexBy(vehicleItems?.list as any, (v: any) => v.id);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Мои заказы');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Мои заказы' },
        ])
    });


    const handleChange = (event: SelectChangeEvent<typeof selectedVehicles>) => {
        let t: any = event.target.value;
        t = typeof t === 'string' ? t.split(',') : t;
        const newVehicles = (t as string[]);

        const params: Record<string, string | string[]> = Object.fromEntries(searchParams.entries());

        if (newVehicles.length) {
            params['vehicle[]'] = newVehicles;
        }
        else {
            delete params['vehicle[]'];
        }

        setSearchParams(params);
    };

    return (<>
        <Box sx={{ mb: 4 }} />
        {!vehicleItems?.list ? undefined : (
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Автомобиль</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedVehicles}
                    onChange={handleChange}
                    input={<OutlinedInput label="Автомобиль" />}
                    renderValue={(ids) => ids.map(id => vehicleItemsById[+id].name).join(', ')}
                    MenuProps={MenuProps}
                >
                    {vehicleItems?.list.map((v) => (
                        <MenuItem key={v.id} value={v.id + ''}>
                            <Checkbox checked={selectedVehicles.includes(v.id + '')} />
                            <ListItemText primary={v.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
        <List
            key={selectedVehicles?.join(',')}
            component={isSmall ? OrdersCard : OrdersTableRow}
            variant={isSmall ? undefined : 'retail'}
            items={items}
            loadNext={() => order.loadNextPage()}
        />
    </>);
};

export default observer(ServiceRetail);