import {Item} from "../../index";
import {AlgorithmPassResult} from "../index";

export type Params = {
    readonly items: Record<number, any>;
};

export const getIncludedItems = (params: Readonly<Params>): { id: string }[] => {
    return Object.keys(params.items).map(id => ({ id }));
}

export const getSampleSet = (items: ReadonlyArray<Readonly<{ id: string, stock: number }>>, params: Readonly<Params>): { id: string, count: number }[] => {
    const result: { id: string, count: number }[] = [];
    for(const item of items) {
        if(!params.items[item.id]) continue;
        if(item.stock < 1) continue;

        result.push({ id: item.id, count: 1 });
        break;
    }

    return result;
}

export const getItemsSet = (items: ReadonlyArray<Readonly<{ id: string, count: number, stock: number }>>, setsCount: number, params: Readonly<Params>): {
    items: { id: string, count: number }[],
    maxSetsCount: number,
} => {

    return {
        maxSetsCount: 1,
        items: [],
    };
}

const ItemsList = (items: ReadonlyArray<Readonly<Item>>, params: Readonly<Params>): AlgorithmPassResult => {

    return { 
        affected: [], 
        untouched: items.map(v => ({...v})), 
        groupsCount: 0 
    };
};

export const getTargetPrices = (items: ReadonlyArray<{ id: string, price: number }>, params: Readonly<Params>): {
    id: string,
    price: number,
}[] => {

    return items.map(v => ({
        id: v.id,
        price: v.price,
    }));
}

export default ItemsList;