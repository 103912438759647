import {
    Box,
    List,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { FC, PropsWithChildren } from "react";
import MenuItem from "./MenuItem";
import { Data } from "../CallMeDialog";

type Props = PropsWithChildren<{
    readonly onCallRequest?: (data: Data) => void;
    readonly callRequestProcessing?: boolean;
}>;


const MenuBar: FC<Props> = ({ children }) => {
    const theme = useTheme();
    const isSmallPhone = useMediaQuery('(max-width: 425px)', { noSsr: true });

    return (
        <Box component="nav" sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            <List
                component="div"
                aria-label="secondary mailbox folder"
                sx={{
                    p: 0,
                    '& .MuiListItemButton-root': {
                        borderRadius: '12px',
                        padding: isSmallPhone ? '8px 12px' : '12px 20px',
                        '& svg': {
                            color: '#8F95B2',
                        }
                    },
                    '& a.active .MuiListItemButton-root': {
                        backgroundColor: theme.palette.primary.main,
                        '& .MuiListItemText-root': {
                            color: 'white'
                        },
                        '& svg': {
                            color: 'white',
                        }
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: isSmallPhone ? '30px' : '39px',
                    }
                }}
            >
                {children}
            </List>

        </Box>
    );
}

export { MenuItem };

export default MenuBar;