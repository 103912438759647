import {BucketTableName, DexieDB, getUpdatedAtkey, TNameToBucketItem} from "../index";
import dayjs, {Dayjs} from "dayjs";
import getDatabase from "../index";

export class Bucket<T extends BucketTableName = BucketTableName> {

    constructor(
        private readonly db: DexieDB,
        private readonly table: T,
    ) {}

    async getUpdatedAt(): Promise<Dayjs|null> {
        const ts = (await this.db.keyValue.get(getUpdatedAtkey(this.table)))?.value;
        if(!ts) return null;

        return dayjs.unix(ts);
    }

    async setUpdatedAt(value: Dayjs) {
        await this.db.keyValue.put({ key: getUpdatedAtkey(this.table), value: value.unix() });
    }

    async add(item: TNameToBucketItem[T]) {
        const itemInDb = await this.db[this.table].where('itemId').equals(item.itemId).first();
        if(itemInDb) return;

        await this.db[this.table].add(item as any);
    }

    async findByCode(code: string): Promise<TNameToBucketItem[T]> {
        return await this.db[this.table].where('code').equals(code).first() as TNameToBucketItem[T];
    }

    async addMany(items: TNameToBucketItem[T][]) {
        await Promise.all(
            items.map(it => this.add(it))
        );
    }

    totalCount() {
        return this.db[this.table].count();
    }

    async totalPrice() {
        return (await this.db[this.table].toArray()).reduce((sum, item) => {
            if(item.zeroLeft) return sum;
            return sum + (item.priceDiscount || item.price);
        }, 0);
    }

    async totalPriceWithoutDiscount() {
        return (await this.db[this.table].toArray()).reduce((sum, item) => {
            if(item.zeroLeft) return sum;
            return sum + item.price;
        }, 0);
    }

    async updateByCode(code: string, changes: Omit<Partial<TNameToBucketItem[T]>, 'id'|'code'>) {
        await this.db[this.table].where('code').equals(code).modify(changes);
    }

    async removeByCode(code: string) {
        await this.db[this.table].where('code').equals(code).delete();
    }

    async getAll(p?: {
        orderBy?: string | [ string, 'ASC'|'DESC' ],
    }): Promise<TNameToBucketItem[T][]> {
        const db = this.db[this.table];

        const orderBy = p?.orderBy || ':id';
        const [ field, dir ] = typeof orderBy === 'string' ? [ orderBy, 'ASC' ] : orderBy;
        let coll = db.orderBy(field);
        if(dir === 'DESC') coll = coll.reverse();

        return await coll.toArray() as any;
    }

    async clear() {
        await this.db[this.table].clear();
    }
}

const buckets: Record<string, Bucket> = {};

export default function getBucket<T extends BucketTableName>(userId: number, table: T): Bucket<T> {
    const key = `${userId}_${table}`;
    let bucket = buckets[key];

    if(!bucket) {
        bucket = new Bucket(getDatabase(userId), table);
        buckets[key] = bucket;
    }

    return bucket as Bucket<T>;
}