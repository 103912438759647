import {
    Box, Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    InputAdornment, Link,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import EmailCheckingIcon from "@mui/icons-material/HourglassTop";
import EmailErrorIcon from "@mui/icons-material/PriorityHighRounded";
import EmailNotConfirmedIcon from "@mui/icons-material/WarningAmberRounded";
import EmailConfirmedIcon from "@mui/icons-material/Done";
import { APIError, AppContext } from "../../../store/app";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { CanceledError } from "axios";
import { NotificationsContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { observer } from "mobx-react-lite";
import img from "../../../images/waitList.png"
import { Link as RouterLink, } from "react-router-dom";


let checkEMailAbort: AbortController;
let notifyMeAbort: AbortController;

const VerifiedEmail = () => {

    const app = useContext(AppContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;
    const theme = useTheme()
    const [email, setEmail] = useState('');

    const [notifyMe, setNotifyMe] = useState(!!notify?.enabled);
    const [emailState, setEmailState] = useState<'notEntered' | 'checking' | 'incorrect' | 'notVerified' | 'verifying' | 'verified'>('notEntered');

    useEffect(() => {

        if (notify?.email) {
            setEmail(notify.email);
            setEmailState('verified');
        }
        if (notify?.enabled)
            setNotifyMe(notify.enabled);

    }, [notify]);

    const checkEmailDebounced = useMemo(() => debounce((email) => {
        checkEMailAbort = new AbortController();
        notifications.checkEmail(email,
            { signal: checkEMailAbort.signal }
        )
            .then(ok => setEmailState(ok ? 'notVerified' : 'incorrect'))
            .catch((e: APIError | CanceledError<any>) => {
                if (e instanceof CanceledError) return;

                setEmailState('incorrect');
                app.alert('Непредвиденная ошибка', { severity: 'error' });
            });

    }, 2000), [1]);

    const setNotifyMeDebounced = useMemo(() => debounce(
        async (enabled: boolean) => {
            notifyMeAbort = new AbortController();

            await notifications.setNotificationsEnabled(enabled, { signal: notifyMeAbort.signal });
        },
        1000),
        [1]
    );

    useEffect(() => {
        if (notifyMeAbort) {
            notifyMeAbort.abort();
            notifyMeAbort = undefined;
        }
        setNotifyMeDebounced.cancel();
        if (!notify || notify.enabled === notifyMe) return;

        if (notify.email && email !== notify.email)
            setEmail(notify.email);

        setNotifyMeDebounced(notifyMe);



    }, [notifyMe, notify]);

    useEffect(() => {
        if (checkEMailAbort) {
            checkEMailAbort.abort();
            checkEMailAbort = undefined;
        }
        checkEmailDebounced.cancel();
        if (!notify) return;
        if (notify.email === email) {
            setEmailState('verified');
            return;
        }

        if (!email.length) {
            setEmailState('notEntered');
            return;
        }

        setEmailState('checking');
        checkEmailDebounced(email);

    }, [email]);

    return (
        <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", smm: "row" }, alignItems: "center" }}>
            <Box
                component="img"
                src={img}
                sx={{
                    width: { xs: "100%", smm: 350 },
                    objectFit: 'contain'
                }}
            />
            <Stack maxWidth={300} alignItems="center">
                <Typography color={theme.palette.warning.main} fontWeight="bold" fontSize={30} pt="15px">Упс!</Typography>
                <Typography py="15px">Товар уже раскупили. Но мы добавили его ваш лист ожидания! Уведомление о поступлении товара придет вам на почту!</Typography>
                <Box component={RouterLink} to="/waitList">
                    <Button variant="contained" sx={{ backgroundColor: "#8F95B2", color: "white" }}>Лист ожидания</Button>
                </Box>
            </Stack>

        </Box>

    );
};

export default observer(VerifiedEmail);