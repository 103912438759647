import { FC, useContext } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { formatPrice as $ } from "../../../../utils";
import { Dayjs } from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { BonusContext, UserContext } from "../../../../store";
import Date from "../../../../components/Date";
import BonusBadge from "../../../../components/bonus/BonusInfo/BonusBadge";

type Props = {
    readonly id: string;
    readonly humanId: string;
    readonly date: Dayjs;
    readonly payUntil?: Dayjs;
    readonly price: number;
    readonly comment?: string;
    readonly status: number;
    readonly vehicleName: string;
    readonly vehicleStateCode: string;
    readonly vehicleMileage: string;
    readonly recipient?: {
        readonly id: number;
        readonly name: string;
    };
    readonly bonus?: {
        readonly added: number;
        readonly subtracted: number;
        readonly deactivated: number;
    }
};

const CardRetail: FC<Props> = (p) => {

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);

    const bonusValue = p.bonus?.added || 0;
    const bonusSubstracted = -p.bonus?.subtracted || 0;

    return (
        <Box
            sx={{ ...{ display: 'flex', width: '100%' } }}
            onClick={() => navigate(`/order/${p.id}`)}
        >
            <Box sx={{ background: '#F0EFFB', p: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography component={Stack} spacing={1} variant="body1" color="text.secondary">
                    <span>Дата</span>
                    <span>Сумма</span>
                    {bonus.status?.enabled && (
                        <span>Бонусы</span>
                    )}
                    <span>Номер ТС</span>
                    <span>Обслуживаемое ТС</span>
                    <span>Пробег</span>
                </Typography>
            </Box>
            <Paper elevation={0} sx={{ p: 2, flexGrow: 2, borderRadius: '8px 0 0 8px' }}>
                <Typography
                    component={Stack}
                    spacing={1}
                    variant="body1"
                    fontWeight="bold"
                    sx={{
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        wordWrap: 'wrap',
                    }}
                >
                    <span><Date date={p.date} /></span>
                    <span>{$(p.price)}</span>
                    {!(bonus.status?.enabled && bonusValue) ? undefined : (
                        <Box display="flex">
                            <BonusBadge
                                mode="order"
                                faded={!!p.bonus?.deactivated}
                                value={bonusValue}
                            />
                            {bonusSubstracted < 0 && (
                                <Box pt={1}>
                                    <BonusBadge
                                        mode="order"
                                        faded={!!p.bonus?.deactivated}
                                        value={bonusSubstracted}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                    <span>{p.vehicleStateCode}</span>
                    <span>{p.vehicleName}</span>
                    <span>{p.vehicleMileage}</span>
                </Typography>
            </Paper>
        </Box>
    );
}

export default CardRetail;