import {observer} from "mobx-react-lite";
import HeadingView from "../HeadingView";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useMemo, useState} from "react";
import {
    AppContext,
    BonusContext,
    CartContext,
    CatalogContext,
    UserContext,
} from "../../../store";
import {getItemsSet, getSampleSet, getTargetPrices, Rule} from "../../../../ts-shared/promo";
import {Box, Button, Fab, Stack, Typography, Zoom} from "@mui/material";
import {getAlgorithm} from "../../../../ts-shared/promo/algorithms";
import {indexBy} from "../../../../ts-shared/utils";
import { Item } from "../../../common/item";
import PromoIcon from "@mui/icons-material/LocalOffer";
import {promoPass} from "../../../../runner-ts/functions";
import LoadingPaper from "../../LoadingPaper";
import GroupsView from "./GroupsView";
import ItemsView from "./ItemsView";
import EmptyView from "./EmptyView";
import ItemCardModal from '../../ItemCardModal';


type Props = {

}

type ActualView = 'items' | 'groups' | 'empty';

const PromoPageView = (p: Props) => {
    const params = useParams();
    const app = useContext(AppContext);
    const catalog = useContext(CatalogContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const [ groupsMode, setGroupsMode ] = useState(false);

    const context = catalog.promoContext;
    const promo: Rule = context?.promoList[params.promoId];
    const bonusesEnabled = bonus.status?.enabled;

    const variant = promo?.display?.variant || 'standard';
    const unifiedMode = promo?.display?.unified || false;

    useEffect(() => {
        if(!promo) return;

        app.setTitle(`${promo.name}`);
    });

    useEffect(() => {
        if(!promo) return;

        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Акции', to: '/saleout' },
            { name: promo.name },
        ]);

    }, [ promo?.name ]);

    const _initialItems = params.promoId ? catalog.promoGetIncludedItems(+params.promoId) : undefined;

    const initialItems = useMemo(() => {
        if(!_initialItems) return undefined;

        return _initialItems.map(v => ({...v}));
    }, [ _initialItems ]);

    const initialItemsById = useMemo(() => {
        if(!initialItems) return undefined;

        return indexBy(initialItems, v => v.id);
    }, [ initialItems ]);

    const [ includedItems, setIncludedItems ] = useState<ReadonlyArray<Readonly<Item>>>(undefined);
    useEffect(() => {
        if(!initialItems) return;

        const _byId = indexBy(initialItems, v => v.id);

        const sample = getSampleSet(initialItems.map(v => ({ id: v.id, stock: v.count, price: v.price })), +params.promoId, context);

        const res = getAlgorithm(promo.algorithm).fn(sample.map(v => ({ id: v.id, count: v.count, stock: _byId[v.id].count, price: _byId[v.id].price })), promo.params).affected;
        const byId = indexBy(res, v => v.id, (p, v) => p.count += v.count);

        setIncludedItems(initialItems.map(v => ({
            ...v, quantity: byId[v.id]?.count || 0,
        })));

    }, [ initialItems ]);

    const passedGroups = useMemo(() => {
        if(!promo || !context || !includedItems || !user.user || bonusesEnabled === undefined) return;

        const u = user.user;
        const passUser = {
            id: u.id, type: u.type, matrixCode: u.matrixCode, 
            parentPriceCode: u.parentPriceCode, bonusesEnabled
        };

        const before = includedItems.filter(v => !!v.quantity).map(v => ({ id: v.id, count: v.quantity, stock: v.count, price: v.price }));
        const res = promoPass(before, passUser, context);

        const toRealItem = v => {
            const item: Mutable<Item> = {
                ...initialItemsById[v.id],
                quantity: v.count,
            };
            if(Math.abs(v.price - item.price) > 0.009) {
                item.oldPrice = item.price;
                item.price = v.price;
            }

            return item;
        };

        return {
            groups: res.groups.map(g => ({
                name: promo.name,
                promoId: g.promoId,
                count: g.count,
                items: g.affectedItems.map(toRealItem),
            })),
            items: res.items.map(toRealItem)
        };

    }, [ promo, context, includedItems, user.user, bonusesEnabled ]);


    const description = useMemo(() => {
        if(!promo?.description) return undefined;

        return promo.description.replace(/([^>])\n/g, '$1<br/>');

    }, [ promo?.description ]);

    const targetPrices = useMemo(() => {
        if(!context || !promo || !initialItems) return undefined;

        return getTargetPrices(+params.promoId, initialItems.map(v => ({ id: v.id, price: v.price })), context);

    }, [ context, promo, initialItems ]);

    const isMeaningfulToShowGroups = !!passedGroups?.groups.length && (
        includedItems?.length > 1 ||
        passedGroups.groups.length > 1 ||
        passedGroups.groups[0].items.length > 1
    );

    let actualView: ActualView;
    if(variant == 'groups') {
        if(passedGroups === undefined)
            actualView = 'groups';
        else
            actualView = isMeaningfulToShowGroups ? 'groups' : 'empty';
    }
    else if(variant == 'standard')
        actualView = (groupsMode && isMeaningfulToShowGroups) ? 'groups' : 'items';
    else
        actualView = 'items';

    return (<>
        <HeadingView />
        <Stack
            sx={{ mb: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
        >
            <Typography sx={{ textAlign: 'justify' }} variant="subtitle1" dangerouslySetInnerHTML={{ __html: description || '' }}/>
            <Button
                variant="contained"
                disabled={!includedItems}
                onClick={() => {
                    cart.addManyWithAmount(includedItems.map(v => ({
                        item: v, amount: v.quantity,
                    })), user.user?.id);
                }}
                sx={{ whiteSpace: 'nowrap' }}
            >
                В корзину
            </Button>
        </Stack>

        {actualView == 'groups' && (
            passedGroups !== undefined ? (
                <GroupsView
                    groups={passedGroups.groups}
                    items={passedGroups.items}
                    onItemClick={it => {
                        app.showDialog(<ItemCardModal item={initialItemsById[it.id]} />)
                    }}
                    onGroupRemoveClick={variant != 'standard' ? undefined : (
                        g => {
                            const countById: Record<number, number> = {};
                            for(const item of g.items) {
                                if(countById[item.id])
                                    countById[item.id] += item.quantity;
                                else
                                    countById[item.id] = item.quantity;
                            }

                            setIncludedItems(includedItems.map(v => ({
                                ...v, quantity: v.quantity - countById[v.id],
                            })));
                        }
                    )}
                    onSetsChange={(g, count) => {
                        const promoItems: {id: string, count: number, stock: number, price: number}[] = [];
                        const byId: Record<number, {id: string, count: number, stock: number, price: number}> = {};
                        for(const item of g.items) {
                            if(byId[item.id]) {
                                byId[item.id].count += item.quantity;
                                continue;
                            }
                            byId[item.id] = {id: item.id, count: item.quantity, stock: item.count, price: item.price};
                            promoItems.push(byId[item.id]);
                        }

                        const currentItems = getItemsSet(g.promoId, promoItems, count, context).items;
                        const countById = indexBy(
                            currentItems,
                            v => v.id,
                        );

                        setIncludedItems(includedItems.map(v => ({
                            ...v, quantity: countById[v.id]?.count || v.quantity,
                        })));
                    }}
                />
            ) : (
                <LoadingPaper />
            )
        )}
        {actualView == 'items' && (
            includedItems ? (
                <ItemsView
                    targetPrices={unifiedMode ? targetPrices : undefined }
                    items={includedItems}
                    onItemClick={it => {
                        app.showDialog(<ItemCardModal item={initialItemsById[it.id]} />)
                    }}
                    onItemCountChange={
                        (it, count) => {
                            const idx = includedItems.findIndex(v => v.id == it.id);
                            const newItems = [...includedItems];
                            newItems[idx] = { ...newItems[idx], quantity: count };
                            setIncludedItems(newItems);
                        }
                    }
                />
            ) : (
                <LoadingPaper />
            )
        )}
        {actualView == 'empty' && (
            <EmptyView />
        )}

        {variant == 'standard' && (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 15,
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            >
                <Zoom
                    in={isMeaningfulToShowGroups}
                >
                    <Fab
                        color="primary"
                        aria-label="add"
                        variant="extended"
                        disabled={!isMeaningfulToShowGroups}
                        onClick={() => setGroupsMode(!groupsMode)}
                    >
                        <PromoIcon sx={{ mr: 1 }} />
                        {groupsMode ? 'Показать все товары' : 'Показать наборы' }
                    </Fab>
                </Zoom>
            </Box>
        )}
    </>);
}

export default observer(PromoPageView);