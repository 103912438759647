import { FC, SyntheticEvent } from "react";
import { Box, Button, Chip, IconButton, Stack } from "@mui/material";
import Stepper from "../../Stepper";
import BatchItemsPrice from "../BatchItemsPrice";
import RemoveIcon from "../../../icons/delete.svg";
import BonusBadge from "../../bonus/BonusInfo/BonusBadge";

type Props = {
    readonly isPromotional?: boolean;
    readonly allowZero?: boolean;
    readonly count: number;
    /** Указывается, если этот товар уже добавлен в корзину в рамках какой-либо группы */
    readonly baseCount?: number;
    readonly stock: number;
    readonly disallowOverstock?: boolean;
    readonly inCart?: boolean;
    readonly outOfStock: boolean;
    readonly price: number;
    readonly oldPrice?: number;
    readonly bonusValue?: number;
    readonly onCountChange?: (count: number) => void;
    readonly onAddToCartClick?: () => void;
    readonly onRemoveFromCartClick?: () => void;
};

const c = (e: SyntheticEvent, fn: CallableFunction) => (e.stopPropagation(), fn && fn());

const WideCardStepperAction: FC<Props> = (p) => {
    const baseCount = p.baseCount || 0;
    const realStock = Math.max(p.stock - baseCount, 0);

    const disallowOverstock = p.isPromotional ? true : p.disallowOverstock;
    const bonusValue = p.bonusValue;

    return (
        <Stack alignItems="flex-end">
            {p.price && (
                <BatchItemsPrice
                    count={Math.min(p.count, realStock)}
                    price={p.price}
                    oldPrice={p.oldPrice}
                />
            )}
            {!p.price && p.isPromotional && (<>
                <Chip label="АКЦИЯ" color="warning" sx={{ color: 'white' }} />
            </>)}
            <Box display="flex" alignItems="center">
                {!bonusValue ? undefined : (
                    <BonusBadge
                        mode="catalogItem"
                        value={bonusValue}
                    />
                )}
                {p.onCountChange ? (
                    <Stepper
                        value={p.count}
                        min={p.allowZero ? 0 : 1}
                        max={disallowOverstock && p.stock - (p.baseCount || 0)}
                        onClick={e => e.stopPropagation()}
                        onChange={value => {
                            p.onCountChange && p.onCountChange(value);
                        }}
                    />
                ) : (
                    <Chip label={p.count} />
                )}
                {p.onAddToCartClick ? (
                    <Button
                        sx={{
                            whiteSpace: 'nowrap',
                            ml: 1,
                            backgroundColor: p.inCart && p.stock ? '#FF754C' : (realStock > 0 ? '#3F8CFF' : 'red'),
                            border: "none",
                            '&:hover': {
                                color: "white",
                                border: "none",
                                backgroundColor: "#FF754C"
                            }
                        }}
                        color="info"
                        size="small"
                        variant="outlined"
                        onClick={e => c(e, p.onAddToCartClick)}
                    >
                        {p.inCart && realStock > 0 ? "В корзине" : (realStock > 0 ? "В корзину" : "Заказать")}
                    </Button>
                ) : p.onRemoveFromCartClick && (
                    <IconButton
                        aria-label="remove"
                        sx={{ p: 0 }}
                        onClick={e => c(e, p.onRemoveFromCartClick)}
                    >
                        <RemoveIcon />
                    </IconButton>
                )}
            </Box>
        </Stack>
    );
};

export default WideCardStepperAction;