import React, {useContext} from "react";
import {FC} from "react";
import MenuItem, { Props as BaseProps } from "../MenuItem"
import {CartContext} from "../../../store";
import {observer} from "mobx-react-lite";

type Props = Omit<BaseProps, 'badge'> & {
};

const CartMenuButton: FC<Props> = (p) => {
    const cart = useContext(CartContext);

    const summary = cart.summary;
    const itemsInCart = summary?.totalItems || undefined;

    return (
        <MenuItem badge={itemsInCart} {...p} />
    );
}

export default observer(CartMenuButton);