import React, {useContext} from "react";
import {FC} from "react";
import MenuItem, { Props as BaseProps } from "../MenuItem"
import {FavoritesContext} from "../../../store";
import {observer} from "mobx-react-lite";

type Props = Omit<BaseProps, 'badge'> & {
};

const FavoritesButton: FC<Props> = (p) => {
    const favorites = useContext(FavoritesContext);

    const summary = favorites.summary;
    const itemsInFavorites = summary?.totalItems || undefined;

    return (
        <MenuItem badge={itemsInFavorites} {...p} />
    );
}

export default observer(FavoritesButton);