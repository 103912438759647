import * as React from "react";
import { FC } from "react";
import { Box, FormControl, FormControlProps, InputLabel, MenuItem, Select } from "@mui/material";

type Props = {
    readonly group?: string;
    readonly onChange?: (group?: string) => void;
} & FormControlProps;

const SortBlock: FC<Props> = ({ group, onChange, ...props }) => {

    return (
        <FormControl
            sx={{ minWidth: 170 }}
            size="small" {...props}>
            <InputLabel id="sort-block-group-label">Группировка</InputLabel>
            <Select
                labelId="sort-block-group-label"
                value={group || ''}
                label="Группировка"
                onChange={e => {
                    onChange && onChange(e.target.value);
                }}
            >
                <MenuItem value="">
                    <em>По умолчанию</em>
                </MenuItem>
                <MenuItem value={1}>Наличие</MenuItem>
                <MenuItem value={2}>Бренд</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SortBlock;