
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, IconButton, InputAdornment, OutlinedInput, Stack, Tab, TextField, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import regImage from "../../../../images/regImage.png"
import regImageMobile from "../../../../images/regImageMobile.png"
import { useContext, useState } from 'react';
import { AppContext, AuthContext, UserContext } from '../../../../store';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { APIError } from '../../../../store/app';
import { MuiTelInput } from 'mui-tel-input';
import { StatefulPinInput } from 'react-input-pin-code';
import { VisibilityOff, Visibility } from '@mui/icons-material';


export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

export const EnterCodeModal = (props: SimpleDialogProps) => {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };
    const isSmallMobile = useMediaQuery('(max-width: 500px)', { noSsr: true });
    const user = useContext(UserContext);
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const [success, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resetToken, setResetToken] = useState<string | null>(null);


    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [password, setPassword] = useState('');

    return (
        <Dialog onClose={handleClose} open={open} sx={{ '& .MuiPaper-root': { borderRadius: "16px" } }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isSmallMobile ? "column" : "row",
                        p: 2,
                        gap: 2
                    }}
                >
                    {resetToken ? (
                        <>
                            {success ? (
                                <Typography component="p" sx={{ textAlign: 'center', fontSize: '1.7rem', lineHeight: '1.9rem' }}>
                                    <SuccessIcon color="success" sx={{ fontSize: 54 }} /> <br />
                                    <strong>Всё хорошо!</strong> Теперь вы можете войти с указанными вами телефоном и паролем <br /><br />
                                    <Button size="large" color="primary" variant="contained" onClick={handleClose}>Хорошо</Button>
                                </Typography>
                            ) : (
                                <Typography component="p" sx={{ textAlign: 'center', fontSize: '1.7rem', lineHeight: '1.9rem' }}>
                                    <Typography>Придумайте новый пароль.</Typography>
                                    <OutlinedInput
                                        autoComplete="current-password"
                                        placeholder="Пароль"
                                        type={isPasswordShown ? 'text' : 'password'}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                                                    edge="end"
                                                >
                                                    {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required
                                        sx={{ width: "100%" }}
                                    />
                                    <LoadingButton loading={isLoading} variant="contained" children="Завершить"
                                        onClick={() => {
                                            setIsLoading(true);
                                            auth.resetPassword(password, resetToken)
                                                .then(() => {
                                                    onClose('success');
                                                    app.alert("Пароль успешно сброшен", { severity: 'success' });
                                                })
                                                .catch((e: APIError) => {
                                                    console.error(e);
                                                    app.alert(e.response?.data?.meta.message || 'Непредвиденная ошибка', { severity: 'error' });
                                                })
                                                .finally(() => setIsLoading(false))
                                                ;
                                        }}
                                    />
                                </Typography>
                            )}
                        </>
                    ) : (
                        <>
                            <Box
                                component='img'
                                src={isSmallMobile ? regImageMobile : regImage}
                            />
                            <Stack
                                component="form"
                                gap={1}
                                onSubmit={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsLoading(true);

                                    const fd = new FormData(e.currentTarget);
                                    const code = fd.getAll('code').join('');

                                    auth.getPasswordResetTokenByCode(code)
                                        .then(token => {
                                            setResetToken(token);
                                        })
                                        .catch((e: APIError) => {
                                            console.error(e);
                                            app.alert(e.response?.data?.meta.message || 'Непредвиденная ошибка', { severity: 'error' });

                                        })
                                        .finally(() => setIsLoading(false))
                                        ;
                                }}
                            >
                                <Typography>Ввод кода подтверждения</Typography>
                                <StatefulPinInput name="code" length={4} initialValue="    " />
                                {/* <TextField required name="code" label="Код из СМС" variant="outlined" /> */}
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    children="Отправить"
                                    loading={isLoading}
                                />
                            </Stack>
                        </>
                    )}
                </Box>
            </Box>
        </Dialog>
    );

}

const ResetPasswordModal = (props: SimpleDialogProps) => {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
        setTimeout(() => setIsSuccess(false), 500);
    };
    const isSmallMobile = useMediaQuery('(max-width: 500px)', { noSsr: true });
    const user = useContext(UserContext);
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [phone, setPhone] = useState('')

    const phoneChange = (newPhone) => {
        setPhone(newPhone)
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ '& .MuiPaper-root': { borderRadius: "16px" } }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: isSmallMobile ? "column" : "row",
                        p: 2,
                        gap: 2
                    }}
                >
                    <Box
                        component='img'
                        src={isSmallMobile ? regImageMobile : regImage}
                    />
                    <Stack
                        component="form"
                        gap={1}
                        onSubmit={async e => {
                            e.stopPropagation();
                            e.preventDefault();
                            setIsLoading(true);
                            auth.sendPasswordResetSMS(e.currentTarget)
                                .then(() => {
                                    onClose('success');
                                })
                                .catch((e: APIError) => {
                                    console.error(e);
                                    app.alert(e.response?.data?.meta.message || 'Непредвиденная ошибка', { severity: 'error' });
                                })
                                .finally(() => setIsLoading(false))
                                ;
                        }}
                    >
                        <Typography>Сброс пароля</Typography>
                        <Typography>Если Вы забыли свой пароль, то можете воспользоваться этой формой для его восстановления. Вы получите СМС с кодом подтверждения.</Typography>
                        <MuiTelInput name="phone" defaultCountry="RU" label="Телефон" placeholder='+7XXXXXXXXXX' value={phone} onChange={phoneChange} sx={{ "& .MuiIconButton-root": { display: "none" } }} required />
                        {/* <TextField required type="number" name="contactPhone" label="Номер телефона" variant="outlined" /> */}
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="secondary"
                            children="Сбросить"
                            loading={isLoading}
                        />
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    );
}

export default observer(ResetPasswordModal);
