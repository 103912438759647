    import * as React from "react";
import {FC, useContext, useEffect} from "react";
import {AppContext, OrderContext} from "../../../store";
import {observer} from "mobx-react-lite";
import List from "../../List";
import Heading from "../HeadingView";
import {OrdersCard, OrdersTableRow} from "../../Order";
import {Typography, useMediaQuery, useTheme} from "@mui/material";

type Props = {

};

const OrdersView: FC<Props> = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });
    const order = useContext(OrderContext);
    const items = order.items();

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('Мои заказы');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Мои заказы' },
        ])
    });

    return (<>
        <Heading/>
        <List
            component={isSmall ? OrdersCard : OrdersTableRow}
            variant={isSmall ? undefined : 'table'}
            items={items}
            loadNext={() => order.loadNextPage()}
        />
    </>);
};

export default observer(OrdersView);