import { Badge, BadgeProps, Box, Button, CircularProgress, Divider, InputAdornment, Paper, TextField, Tooltip, TooltipProps, Typography, Zoom, styled, tooltipClasses, useMediaQuery, useTheme } from "@mui/material";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { PropsWithChildren, ReactNode, forwardRef, useContext, useEffect, useState } from "react";
import { AppContext, AuthContext, BonusContext, CartContext, UserContext, WaitListContext } from "../../../store";
import { observer } from "mobx-react-lite";
import ProfileIcon from "../../../icons/profileNew.svg"
import OrdersIcon from "../../../icons/orders.svg"
import FavoritesIcon from "../../../icons/favorites.svg"
import BasketIcon from "../../../icons/basket.svg"
import { formatPrice as $ } from "../../../utils";
import PopoverMenuItems from "./PopoverMenuItems";
import BonusInfo from "../../bonus/BonusInfo";
import BonusIcon from "../../../icons/points.svg"

type Props = PropsWithChildren & {
    readonly description?: ReactNode;
};

const CartPopover = () => {
    const theme = useTheme();
    const cart = useContext(CartContext);
    const summary = cart.checkedSummary;
    const count = cart.summary?.totalItems || 0;

    return (
        <Paper sx={{ width: "185px", height: "100%", p: "10px 20px", fontSize: 12 }}>
            <Box color="#8F95B2" display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Товаров в корзине:</Typography>
                <Typography>{count}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography color="#8F95B2">На сумму:</Typography>
                <Typography fontSize="14px" color={theme.palette.warning.main}>{$(summary?.priceWithDiscount)}</Typography>
            </Box>
        </Paper>
    );
};

const ProfilePopover = forwardRef((p: { onItemClick?: () => void }, ref) => {
    const theme = useTheme();
    const user = useContext(UserContext);

    return (
        <Box ref={ref}>
            <Paper sx={{ px: 1, py: 1, fontSize: 12 }}>
                <Typography sx={{ mx: 1 }} variant="body1" fontWeight="bold" fontSize={16} color={theme.palette.primary.main}>
                    {user.user?.fullName}
                </Typography>
                {user.debtInfo && (<>
                    <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", pl: 1 }}>
                        <Box>
                            <Typography fontSize={12} component="div" color="#8F95B2" >
                                Сумма дебиторской задолженности
                            </Typography>
                            <Typography component="span" fontWeight="bold" color={theme.palette.warning.main}>
                                {$(user.debtInfo.debt)}
                            </Typography>{" "}
                        </Box>
                        {user.debtInfo.overdebt ? (<>
                            <Box>
                                <Typography component="div" color="text.secondary" >
                                    В т.ч. просроченная:
                                </Typography>
                                <Typography component="div" color={theme.palette.warning.main}>
                                    {$(user.debtInfo.overdebt)}
                                </Typography>
                            </Box>
                        </>) : (
                            user.debtInfo.paydate && (<>
                                <Box>
                                    <Typography color="#8F95B2" fontSize={12} component="div">
                                        Следующая оплата до:
                                    </Typography>
                                    <Typography component="div" fontWeight="bold" color="#8F95B2">
                                        {user.debtInfo.paydate}
                                    </Typography>
                                </Box>
                            </>)
                        )}

                    </Box>
                </>)}
                <Divider variant="fullWidth" sx={{ pt: 1, mx: 1 }} />
                <Box
                    sx={{ '& .MuiButton-root': { my: 1 }, '& a': { display: 'block', color: '#081735', px: 1 } }}
                    onClick={p.onItemClick}
                >
                    <PopoverMenuItems />
                </Box>
            </Paper>
        </Box>
    );
});

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        marginTop: '0 !important',
        padding: 0, margin: 0,
        backgroundColor: 'transparent',
    },
}));

const BonusPopover = forwardRef((p: { onItemClick?: () => void }, ref) => {

    const bonus = useContext(BonusContext);

    return (<>
        <BonusInfo
            sx={{ color: "black" }}
            mode="profile"
            balance={bonus.status?.totalBalance || 0}
            available={Math.max(bonus.status?.balance ?? 0, 0)}
            inactive={bonus.status?.inactive ?? 0}
        />
    </>)
})

const HeadingView = (p: Props) => {
    const auth = useContext(AuthContext);
    const theme = useTheme()
    const app = useContext(AppContext);
    const bonus = useContext(BonusContext);
    const user = useContext(UserContext);
    const cart = useContext(CartContext);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
    const [menuOpened, setMenuOpened] = useState(false);
    const [bonusOpened, setBonusOpened] = useState(false);

    const isSmall = useMediaQuery(theme.breakpoints.down('smm'));

    const wasLoggedIn = auth.wasLoggedIn;
    const debtInfo = user?.debtInfo;
    const showOncePopup = app.scheduledTask('BonusSystemPopup', () => !!bonus.status?.enabled);
    const StyledBadge = styled(Badge)<BadgeProps>(() => ({
        '& .MuiBadge-badge': {
            color: 'white',
        },
    }));
    useEffect(() => {

        setSearchQuery(searchParams.get('q') || '')

    }, [searchParams.get('q')]);

    const count = cart.summary?.totalItems || 0;
    useEffect(() => {
        if (!wasLoggedIn) return;

        if (
            showOncePopup ||
            !bonus.status?.enabled
        )
            return;

        // app.showDialog(<InfoPopup />);

    }, [bonus.status?.enabled, wasLoggedIn]);


    return (<>
        <Box sx={{ borderRadius: "1rem", backgroundColor: "none", border: "1px solid #e6e8f0 ", py: 1, px: 2, my: 2 }}>
            {user.user?.fullName ? (<>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, alignItems: "center" }}>
                    <Button component={Link} to="/profile" variant="text" size="large" sx={{ padding: 0, my: 1 }}>
                        <Typography fontSize={isSmall ? "14px" : "18px"} fontWeight="bold" color="#3F8CFF">
                            {user.user?.fullName}
                        </Typography>
                    </Button>
                    {bonus.status?.enabled && (<>
                        <LightTooltip
                            placement="top"
                            title={
                                <BonusPopover
                                    onItemClick={() => setBonusOpened(false)}
                                />
                            }
                            open={bonusOpened}
                            onOpen={() => setBonusOpened(true)}
                            onClose={() => setBonusOpened(false)}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    px: "15px",
                                    py: "6px",
                                    backgroundColor: "#F9FAFC",
                                    border: "1px solid #E6E8F0",
                                    height: "100%",
                                    width: "max-content",
                                    borderRadius: "12px"
                                }}
                            >
                                <BonusIcon />
                                <Typography pl={1} color={theme.palette.info.contrastText} fontSize={isSmall ? "14px" : "18px"} fontWeight="bold">
                                    {Math.floor(bonus.status?.totalBalance || 0)}
                                </Typography>
                            </Box>
                        </LightTooltip>
                    </>)}
                </Box>
                {debtInfo && (<>
                    <Divider sx={{ py: "5px" }} variant="fullWidth" />
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", smm: "row" }, justifyContent: { xs: "start", smm: "space-between" }, mt: 1, flexWrap: "wrap", gap: 1, }}>
                        <Box display="flex" gap={1} >
                            <Typography fontSize={isSmall ? "10px" : "14px"} fontWeight="600" color="#8F95B2" component="div">
                                Дебиторская задолженность:  (на {user.debtInfo.updatedAt})
                            </Typography>
                            <Typography fontSize={isSmall ? "10px" : "14px"} fontWeight="bold" component="span" color={theme.palette.warning.main}>
                                {$(user.debtInfo.debt)}
                            </Typography>{" "}
                        </Box>
                        {user.debtInfo.overdebt ? (<>
                            <Box>
                                <Typography fontSize={isSmall ? "10px" : "14px"} fontWeight="600" color="#8F95B2" component="div">
                                    В т.ч. просроченная:
                                </Typography>
                                <Typography component="div" fontSize={isSmall ? "10px" : "14px"} fontWeight="bold" color={theme.palette.warning.main}>
                                    {$(user.debtInfo.overdebt)}
                                </Typography>
                            </Box>
                        </>) : (user.debtInfo.paydate && (<>
                            <Box display="flex" gap={1}>
                                <Typography fontSize={isSmall ? "10px" : "14px"} fontWeight="600" color="#8F95B2" component="div">
                                    Следующая оплата до:
                                </Typography>
                                <Typography component="div" fontSize={isSmall ? "10px" : "14px"} fontWeight="bold" color="#8F95B2">
                                    {user.debtInfo.paydate}
                                </Typography>
                            </Box>
                            <Box display="flex" gap={1}>
                                <Typography fontSize={isSmall ? "10px" : "14px"} fontWeight="600" color="#8F95B2" component="div">
                                    Сумма оплаты:
                                </Typography>
                                <Typography component="div" fontSize={isSmall ? "10px" : "14px"} fontWeight="bold" color="#8F95B2">
                                    {$(user.debtInfo.paysum)}
                                </Typography>
                            </Box>
                        </>)
                        )}
                    </Box>
                </>)}
            </>) : (
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Box>)}
        </Box>
        <Paper
            sx={{
                position: { xs: "relative", sm: "sticky" },
                mb: 2,
                display: "flex",
                top: 0,
                p: 1,
                zIndex: 999,
                borderRadius: "1rem",
                mt: { xs: 4, sm: 0 }
            }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', width: "100%" }}>
                <Box
                    sx={{ position: "relative", width: "100%" }}
                    component="form"
                    onSubmit={e => {
                        e.preventDefault();
                        const params = Object.fromEntries(searchParams.entries());
                        if (searchQuery.length) {
                            params.q = searchQuery;
                            delete params.f;
                        } else
                            delete params.q;

                        const search = Object.keys(params) ? `?${createSearchParams(params)}` : '';

                        navigate({ pathname: '/catalog/', search });
                    }}>
                    <TextField
                        fullWidth
                        id="input-with-icon-textfield"
                        placeholder="Артикул, Код, Наименование"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        color="primary"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                display: "none"
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: "10px"
                            },
                            '& .MuiTextField-root': {
                                padding: "25px"
                            },
                            // maxWidth: 390,
                            borderRadius: "12px",
                            border: "1px solid #3F8CFF",
                            backgroundColor: "white",
                            zIndex: 999,
                        }}
                    />
                    <Button
                        type="submit"
                        size="large"
                        children="Найти"
                        sx={{
                            display: { xs: "none", smm: "inline-flex" },
                            '&:hover': {
                                backgroundColor: "#1371ff"
                            },
                            position: "absolute",
                            right: -70,
                            padding: "8px 16px 8px 31px",
                            background: "#3F8CFF",
                            fontWeight: 700,
                            color: "white",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: { xs: "none", sm: "flex" },
                        '& .MuiButton-root': {
                            minWidth: "auto",
                            p: { xs: 0, lg: "8px 11px" },
                        },
                        pl: { xs: 1, smm: "85px" }
                    }}>
                    <LightTooltip
                        leaveDelay={500}
                        placement="bottom-start"
                        title={
                            <ProfilePopover
                                onItemClick={() => setMenuOpened(false)}
                            />
                        }
                        open={menuOpened}
                        onOpen={() => setMenuOpened(true)}
                        onClose={() => setMenuOpened(false)}
                    >
                        <Button
                            startIcon={<ProfileIcon />}
                            component={Link}
                            to="/profile"
                            variant="text"
                            size="large"
                        >
                            <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "black", display: { lg: "block", xs: "none" } }}>
                                Профиль
                            </Typography>
                        </Button>
                    </LightTooltip>

                    <Button startIcon={<OrdersIcon />} component={Link} to="/order" variant="text" size="large">
                        <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "black", display: { lg: "block", xs: "none" } }}>
                            Заказы
                        </Typography>
                    </Button>

                    <Button startIcon={<FavoritesIcon />} component={Link} to="/favorites" variant="text" size="large">
                        <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "black", display: { lg: "block", xs: "none" } }} >
                            Избранное
                        </Typography>
                    </Button>
                    <LightTooltip placement="bottom-end" title={<CartPopover />}>
                        <Button
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            sx={{ position: "relative" }}
                            startIcon={<BasketIcon />}
                            component={Link} to="/cart"
                            variant="text"
                            size="large"
                        >
                            <Box>
                                <Zoom in={!!count && location.pathname !== '/cart'} unmountOnExit>
                                    <StyledBadge
                                        // badgeContent={count} убираем число товаров в корзине
                                        color="warning"
                                        variant="dot"
                                        sx={{
                                            position: "absolute",
                                            right: "7px",
                                            top: { lg: "13px", xs: "3px" },
                                            zIndex: 2
                                        }}
                                    />
                                </Zoom>
                                <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "black", display: { lg: "block", xs: "none" } }}>
                                    Корзина
                                </Typography>

                            </Box>
                        </Button>
                    </LightTooltip>
                </Box>
            </Box>
        </Paper >
        <Box>
            <Typography variant="h4" fontWeight="bold" component="div" sx={{ pr: 1, pb: { xs: 2, md: 1 } }}>
                {app.title}
            </Typography>
            {p.description && (
                <Box children={p.description} />
            )}
            {/* <Breadcrumbs items={app.breadcrumbs} /> */}
            <Box sx={{ mb: 1 }}>
                {p.children}
            </Box>

        </Box>
    </>);
}

export default observer(HeadingView);