import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import { request } from "../utils";
import { getBonusToGiveForItem, Item } from "../../ts-shared/bonus";
import { BonusTransaction } from "../components/views/ProfileView/BonusView/Imports";
import { CacheableWithPagination } from "../common/caching";
import dayjs from "dayjs";
import { getAllPromoItemIds } from "../../ts-shared/promo";

type Status = {
    readonly enabled: boolean;
    readonly balance: number;
    readonly inactive: number;
    readonly totalBalance: number;
}

export type State = {
    readonly status?: Status;
    readonly transactions?: CacheableWithPagination & {
        readonly list: BonusTransaction[],
    }
};

export class Bonus {
    _store: Store = undefined;
    _state: State = {};
    _indexedPromoItemIds: Record<string, 1> = undefined;

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            _indexedPromoItemIds: observable,
            indexedPromoItemsIds: computed,
            status: computed,
            transactions: computed,
            invalidate: action,
        })
    }

    setState(state: State | undefined) {
        if(state?.transactions?.list) {
            const trxs = state.transactions.list;
            for(const trx of trxs) {
                (trx as any).date = dayjs(trx.date);
            }
        }

        this._state = merge(this._state, state || {});
    }

    invalidate() {
        (this._state as any).status = undefined;
        (this._state as any).transactions = undefined;
    }

    get status(): Status|undefined {
        if(this._state.status === undefined) {
            request({
                method: 'GET',
                url: `/bonus`,
            })
            .then(res => {
                this._store.setState(res.data.data.state);
            });
        }

        return this._state.status;
    }

    get transactions() {

        if(this._state?.transactions === undefined) {
            request({
                method: 'GET',
                url: `/profile/bonus`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            }).then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state?.transactions;
    }

    transactionsLoadNextPage() {

        request({
            method: 'GET',
            url: `/profile/bonus`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.transactions.nextPage,
            }
        }).then(res => {
            this._store.setState(res.data.state);
        });
    }

    get indexedPromoItemsIds() {
        if(this._indexedPromoItemIds === undefined) {
            const user = this._store.user.user;
            if(!user) return user as undefined;

            const context = this._store.catalog.promoContext;
            if(!context) return context as undefined;

            const bonusStatus = this._store.bonus.status;
            if(!bonusStatus) return bonusStatus as undefined;

            const passUser = {
                id: user.id, type: user.type, matrixCode: user.matrixCode, 
                parentPriceCode: user.parentPriceCode, bonusesEnabled: bonusStatus.enabled,
            };

            this._indexedPromoItemIds = Object.fromEntries(getAllPromoItemIds(passUser, context).map(v => [v, 1]));
        }

        return this._indexedPromoItemIds;
    }

    countBonusForItem(item: Item): number|undefined {
        const itemIds = this.indexedPromoItemsIds;
        if(!itemIds) return itemIds as undefined;

        return getBonusToGiveForItem(item, itemIds);
    }
}

export const Context = createContext<Bonus>(undefined);