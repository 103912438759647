import {
    Box,
    List,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Data } from "../../../components/CallMeDialog";
import MenuItemRetail from "../../../components/MenuBar/MenuItem"

type Props = PropsWithChildren<{
    readonly onCallRequest?: (data: Data) => void;
    readonly callRequestProcessing?: boolean;
}>;


const MenuBarRetail: FC<Props> = ({ children }) => {
    const theme = useTheme();
    const isSmallPhone = useMediaQuery('(max-width: 425px)', { noSsr: true });

    return (
        <Box component="nav">
            <List
                component="div"
                aria-label="secondary mailbox folder"
                sx={{
                    display: "flex",
                    p: 0,
                    '& .MuiListItemButton-root': {
                        borderRadius: '12px',
                        padding: "8px",
                        '& svg': {
                            color: '#8F95B2',
                        }
                    },
                    '& a.active .MuiListItemButton-root': {
                        backgroundColor: theme.palette.primary.main,
                        '& .MuiListItemText-root': {
                            color: 'white'
                        },
                        '& svg': {
                            color: 'white',
                        }
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: 24,
                    }
                }}
            >
                {children}
            </List>

        </Box>
    );
}

export { MenuItemRetail };

export default MenuBarRetail;