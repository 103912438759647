import Carousel from "react-material-ui-carousel";
import { useContext, useMemo } from "react";
import { BonusContext, NotificationsContext, UserContext } from "../../store";
import { observer } from "mobx-react-lite";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { executePredicates } from "../../../ts-shared/predicates";
type Props = {
    readonly sx?: SxProps<Theme>;
    readonly variant: 'home' | 'promo';
}

const CarouselItem = (p: { src: string, to?: string }) => {
    return (
        <Box
            component={p.to && Link}
            to={p.to}
            draggable={p.to && false}
        >
            <img
                src={p.src}
                alt="Акционный баннер"
                width="1063"
                height="450"
                style={{
                    display: 'block',
                    width: '100%',
                    height: 'auto',
                    borderRadius: '16px',
                    pointerEvents: 'none',
                }}
            />
        </Box>
    );
}

export const PromoItem = (p: Props) => {

    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const notification = useContext(NotificationsContext);
    const banners = notification.banners;
    const bonusesEnabled = bonus.status?.enabled;
    const items = useMemo(() => {
        if (!banners || !user.user || user.user.parentPriceCode || bonusesEnabled === undefined)
            return [];  // Акции выключены для всех дочерних аккаунтов

        const items = [];
        for (const banner of banners.list) {
            if (!banner.showAtHomePage && p.variant === 'home') continue;

            const u = user.user;
            const passUser = {
                id: u.id, type: u.type, matrixCode: u.matrixCode,
                parentPriceCode: u.parentPriceCode, bonusesEnabled
            };

            if (executePredicates(passUser, banner.userChecker))
                items.push({ src: banner.image, to: banner.link });
        }

        return items;
    }, [banners, user.user, p.variant]);

    return (<>
        {!items.length ? undefined : <>
            {items.map(v =>
                <Box sx={{ width: { xs: "100%", md: "48%" }, flexGrow: 1 }}>
                    <CarouselItem key={v.src} src={v.src} to={v.to} />
                </Box>
            )}
        </>}
    </>);
}


const PromoCarousel = (p: Props) => {

    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const notification = useContext(NotificationsContext);
    const banners = notification.banners;

    const bonusesEnabled = bonus.status?.enabled;

    const items = useMemo(() => {
        if (!banners || !user.user || user.user.parentPriceCode || bonusesEnabled === undefined)
            return [];  // Акции выключены для всех дочерних аккаунтов

        const items = [];
        for (const banner of banners.list) {
            if (!banner.showAtHomePage && p.variant === 'home') continue;

            const u = user.user;
            const passUser = {
                id: u.id, type: u.type, matrixCode: u.matrixCode,
                parentPriceCode: u.parentPriceCode, bonusesEnabled
            };

            if (executePredicates(passUser, banner.userChecker))
                items.push({ src: banner.image, to: banner.link });
        }

        return items;
    }, [banners, user.user, p.variant]);

    return (<>
        {!items.length ? undefined : (
            <Carousel
                autoPlay
                cycleNavigation
                navButtonsAlwaysVisible
                indicators
                sx={{
                    mt: 2,
                    filter: 'drop-shadow(0px 8px 16px rgba(143, 149, 178, 0.15))',
                    borderRadius: '16px',
                    ...(p.sx || {}),
                }}
            >
                {items.map(v => <CarouselItem key={v.src} src={v.src} to={v.to} />)}
            </Carousel>
        )}
    </>);
}

export default observer(PromoCarousel);