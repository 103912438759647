import Dexie, { Table } from 'dexie';

export interface Item {
    id?: string;
    itemId: string;
    code: string;
    item: string;
    price: number;
    priceDiscount: number;
    zeroLeft: 0 | 1;
    stock: number;
}

export interface FavoritesItem extends Item {}
export interface WaitListItem extends Item {}
export interface CartItem extends Item {
    count: number;
}

export interface KeyValueItem {
    key: string;
    value: any;
}

const bucketTables = {
    cart: true,
    favorites: true,
    waitList: true,
};
export type TNameToBucketItem = {
    cart: CartItem;
    favorites: FavoritesItem;
    waitList: WaitListItem;
};

export type BucketTableName = keyof typeof bucketTables;
export const BUCKET_TABLE_NAMES = Object.keys(bucketTables) as ReadonlyArray<BucketTableName>;

export const getUpdatedAtkey = (tableName: string) => `BucketUpdatedAt_${tableName}`;

export class DexieDB extends Dexie {
    cart!: Table<CartItem>;
    favorites!: Table<FavoritesItem>;
    waitList!: Table<WaitListItem>;
    keyValue!: Table<KeyValueItem>;

    constructor(userId: number) {
        super(`shop-${userId}`);
        this.version(5).stores({
            cart:      '++id, code, item, count, price, priceDiscount, zeroLeft, stock, [zeroLeft+id]', // Primary key and indexed props
            favorites: '++id, code, item, price, priceDiscount, zeroLeft, stock, [zeroLeft+id]',
            waitList:  '++id, code, item, price, priceDiscount, zeroLeft, stock, [zeroLeft+id]',
        })
        .upgrade(tx => { // Апгрейд с 4 версии на 5
            return Promise.all(Object.keys(bucketTables).map(tname => {
                return tx.table(tname).toCollection().modify((it: Item) => {
                    it.stock = it.zeroLeft ? 0 : 999;
                })
            }));
        });

        this.version(6).stores({ // Добавляет новую таблицу к предыдущим, а не оставляет только одну
            keyValue: 'key',
        })
        .upgrade(async tx => { // Апгрейд с 5 версии на 6
            const getStorageKey = (userId: number, tableName: BucketTableName) => `AppBucketUpdatedAt_${userId}_${tableName}`;
            const table = tx.table('keyValue');

            for(const tname of BUCKET_TABLE_NAMES) {
                const ts = window.localStorage.getItem(getStorageKey(userId, tname));
                if(!ts) continue;

                await table.put({ key: getUpdatedAtkey(tname), value: +ts });
            }
        });

        this.version(7).stores({
            cart:      '++id, &itemId, code, item, count, price, priceDiscount, zeroLeft, stock, [zeroLeft+itemId]',
            favorites: '++id, &itemId, code, item, price, priceDiscount, zeroLeft, stock, [zeroLeft+itemId]',
            waitList:  '++id, &itemId, code, item, price, priceDiscount, zeroLeft, stock, [zeroLeft+itemId]',
        })
        .upgrade(tx => {
            return Promise.all(Object.keys(bucketTables).map(tname => {
                return tx.table(tname).toCollection().modify((it: Item) => {
                    it.itemId = JSON.parse(it.item).id;
                })
            }));
        });
    }
}

let db: Record<number, DexieDB> = {};
const getDatabase = (userId: number): DexieDB => {
    if(!db[userId]) db[userId] = new DexieDB(userId);
    return db[userId];
}

export default getDatabase;