// noinspection JSUnusedGlobalSymbols

import {
    pass as _promoPass,
    checkItems as _promoCheckItems,
    getItemIds as _promoGetItemIds,
    getIncludedItems as promoGetIncludedItems,
    getSummary as promoGetSummary,
    Rule as PromoRule,
    getAllPromoItemIds as promoGetAllItemIds,
} from "../ts-shared/promo";
import dayjs from "dayjs";
import { getPermissions as adminGetPermissions } from "../ts-shared/admin";
import { calculateOrderBonusInfo as _bonusCalculateOrderBonusInfo } from "../ts-shared/bonus";

const bonusCalculateOrderBonusInfo: typeof _bonusCalculateOrderBonusInfo = (items, userBalance, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _bonusCalculateOrderBonusInfo(items, userBalance, user, {
        ...context,
        promoList: newPromoList,
    })
}

const promoPass: typeof _promoPass = (items, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _promoPass(items, user, {
        ...context,
        promoList: newPromoList,
    })
}

const promoCheckItems: typeof _promoCheckItems = (itemIds, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _promoCheckItems(itemIds, user, {
        ...context,
        promoList: newPromoList,
    })
}

const promoGetItemIds: typeof _promoGetItemIds = (itemIds, user, context) => {

    const newPromoList: Record<number, PromoRule> = {};
    for(const id in context.promoList) {
        const promo = context.promoList[id];
        newPromoList[id] = {
            ...promo,
            validSince: dayjs(promo.validSince as any as string),
            validUntil: dayjs(promo.validUntil as any as string),
        };
    }

    return _promoGetItemIds(itemIds, user, {
        ...context,
        promoList: newPromoList,
    })
}

export { promoPass, promoGetSummary, promoGetIncludedItems, adminGetPermissions, bonusCalculateOrderBonusInfo, promoCheckItems, promoGetItemIds, promoGetAllItemIds }