import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
    Box, Divider,
    Unstable_Grid2 as Grid,
    Paper, Stack, Typography, useTheme, Button, ButtonGroup
} from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    AppContext,
    BonusContext,
    CartContext,
    FavoritesContext,
    NotificationsContext,
    OrderContext,
    UserContext,
    WaitListContext
} from "../../../store";
import AppList, { Patch } from "../../List";
import { Link, useParams } from "react-router-dom";
import Heading from "../HeadingView";
import { status } from "../../Order/ListTable";
import LoadingPaper from "../../LoadingPaper";
import { formatPrice as $ } from "../../../utils";
import Date from "../../Date";
import { LoadingButton } from "@mui/lab";
import GetOrderInvoiceLink from "../../GetOrderInvoiceLink";
import { OrderWideCard } from "../../Item";
import TotalPrice from "../../Order/TotalPrice";
import NotificationEmailSetup from "../../NotificationEmailSetup";
import dayjs from "dayjs";
import BonusInfo from "../../bonus/BonusInfo";
import OrderApprovalBonus from "./OrderApprovalBonus";

type Props = {

};

const _createWideCard = (item: any, editMode: boolean, events?: {
    onCountChange: (count: number) => void,
    onRemoveClick: () => void,
}, patch?: null | { count: number }) => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;

    const app = useContext(AppContext);

    const favorite = favorites.includes(user.user?.id, item.snapshot.id);
    const inCart = cart.includes(user.user?.id, item.snapshot.id);

    if (patch?.count !== undefined) {
        item = { ...item, item: { ...item.item, quantity: patch.count } };
    }

    return (
        <OrderWideCard
            removed={patch === null}
            editMode={editMode}
            item={item}
            bonus={item.bonus}
            inCart={inCart}
            favorite={favorite}
            outOfStock={item.item?.count <= 0 && ([0, 1].includes(order.order.status) || item.snapshot.state === 2)}
            onFavoritesClick={async () => {
                const it = item.item;
                if (!it) return;
                const { orderPrice, ...cartItem } = it;

                if (favorite) {
                    await favorites.remove(cartItem, user.user?.id);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem, user.user?.id);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async () => {
                const it = item.item;
                if (!it) return;
                const { orderPrice, ...cartItem } = it;

                await cart.addWithAmount(cartItem, item.snapshot.quantity, user.user?.id);

                if (cartItem.count <= 0 && user.user?.matrixCode != 1) {
                    await waitList.add(cartItem, user.user?.id);
                    if (!notify.email)
                        app.showDialog(<NotificationEmailSetup />);
                    app.alert(`Добавлено в корзину и лист ожидания`);
                }
                else {
                    app.alert(`Добавлено в корзину`);
                }
            }}
            onCountChange={events?.onCountChange}
            onRemoveClick={events?.onRemoveClick}
        />
    );
}

const MyWideCardEditable = observer((item: {
    item, events: {
        onCountChange: (item: any, count: number) => void,
        onRemoveClick: (item: any) => void,
    }, patch?: null | { count: number }
}) => {

    return _createWideCard(item.item, true, {
        onCountChange: v => item.events.onCountChange(item, v),
        onRemoveClick: () => item.events.onRemoveClick(item),
    }, item.patch);
});

const MyWideCard = observer((item: { item }) => {
    return _createWideCard(item.item, false);
});

const keyResolver = v => `${v.snapshot.id}_${(v.snapshot.price || 0).toFixed(2)}`;

const OrderItemView: FC<Props> = () => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const [addingItems, setAddingItems] = useState(false);
    const [patch, setPatch] = useState({} as Patch<string, { count: number }>);
    const bonus = useContext(BonusContext);
    const [useBonuses, setUseBonuses] = useState(false);

    const theme = useTheme();

    const app = useContext(AppContext);

    const [isApproving, setIsApproving] = useState(false);
    const [isReceiving, setIsReceiving] = useState(false);

    const items = order?.items;

    const total = useMemo(() => {
        if (!items?.list) return { price: 0, count: 0 };

        let price = 0;
        let count = 0;
        for (const v of items.list) {
            const p = patch[keyResolver(v)];
            if (p === null) continue;

            const c = p?.count || v.snapshot.quantity;
            count += c;
            price += v.snapshot.price * c;
        }
        return { price, count };

    }, [items?.list, patch]);

    useEffect(() => {
        if (!order?.order) return;

        app.setTitle(`Заказ #${order.order.id}`);
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Мои заказы', to: '/order' },
            { name: `#${order.order.id}` }
        ])
    }, [order?.order]);

    const addAllToCart = () => {
        const list = items.list.filter(v => !!v.item);
        if (list.length === 0) {
            app.alert('Ни один товар не добавлен', { severity: 'warning' });
            return;
        }

        setAddingItems(true);
        const partially = list.length != items.list.length;

        cart.addManyWithAmount(list.map(it => ({
            item: it.item,
            amount: it.snapshot.quantity,
        })), user.user?.id)
            .then(() => app.alert("Товары добавлены в корзину" + (partially ? ' частично' : '')))
            .finally(() => setAddingItems(false));
    };

    const approve = (id: string, patch: null | Patch, useBonuses: boolean) => {
        setIsApproving(true);
        orderStore.approveOrder(id, patch, useBonuses)
            .catch(e => app.alert(e.response.data?.meta?.message || 'Непредвиденная ошибка', { severity: 'error' }))
            .finally(() => setIsApproving(false))
            ;
    }

    const orderReceived = (id: string) => {
        setIsReceiving(true);
        orderStore.setOrderReceived(id)
            .catch(e => app.alert(e.response.data?.meta?.message || 'Непредвиденная ошибка', { severity: 'error' }))
            .finally(() => setIsReceiving(false))
            ;
    }

    const toSetReceived = !!order?.order && [2,3,5,6,7].includes(order.order.status) && 
        !order.order.receivedByRecipient && !!order.payer && 
        order.payer.id != user.user?.id
    ;
    const toApprove = order?.order.status === 8 && !!order?.recipient;
    const bonusesEnabled = bonus.status?.enabled;
    const allItemsRemoved = !!items?.list.every(v => patch[keyResolver(v)] === null);

    return (<>
        <Heading>
            <Typography variant="h6" color={theme.palette.warning.main}>
                Внимание! Цены актуальны на момент формирования заказа.
            </Typography>
        </Heading>
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }}>
            <Grid xs={12} md={7} lg={8}>
                <AppList
                    patch={patch}
                    component={toApprove ? MyWideCardEditable : MyWideCard}
                    items={items}
                    loadNext={() => orderStore.orderItemsLoadNextPage(orderId)}
                    keyResolver={keyResolver}
                    groups={toApprove ? undefined : [
                        item => ({ text: 'Добавлено оператором', condition: () => item.snapshot.state === 1 }),
                        item => ({ text: 'Убрано оператором', condition: () => item.snapshot.state === 2 }),
                    ]}
                    events={{
                        onCountChange: (item: any, v: number) => {
                            const key = keyResolver(item.item);
                            const p = { ...patch };
                            if (!p[key]) p[key] = { count: 0 };

                            p[key].count = v;
                            setPatch(p);
                        },
                        onRemoveClick: (item: any) => {
                            const key = keyResolver(item.item);

                            setPatch({ ...patch, [key]: null });
                        }
                    }}
                />
            </Grid>
            <Grid xs={12} md={5} lg={4}>
                {order?.order ? (
                    <Stack sx={{ p: 2, mb: { xs: 4, md: 0 } }} component={Paper} direction="column" elevation={1} spacing={2}>
                        {order.order.payUntil && (
                            order.order.payUntil.isBefore(dayjs())
                                ? <Typography color={theme.palette.error.main} children="Требуется оплата" />
                                : <Typography>
                                    <Box component="span" sx={{ color: theme.palette.warning.main }}>Оплатить до:</Box>{" "}
                                    <Date date={order.order.payUntil} />
                                </Typography>
                        )}
                        <Date date={order.order.date} />
                        <Box>
                            {status[order.order.status]}
                        </Box>
                        {toApprove && (
                            <Box>
                                <ButtonGroup disabled={isApproving} sx={{mb: 2}}>
                                    <Button
                                        onClick={() => approve(order.order.id, patch, useBonuses)}
                                        color="success"
                                        disabled={allItemsRemoved}
                                    >
                                        Одобрить
                                    </Button>
                                    <Button onClick={() => approve(order.order.id, null, useBonuses)} color="error" >Отклонить</Button>
                                </ButtonGroup>
                                {bonusesEnabled && (
                                    <OrderApprovalBonus
                                        items={(items?.list || []).map(v => ({
                                            id: v.item.id,
                                            bonusFactor: v.item.bonusFactor,
                                            maxDiscountFactor: v.item.maxBonusDiscountFactor,
                                            quantity: patch[keyResolver(v)]?.count || v.snapshot.quantity,
                                            outOfStock: v.item.count <= 0,
                                            price: v.snapshot.price || 0, // Цена зафиксирована на момент создания заказа
                                        }))}
                                        useBonuses={useBonuses}
                                        onUseBonusChange={setUseBonuses}
                                    />
                                )}
                            </Box>
                        )}

                        {toSetReceived ? (
                            <Box>
                                <Button
                                    variant="contained"
                                    onClick={() => orderReceived(order.order.id)}
                                    color="success"
                                    disabled={allItemsRemoved}
                                >
                                    Я получил заказ
                                </Button>
                            </Box>
                        ) : (
                            order.order.receivedByRecipient ? (
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Заказ получен грузополучателем
                                </Typography>
                            ) : undefined
                        )}

                        {(order.payer || order.recipient) && (<>
                            <Divider variant="fullWidth" />
                            {order.payer && (<>
                                <Typography variant="subtitle1">
                                    Плательщик
                                </Typography>
                                <Typography variant="body1">
                                    {order.payer.name}
                                </Typography>
                            </>)}
                            {order.recipient && (<>
                                <Typography variant="subtitle1">
                                    Грузополучатель
                                </Typography>
                                <Typography variant="body1">
                                    {order.recipient.name}
                                </Typography>
                            </>)}
                        </>)}
                        <GetOrderInvoiceLink
                            id={order.order.id}
                            status={order.order.status}
                            payUntil={order.order.payUntil}
                        />
                        {[0, 8].includes(order.order.status) && user.user?.login === 'vyatavt' && (
                            <Box
                                component={Link}
                                color={theme.palette.warning.main}
                                sx={{ ml: '5px', alignSelf: 'center' }}
                                to={`/order/${order.order.id}/xls`}
                                target="_blank"
                            >
                                Скачать XLS с товарами
                            </Box>
                        )}

                        {bonusesEnabled && order.order.bonus && (<>
                            <Divider variant="fullWidth" />
                            <BonusInfo
                                mode="order"
                                sx={{ maxWidth: { xs: "100%", md: 290 } }}
                                added={order.order.bonus.added}
                                subtracted={order.order.bonus.subtracted}
                            />
                        </>)}

                        <Divider variant="fullWidth" />
                        <Stack spacing={1}>
                            <Typography variant="subtitle1">
                                Сумма заказа
                            </Typography>
                            <Typography variant="body1">
                                Товаров: {total.count}
                            </Typography>
                            <Typography variant="body1">
                                На сумму: {$(total.price)}
                            </Typography>
                        </Stack>
                        {order.order.address ? (<>
                            <Divider variant="fullWidth" />
                            <Typography variant="subtitle1">
                                Доставка
                            </Typography>
                            {order.order.deliveryDate ? (
                                <Typography variant="body1">
                                    <strong>Дата:</strong> {order.order.deliveryDate.format('DD.MM.YYYY')}
                                </Typography>
                            ) : undefined}
                            <Typography variant="body1">
                                <strong>Адрес:</strong> {order.order.address}
                            </Typography>
                        </>) : (<>
                            <Divider variant="fullWidth" />
                            <Typography variant="subtitle1">
                                Самовывоз
                            </Typography>
                            <Typography variant="body1">
                                г. Киров, ул. Базовая, 2
                            </Typography>
                        </>)}
                        <Divider variant="fullWidth" />
                        <TotalPrice label="Итог" value={order.order.totalPrice} />
                        {order.order.comment && (<>
                            <Divider variant="fullWidth" />
                            <Typography variant="subtitle1">
                                Комментарий
                            </Typography>
                            <Typography variant="body1">
                                {order.order.comment}
                            </Typography>
                            <Divider variant="fullWidth" />
                        </>)}
                        <LoadingButton
                            sx={{
                                width: '100%',
                                backgroundColor: theme.palette.info.contrastText,
                                border: "none",
                                '&:hover': {
                                    color: "white",
                                    border: "none",
                                    backgroundColor: "#6C5DD3"
                                }
                            }}
                            color="info"
                            variant="outlined"
                            onClick={addAllToCart}
                            loading={addingItems}
                        >
                            Добавить снова в корзину
                        </LoadingButton>
                    </Stack>
                ) : (
                    <LoadingPaper />
                )}
            </Grid>
        </Grid>
    </>);
};

export default observer(OrderItemView);