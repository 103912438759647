import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { AppContext, CatalogContext } from "../../../store";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Sector } from "../../../store/catalog";
import SortBlock from "../../SortBlock";
import GroupBlock from "../../GroupBlock";
import Heading from "../HeadingView";
import CatalogTreeView, { ViewState } from "../CatalogTreeView";
import GridIcon from '@mui/icons-material/Apps';
import LineIcon from '@mui/icons-material/MenuRounded';
import CFMoto from "../../../images/CFMoto.svg"

type Props = {

};

const getUrlParams = (fullPath: string, sectors?: Sector[]) => {
    const sects = sectors?.map(s => s.alias).join('|') || ['_none_'];
    const [, , , sector, , path] = fullPath.match(new RegExp(`((\\/(${sects})(\\/|$))?\\/?((\\/\\w|\\w|\\d|-)+)?)?\\/?`, 'mi'));

    const basePath = '/catalog' + (sector ? '/' + sector : '') + (path ? '/' + path : '');

    return [sector || undefined, path, basePath];
}

const CatalogView: FC<Props> = () => {
    const catalog = useContext(CatalogContext);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewState, setViewState] = useState<ViewState | undefined>();
    const filters: Record<number, any> = useMemo(() => searchParams.has('f') ? JSON.parse(searchParams.get('f')) : undefined, [searchParams.get('f')]);

    const [sector, path, basePath] = useMemo(() => getUrlParams(location.pathname.substring('/catalog'.length), catalog.sectors), [location.pathname, catalog.sectors]);

    const app = useContext(AppContext);
    useEffect(() => {
        app.setTitle('');
        app.setBreadcrumbs([])
    });

    const listMode = catalog.itemsViewMode;
    const filterProps = catalog.getFilterProps(sector, path, filters, searchParams.get('q'), searchParams.get('g'), searchParams.get('s'));

    const topCategories = catalog.getTreeLevel(sector)?.list;
    const topCategory = path && topCategories && topCategories[path.split('/', 2)[0]];

    return (<>
        <Heading
            description={
                topCategory?.alias === "avtomototehnika" &&
                <Box display='flex' flexDirection='column'>
                    <Typography fontWeight="bold" sx={{ fontSize: 16 }}>
                        Переходите на официальный сайт нашего дилерского центра «Движение-Автомасла»
                        чтобы увидеть более подробную информацию, описания, акции, события и наши официальные соц сети.
                    </Typography>
                    <Box
                        component={Link}
                        to='https://cf.avtomasla.su/'
                        sx={{
                            backgroundColor: "#009BB4",
                            borderRadius: "0.75rem",
                            padding: "8px 16px",
                            mt: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            maxWidth: 240
                        }}
                    >
                        <CFMoto />
                    </Box>
                </Box>
            }
        >
            {viewState?.type == 'items' && (
                <Stack
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: "12px"
                        }
                    }}
                    spacing={1}
                    direction={{ xs: 'column', md: 'row', }}
                >
                    <SortBlock
                        sort={searchParams.get('s') || undefined}
                        onChange={s => {
                            const current = Object.fromEntries(searchParams.entries());
                            delete current.page;

                            if (s) current.s = s;
                            else delete current.s;

                            setSearchParams(current);
                        }}
                    />
                    <GroupBlock
                        group={searchParams.get('g') || undefined}
                        onChange={g => {
                            const current = Object.fromEntries(searchParams.entries());
                            delete current.page;

                            if (g) current.g = g;
                            else delete current.g;

                            setSearchParams(current);
                        }}
                    />
                    <Box display={{ xs: 'none', md: 'flex' }}>
                        <IconButton
                            onClick={() => catalog.setItemsViewMode('row')}
                            color={listMode === 'row' ? 'primary' : undefined}
                        >
                            <GridIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => catalog.setItemsViewMode('grid')}
                            color={listMode === 'grid' ? 'primary' : undefined}
                        >
                            <LineIcon />
                        </IconButton>
                    </Box>
                </Stack>
            )}
        </Heading >
        <CatalogTreeView
            itemsMode={listMode === 'grid' ? 'grid' : undefined}
            path={path}
            sector={sector}
            basePath={basePath}
            filters={filters}
            group={searchParams.get('g')}
            search={searchParams.get('q')}
            sort={searchParams.get('s')}
            tree={listMode && catalog.tree}
            filterProps={filterProps}
            loadNextPage={() => catalog.loadNextPage(sector, path, filters, searchParams.get('q'), searchParams.get('g'), searchParams.get('s'))}
            onFiltersChange={selected => {
                const current = Object.fromEntries(searchParams.entries());
                delete current.page;

                if (selected) current.f = JSON.stringify(selected);
                else delete current.f;

                setSearchParams(current);
            }}
            onViewChange={setViewState}
        />

    </>);
};

export default observer(CatalogView);