import { observer } from "mobx-react-lite";
import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import BonusIcon from "../../../icons/points.svg"
import { BonusContext } from "../../../store";
import { useContext } from "react";
import List from "../../../components/List";
import { OrdersCardBonusRetail } from "./Imports";
import { OrdersTableRowBonusRetail } from "./Imports";
import { formatPrice } from "../../../utils";

const $ = (val: number) => formatPrice(val, false);

type Props = {
    readonly available?: number;
    readonly balance?: number;
    readonly inactive?: number;
}

const BonusesRetail = (p: Props) => {

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const bonus = useContext(BonusContext);
    const items = bonus.transactions;

    return (<>
        <Paper sx={{ display: "flex", flexDirection: "column", p: 1, mt: 3 }}>
            <Stack
                flexDirection={isLarge ? "row" : "column"}
                justifyContent="space-between"
                alignItems="start"
                width="100%"
                gap={1}
                sx={{
                    backgroundColor: "#F9FAFC",
                    borderRadius: "12px",
                    border: "1px solid #E6E8F0",
                    px: "15px",
                    py: "20px"
                }}>
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                >
                    <Box component={BonusIcon} />
                    <Typography
                        fontWeight="bold"
                        fontSize={24}
                        mr={1}
                        color={theme.palette.info.contrastText}
                    >
                        {Math.floor(bonus.status?.totalBalance || 0)}
                    </Typography>
                </Stack>
                <Stack
                    flexDirection={isLarge ? "row" : "column"}
                    gap={isLarge ? 3 : 1}
                >
                    <Box display="flex" gap={1}>
                        <Typography>Неактивные:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{Math.floor(bonus.status?.inactive ?? 0)}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                        <Typography>Доступно для списания:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{$(Math.max(bonus.status?.balance ?? 0, 0))}</Typography>
                    </Box>
                </Stack>
            </Stack>
            <Typography textAlign="start" fontSize="24px" fontWeight="bold" color="#081735" py="15px">История транзакций</Typography>
            <List
                component={isSmall ? OrdersCardBonusRetail : OrdersTableRowBonusRetail}
                variant={isSmall ? undefined : 'bonusRetail'}
                items={items}
                loadNext={() => bonus.transactionsLoadNextPage()}
            />
        </Paper>
    </>);
}

export default observer(BonusesRetail);