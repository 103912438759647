import { FC } from "react";
import { observer } from "mobx-react-lite";
import OrderItemRetail from "../OrderItemRetail";
import { Box, Container } from "@mui/material";
import LogoIcon from '../../../icons/logo.svg';
import { Link } from "react-router-dom";

type Props = {

};

const ReceiptPageView: FC<Props> = () => {

    return (<>
        <Container maxWidth="xl"
            sx={{
                paddingLeft: { xs: 2, lg: 6 },
                paddingRight: { xs: 2, lg: 6 },
            }}
        >
            <Link to="https://avtomasla.su">
                <Box component={LogoIcon}
                    sx={{
                        width: 158,
                        height: 29,
                        mt: 2,
                    }}
                />
            </Link>
            <OrderItemRetail />

        </Container>
    </>);
};

export default observer(ReceiptPageView);