import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack, TextField,
    Typography
} from "@mui/material";
import { formatPrice as $ } from "../../../../utils";
import TotalPrice from "../../../Order/TotalPrice";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { APIError, AppContext } from "../../../../store/app";
import LoadingPaper from "../../../LoadingPaper";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react";
import { BonusContext, CartContext, OrderContext, UserContext } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { indexBy } from "../../../../../ts-shared/utils";
import { useTheme } from "@mui/material";
import BonusInfo from "../../../bonus/BonusInfo";
import BonusIcon from "../../../../icons/points.svg"
import { DEFAULT_POINT_ID } from "../../../../store/user";

type Props = {
}

const OrderBar = (p: Props) => {
    const order = useContext(OrderContext);
    const user = useContext(UserContext);
    const theme = useTheme();
    const navigate = useNavigate();

    const [isCreatingOrder, setIsCreatingOrder] = useState(false);

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [comment, setComment] = useState('');
    const [useBonuses, setUseBonuses] = useState(false);
    const bonus = useContext(BonusContext);

    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    useEffect(() => {
        app.setTitle('Корзина');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Корзина' },
        ])
    });

    const checkedItems = cart.checkedItems || [];

    const summary = cart.checkedSummary;
    const totalItems = cart.summary?.totalItems || 0;

    const bonusSummary = cart.bonusSummary;

    const addresses = cart.addresses?.list;

    const addressesById = useMemo(() => indexBy(addresses, a => a.id), [addresses]);

    const [address, setAddress] = useState<number | null>(null);

    const [date, setDate] = useState<string | null>(null);

    let currentSchedule = addressesById[address]?.schedule;
    if (!currentSchedule?.length) currentSchedule = null;

    // const canCreateOrder = items?.list.length && address !== null && (address === 0 || summary.priceWithDiscount >= minDeliveryPrice);
    const canCreateOrder = !!summary?.totalItems && address !== null && (date !== null || !currentSchedule || address == 0);

    const bonusPart = !useBonuses ? 0 : (bonusSummary?.available || 0);
    const totalSum = summary ? (summary.priceWithDiscount || summary.priceWithoutDiscount) - bonusPart : 0;

    const bonusesEnabled = bonus.status?.enabled;


    const createOrder = (_useBonuses: boolean) => {
        setIsCreatingOrder(true);
        setOpen(false);
        order.createOrder(
            checkedItems.map(i => ({
                item: i, count: i.quantity,
            }) as any),
            comment.trim().length ? comment : undefined,
            address || null,
            date || null,
            user.user?.id,
            _useBonuses,
        )
            .then(res => {
                navigate(`/order/success/${res.data.data.orderId}`);
                app.alert('Заказ создан', { severity: 'success' });
            })
            .catch((e: APIError) => {
                const message = e.response.data?.meta?.message || 'Непредвиденная ошибка';
                app.alert(message, { severity: 'error' });
            })
            .finally(() => {
                setComment('');
                setIsCreatingOrder(false);
            })
            ;
    }


    return (
        <>
            {summary ? (
                <Paper sx={{ p: 2, mb: { xs: 4, md: 0 } }} elevation={1}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="subtitle1">
                            Сумма заказа
                        </Typography>
                        <Box>
                            <Typography variant="body1" pb="8px">
                                Товаров: {summary.totalItems}
                            </Typography>
                            <Typography variant="body1" pb="8px">
                                На сумму: {$(summary.priceWithoutDiscount)}
                            </Typography>
                            {summary.priceWithDiscount ? <Typography variant="body1">
                                Скидка: {$(Math.max(summary.priceWithoutDiscount - summary.priceWithDiscount, 0))}
                            </Typography> : undefined}
                        </Box>
                        {bonusesEnabled && bonusSummary && (
                            <BonusInfo
                                mode="cart"
                                available={bonusSummary.available}
                                toGive={bonusSummary.toGive}
                                useBonuses={useBonuses}
                                onUseBonusesChange={setUseBonuses}
                            />
                        )}
                        <Divider variant="fullWidth" />
                        <TotalPrice label="Итог" value={totalSum} />
                        <Divider variant="fullWidth" />
                        <FormControl fullWidth>
                            <InputLabel id="cart-address-select-label">Адрес доставки</InputLabel>
                            <Select
                                labelId="cart-address-select-label"
                                value={address === null ? '' : '' + address}
                                label="Адрес доставки"
                                onChange={e => {
                                    setAddress(e.target.value === "" ? null : +e.target.value)
                                    setDate(null);
                                }}
                                disabled={!summary.totalItems}
                                sx={{
                                    '& .MuiInputBase-input .MuiIconButton-root': {
                                        display: 'none',
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>Не выбран</em>
                                </MenuItem>
                                {user.user?.stockPointId === DEFAULT_POINT_ID && (
                                    <MenuItem value={0}>
                                        <strong>Самовывоз </strong> | г. Киров, ул. Базовая, 2
                                    </MenuItem>
                                )}
                                {addresses && addresses.map(addr => (
                                    <MenuItem
                                        key={addr.id}
                                        value={addr.id}
                                        sx={{
                                            justifyContent: 'space-between',
                                            flexWrap: 'nowrap',
                                        }}
                                    >
                                        {addr.name || addr.address}
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={() => navigate('/profile/delivery')}
                                        >
                                            <DeleteIcon sx={{ color: '#999999' }} />
                                        </IconButton>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {currentSchedule != null && (
                            <FormControl fullWidth>
                                <InputLabel id="cart-date-select-label">Дата доставки</InputLabel>
                                <Select
                                    labelId="cart-date-select-label"
                                    label="Дата доставки"
                                    value={date === null ? '' : date}
                                    onChange={e => setDate(e.target.value === "" ? null : e.target.value)}
                                    disabled={!summary.totalItems}
                                    sx={{
                                        '& .MuiInputBase-input .MuiIconButton-root': {
                                            display: 'none',
                                        }
                                    }}
                                >
                                    {currentSchedule.map(d => (
                                        <MenuItem
                                            key={d.id}
                                            value={d.id}
                                            sx={{
                                                justifyContent: 'space-between',
                                                flexWrap: 'nowrap',
                                            }}
                                        >
                                            {d.date.format('DD.MM.YYYY')}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <Typography variant="body1" sx={{ mt: 1 }}>
                            {address
                                ? addressesById[address]?.address || 'Нет названия'
                                : (address === null
                                    ? <em>Выберите адрес доставки или <strong>самовывоз</strong></em>
                                    : "Выбран самовывоз - г. Киров, ул. Базовая, 2"
                                )
                            }
                        </Typography>

                        <Divider variant="fullWidth" />
                        <TextField
                            label="Комментарий"
                            multiline
                            name="comment"
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            disabled={!summary.totalItems}
                        />
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={{ '.MuiDialog-paper': { padding: 2, alignItems: "center", gap: 2, borderRadius: "1rem", textAlign: "center" } }}
                        >
                            <Typography variant="h5">У вас есть активные баллы!</Typography>
                            <Box sx={{ display: "flex", gap: "5px", p: 1, backgroundColor: '#F9FAFC', border: "1px solid #E6E8F0 ", borderRadius: "16px", }}>
                                <BonusIcon />
                                <Typography pl={1} color={theme.palette.info.contrastText} fontSize="18px" fontWeight="bold">
                                    {Math.floor(bonusSummary.available)}
                                </Typography>
                            </Box>
                            <Typography fontSize="1rem" fontWeight="bold">Списать?</Typography>
                            <DialogActions sx={{ display: "flex", gap: 2, maxWidth: "20.62rem", width: "100%", justifyContent: "space-between" }}>
                                <Button
                                    sx={{
                                        width: '100%',
                                        backgroundColor: theme.palette.secondary.main,
                                        color: "white",
                                        "&:hover": { backgroundColor: "#6c5dd3" }
                                    }}
                                    onClick={() => {
                                        setUseBonuses(true);
                                        createOrder(true);
                                    }}
                                >
                                    Да
                                </Button>
                                <Button
                                    sx={{
                                        width: '100%',
                                        backgroundColor: "#8f95b2",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#4e515e" }
                                    }}
                                    onClick={() => {
                                        setUseBonuses(false);
                                        createOrder(false);
                                    }}
                                >
                                    Нет
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <LoadingButton
                            sx={{ width: '100%', backgroundColor: theme.palette.info.contrastText }}
                            color="primary"
                            size="large"
                            variant="contained"
                            disabled={!canCreateOrder}
                            loading={isCreatingOrder}
                            onClick={() => {
                                if (!useBonuses && bonusSummary.available >= 0.01) {
                                    setOpen(true);
                                    return;
                                }

                                createOrder(useBonuses);
                            }}
                        >
                            Оформить заказ
                        </LoadingButton>
                        <Button
                            size="large"
                            variant="text"
                            color="error"
                            disabled={!totalItems}
                            onClick={() => {
                                cart
                                    .clear(user.user?.id)
                                    .then(() => {
                                        app.alert('Корзина очищена', { severity: 'info' });
                                    })
                                    .catch((e: APIError) => {
                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                    ;
                            }}
                        >
                            Очистить корзину
                        </Button>
                    </Stack>
                </Paper>
            ) : (
                <LoadingPaper />
            )}
        </>
    );
}

export default observer(OrderBar);
