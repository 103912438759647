import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { AppContext } from "../../../store";
import PageContentSkeleton from "../../../components/Skeleton/PageContentSkeleton";
import ProfileContentViewRetail from "./ProfileContentViewRetail";
import InfoViewRetail from "./InfoViewRetail";

type Props = {

};


const ProfileRetail: FC<Props> = () => {
    const app = useContext(AppContext);
    return (<>
            {/* <React.Suspense fallback={<PageContentSkeleton />}>
                <ProfileContentViewRetail />
            </React.Suspense> */}
            <InfoViewRetail/>
    </>);
};

export default observer(ProfileRetail);