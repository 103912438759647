import { FC, useContext, useState } from "react";
import {
    Box, Divider,
    Unstable_Grid2 as Grid,
    Paper, Stack, Typography, useTheme, Button, ButtonGroup
} from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    AppContext,
    BonusContext,
    CartContext,
    FavoritesContext,
    NotificationsContext,
    OrderContext,
    UserContext,
    WaitListContext
} from "../../../store";
import { Link as RouterLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import LoadingPaper from "../../../components/LoadingPaper";
import Date from "../../../components/Date";
import TotalPrice from "../../../components/Order/TotalPrice";
import NotificationEmailSetup from "../../../components/NotificationEmailSetup";
import AppList, { Patch } from "../../../components/List";
import OrderApprovalBonus from "../../../components/views/OrderItemView/OrderApprovalBonus";
import BonusInfo from "../../../components/bonus/BonusInfo";
import OrderWideCardRetail from "../../components/Item/OrderWideCardRetail";
import RubleIcon from '@mui/icons-material/CurrencyRuble';
import { formatPrice } from "../../../utils";

type Props = {

};

const _createWideCard = (item: any, editMode: boolean, events?: {
    onCountChange: (count: number) => void,
    onRemoveClick: () => void,
}, patch?: null | { count: number }) => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const user = useContext(UserContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;

    const app = useContext(AppContext);

    const favorite = favorites.includes(user.user?.id, item.snapshot.id);
    const inCart = cart.includes(user.user?.id, item.snapshot.id);

    if (patch?.count !== undefined) {
        item = { ...item, item: { ...item.item, quantity: patch.count } };
    }

    return (
        <OrderWideCardRetail
            removed={patch === null}
            editMode={editMode}
            item={item}
            bonus={item.bonus}
            inCart={inCart}
            favorite={favorite}
            outOfStock={item.item?.count <= 0 && ([0, 1].includes(order.order.status) || item.snapshot.state === 2)}
            onFavoritesClick={async () => {
                const it = item.item;
                if (!it) return;
                const { orderPrice, ...cartItem } = it;

                if (favorite) {
                    await favorites.remove(cartItem, user.user?.id);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem, user.user?.id);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async () => {
                const it = item.item;
                if (!it) return;
                const { orderPrice, ...cartItem } = it;

                await cart.addWithAmount(cartItem, item.snapshot.quantity, user.user?.id);

                if (cartItem.count <= 0 && user.user?.matrixCode != 1) {
                    await waitList.add(cartItem, user.user?.id);
                    if (!notify.email)
                        app.showDialog(<NotificationEmailSetup />);
                    app.alert(`Добавлено в корзину и лист ожидания`);
                }
                else {
                    app.alert(`Добавлено в корзину`);
                }
            }}
            onCountChange={events?.onCountChange}
            onRemoveClick={events?.onRemoveClick}
        />
    );
}

const MyWideCardEditable = observer((item: {
    item, events: {
        onCountChange: (item: any, count: number) => void,
        onRemoveClick: (item: any) => void,
    }, patch?: null | { count: number }
}) => {

    return _createWideCard(item.item, true, {
        onCountChange: v => item.events.onCountChange(item, v),
        onRemoveClick: () => item.events.onRemoveClick(item),
    }, item.patch);
});

const MyWideCard = observer((item: { item }) => {
    return _createWideCard(item.item, false);
});

const keyResolver = v => `${v.snapshot.id}_${(v.snapshot.price || 0).toFixed(2)}`;

const OrderItemView: FC<Props> = () => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);
    const [patch, setPatch] = useState({} as Patch<string, { count: number }>);
    const bonus = useContext(BonusContext);
    const [useBonuses, setUseBonuses] = useState(false);

    const theme = useTheme();

    const app = useContext(AppContext);

    const [isApproving, setIsApproving] = useState(false);

    const items = order?.items;
    const operations = order?.operations;

    const approve = (id: string, patch: null | Patch, useBonuses: boolean) => {
        setIsApproving(true);
        orderStore.approveOrder(id, patch, useBonuses)
            .catch(e => app.alert(e.response.data?.meta?.message || 'Непредвиденная ошибка', { severity: 'error' }))
            .finally(() => setIsApproving(false))
            ;
    }

    const toApprove = order?.order.status === 8 && !!order?.recipient;
    const bonusesEnabled = bonus.status?.enabled;
    const allItemsRemoved = !!items?.list.every(v => patch[keyResolver(v)] === null);

    return (<>
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }} sx={{ pt: 2 }}>
            <Grid xs={12} md={7} lg={8}>
                {items?.list.length > 0 ? (
                    <AppList
                        patch={patch}
                        component={toApprove ? MyWideCardEditable : MyWideCard}
                        items={items}
                        loadNext={() => orderStore.orderItemsLoadNextPage(orderId)}
                        keyResolver={keyResolver}
                        groups={toApprove ? undefined : [
                            item => ({ text: 'Добавлено оператором', condition: () => item.snapshot.state === 1 }),
                            item => ({ text: 'Убрано оператором', condition: () => item.snapshot.state === 2 }),
                        ]}
                        events={{
                            onCountChange: (item: any, v: number) => {
                                const key = keyResolver(item.item);
                                const p = { ...patch };
                                if (!p[key]) p[key] = { count: 0 };

                                p[key].count = v;
                                setPatch(p);
                            },
                            onRemoveClick: (item: any) => {
                                const key = keyResolver(item.item);

                                setPatch({ ...patch, [key]: null });
                            }
                        }}
                    />
                ) : undefined}
                {operations?.list.length > 0 ? (
                    <Box>
                        <Typography sx={{ py: 1 }}>Услуги:</Typography>
                        <AppList
                            component={toApprove ? MyWideCardEditable : MyWideCard}
                            items={operations}
                            loadNext={() => orderStore.orderItemsLoadNextPage(orderId)}
                            keyResolver={keyResolver}
                        />
                    </Box>
                ) : undefined}
            </Grid>
            <Grid xs={12} md={5} lg={4}>
                {order?.order ? (
                    <Stack sx={{ p: 2, mb: { xs: 4, md: 0 } }} component={Paper} direction="column" elevation={1} spacing={2}>
                        {order.order.payUntil && (
                            order.order.payUntil.isBefore(dayjs())
                                ? <Typography color={theme.palette.error.main} children="Требуется оплата" />
                                : <Typography>
                                    <Box component="span" sx={{ color: theme.palette.warning.main }}>Оплатить до:</Box>{" "}
                                    <Date date={order.order.payUntil} />
                                </Typography>
                        )}
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Typography fontWeight="bold">Заказ создан:</Typography>
                            <Date variant="date" date={order.order.date} />
                        </Box>

                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Обслуживаемое ТС:</Typography>
                                <Typography>{order.order.vehicleName}</Typography>
                            </Box>
                        )}
                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Номер ТС: </Typography>
                                <Typography>{order.order.vehicleStateCode}</Typography>
                            </Box>
                        )}
                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Пробег:</Typography>
                                <Typography>{order.order.vehicleMileage}</Typography>
                            </Box>
                        )}
                        {toApprove && (
                            <Box>
                                <ButtonGroup disabled={isApproving} sx={{ mb: 2 }}>
                                    <Button
                                        onClick={() => approve(order.order.id, patch, useBonuses)}
                                        color="success"
                                        disabled={allItemsRemoved}
                                    >
                                        Одобрить
                                    </Button>
                                    <Button onClick={() => approve(order.order.id, null, useBonuses)} color="error" >Отклонить</Button>
                                </ButtonGroup>
                                {bonusesEnabled && (
                                    <OrderApprovalBonus
                                        items={(items?.list || []).map(v => ({
                                            id: v.item.id,
                                            bonusFactor: v.item.bonusFactor,
                                            maxDiscountFactor: v.item.maxBonusDiscountFactor,
                                            quantity: patch[keyResolver(v)]?.count || v.snapshot.quantity,
                                            outOfStock: v.item.count <= 0,
                                            price: v.snapshot.price || 0, // Цена зафиксирована на момент создания заказа
                                        }))}
                                        useBonuses={useBonuses}
                                        onUseBonusChange={setUseBonuses}
                                    />
                                )}
                            </Box>
                        )}

                        {(order.payer || order.recipient) && (<>
                            <Divider variant="fullWidth" />
                            {order.payer && (<>
                                <Typography variant="subtitle1">
                                    Плательщик
                                </Typography>
                                <Typography variant="body1">
                                    {order.payer.name}
                                </Typography>
                            </>)}
                            {order.recipient && (<>
                                <Typography variant="subtitle1">
                                    Грузополучатель
                                </Typography>
                                <Typography variant="body1">
                                    {order.recipient.name}
                                </Typography>
                            </>)}
                        </>)}
                        {order.order.cashId && order.order.status === 7 && (
                            <RouterLink to={`/receipt/cash/${order.order.cashId}/pdf`} target="_blank">
                                Скачать чек
                            </RouterLink>
                        )}
                        {order.order.techServiceId && (
                            <RouterLink to={`/receipt/service/${order.order.techServiceId}/pdf`} target="_blank">
                                Скачать акт
                            </RouterLink>
                        )}
                        {/* <GetOrderInvoiceLink
                            id={order.order.id}
                            status={order.order.status}
                            payUntil={order.order.payUntil}
                        /> */}

                        {bonusesEnabled && order.order.bonus && (<>
                            <Divider variant="fullWidth" />
                            <BonusInfo
                                mode="order"
                                sx={{ maxWidth: { xs: "100%", md: 290 } }}
                                added={order.order.bonus.added}
                                subtracted={order.order.bonus.subtracted}
                            />
                        </>)}
                        {order.order.serviceAddress && (<>
                            <Typography variant="subtitle1">
                                Адрес обслуживания
                            </Typography>
                            <Typography variant="body1">
                                {order.order.serviceAddress}
                            </Typography>
                        </>)}
                        <Divider variant="fullWidth" />
                        <TotalPrice label="Итог:" value={order.order.totalPrice} />
                        {order.order.comment && (<>
                            <Divider variant="fullWidth" />
                            <Typography variant="subtitle1">
                                Комментарий
                            </Typography>
                            <Typography variant="body1">
                                {order.order.comment}
                            </Typography>
                            <Divider variant="fullWidth" />
                        </>)}
                        {[7, 11, 12].includes(order.order.status) ? undefined : (
                            <RouterLink
                                to={`/receipt/${orderId}/checkout`}
                                reloadDocument
                            >
                                <Button
                                    variant="contained"
                                    endIcon={<RubleIcon />}
                                >
                                    Оплатить {formatPrice(order.order.totalPrice, false)}
                                </Button>
                            </RouterLink>
                        )}
                    </Stack>
                ) : (
                    <LoadingPaper />
                )}
            </Grid>
        </Grid>
    </>);
};

export default observer(OrderItemView);