import {FC, useContext, useEffect} from "react";
import {
    Unstable_Grid2 as Grid,
    Typography,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {AppContext} from "../../../store";
import Heading from "../HeadingView";
import UnifiedView from "./UnifiedView";
import UploadXLSFloatingButton from "./UploadXLSFloatingButton";
import OrderBar from "./OrderBar";

type Props = {

};


const CartView: FC<Props> = () => {
    const app = useContext(AppContext);

    useEffect(() => {
        app.setTitle('Корзина');
        app.setBreadcrumbs([
            { name: 'Главная', to: '/' },
            { name: 'Корзина' },
        ])
    });

    return (<>
        <Heading/>
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }} sx={{pb: 10}}>
            <Grid xs={12} md={8} lg={9}>
                <UnifiedView />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
                <OrderBar />
            </Grid>
        </Grid>
        <UploadXLSFloatingButton />
    </>);
};

export default observer(CartView);