import { FC } from "react";
import { themed } from "../../theme";
import { Link } from "react-router-dom";
import LogoIcon from "../../icons/logo.svg";
import { Box } from "@mui/material";

export type Props = {
    readonly size?: 'large' | 'normal' | 'small' | 'extra-small';
    readonly disableLink?: boolean;
};

const Logo: FC<Props> = (p) => {

    return (
        <Box to="/" component={p.disableLink ? undefined : Link} sx={{ width: 158, height: 29, justifySelf: 'flex-start' }}>
            <Box component={LogoIcon}
                sx={{
                    width: 158,
                    height: 29,
                    clipPath: p.size === 'extra-small' ? 'inset(0 121px 0 0)' : 'none',
                    position: 'absolute',
                }}
            />
        </Box>
    );
}

export default themed('Logo', Logo);