import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment, OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { AppContext, AuthContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import { APIError } from "../../../store/app";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../../Logo";
import LoginBackground from "./LoginBackground";
import ExtraInfo from "./ExtraInfo";
import TemporalPasswordError from "../../../store/auth/TemporalPasswordError";
import EmailSentView from "./EmailSentView";
import { CanceledError } from "axios";
import EmailCheckingIcon from "@mui/icons-material/HourglassTop";
import EmailErrorIcon from "@mui/icons-material/PriorityHighRounded";
import EmailCorrectIcon from "@mui/icons-material/Done";
import { debounce } from "lodash";
import RegistrationModal, { EnterCodeModal } from "./Registration"
import { themed } from "../../../theme";

type Props = {

};

let checkEMailAbort: AbortController;

const PasswordResetDialog = (p: {
    readonly token: string;
    readonly onAcceptClick: (email: string) => Promise<any>;
    readonly onRejectClick: () => void;
}) => {
    const theme = useTheme();
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [emailState, setEmailState] = useState<'notEntered' | 'checking' | 'incorrect' | 'correct'>('notEntered');

    const checkEmailDebounced = useMemo(() => debounce((email) => {
        checkEMailAbort = new AbortController();
        auth.validateEmail(email, p.token,
            { signal: checkEMailAbort.signal }
        )
            .then(ok => setEmailState(ok ? 'correct' : 'incorrect'))
            .catch((e: APIError | CanceledError<any>) => {
                if (e instanceof CanceledError) return;

                setEmailState('incorrect');
                app.alert('Непредвиденная ошибка', { severity: 'error' });
            });

    }, 2000), [1]);

    useEffect(() => {
        if (checkEMailAbort) {
            checkEMailAbort.abort();
            checkEMailAbort = undefined;
        }
        checkEmailDebounced.cancel();

        if (!email.length) {
            setEmailState('notEntered');
            return;
        }

        setEmailState('checking');
        checkEmailDebounced(email);

    }, [email]);

    return (
        <Box>
            <Typography variant="h6" color="error">
                Внимание!
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Ваш текущий пароль требует сброса.
                К сожалению, вы не сможете пользоваться сайтом, пока не создадите новый пароль.
            </Typography>
            <TextField
                disabled={isLoading}
                label="E-Mail"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value.trim())}
                sx={{ my: 1, width: '100%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {emailState == 'checking' && (<EmailCheckingIcon color="info" />)}
                            {emailState == 'incorrect' && (<EmailErrorIcon color="error" />)}
                            {emailState == 'correct' && (<EmailCorrectIcon color="success" />)}
                        </InputAdornment>
                    ),
                }}
            />
            <Box sx={{ minHeight: 20, textAlign: 'right' }}>
                {emailState !== 'notEntered' ? undefined : <Typography sx={{ color: theme.palette.warning.main }} children="Укажите почту" />}
                {emailState !== 'checking' ? undefined : <Typography sx={{ color: theme.palette.info.main }} children="Проверяем..." />}
                {emailState !== 'incorrect' ? undefined : <Typography sx={{ color: theme.palette.error.main }} children="Проверьте правильность написания почты" />}
                {emailState !== 'correct' ? undefined : <Typography sx={{ color: theme.palette.success.main }} children="Почта указана верно" />}
            </Box>
            <Typography>
                После нажатия на кнопку ниже мы вышлем письмо для сброса пароля на указанную вами почту.
            </Typography>
            <LoadingButton
                disabled={emailState !== 'correct'}
                loading={isLoading}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => {
                    setIsLoading(true);
                    p.onAcceptClick(email)
                        .finally(() => setIsLoading(false));
                }}
                children="Выслать письмо"
            />
            <Button
                disabled={isLoading}
                sx={{ mt: 2 }}
                variant="contained"
                color="error"
                onClick={p.onRejectClick}
                children="Не надо!"
            />
        </Box>
    );
};

const LoginView: FC<Props> = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);
    const [progress, setProgress] = useState(false);

    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [resetEmail, setResetEmail] = useState(null as string | null);


    const isLoggedIn = auth.isLoggedIn;


    const [registerOpen, setRegisterOpen] = useState(false);
    const [enterCodeOpen, setEnterCodeOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const isSmallMobile = useMediaQuery('(max-width: 460px)', { noSsr: true });

    const handleClickOpen = () => {
        setRegisterOpen(true);
    };
    const handleClose = (value: string) => {
        if(value === 'success') {
            setEnterCodeOpen(true);
        } 
        setRegisterOpen(false);
    };


    useEffect(() => {
        app.setTitle('Вход');
    });

    useEffect(() => {
        if (!isLoggedIn) return;
        navigate('/');

    }, [isLoggedIn]);

    if (resetEmail)
        return <EmailSentView email={resetEmail} />;

    return (
        <LoginBackground>
            <Container fixed>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <Logo size="large" disableLink />
                    <Typography fontSize={isSmallMobile ? "2rem" : "3rem"} fontWeight="bold" sx={{ mt: isSmallMobile ? 2 : 5, textAlign: "center" }}>Интернет-магазин</Typography>
                    <Typography fontSize="2.25rem" sx={{ mb: isSmallMobile ? 1 : 5, mt: 3 }}>B2B – портал</Typography>
                    <Paper sx={{ p: 2, m: 2, width: 257 }} elevation={1}>
                        <Stack
                            component="form"
                            aria-autocomplete="both"
                            spacing={2}
                            onSubmit={async e => {
                                e.preventDefault();
                                setProgress(true);
                                auth.logIn(login, password)
                                    .catch((e: APIError | TemporalPasswordError) => {
                                        if (e instanceof TemporalPasswordError) {
                                            app.showDialog(
                                                <PasswordResetDialog
                                                    token={e.confirmationToken}
                                                    onAcceptClick={(email) => {
                                                        return auth.sendPasswordResetEmail(email, e.confirmationToken)
                                                            .then(() => {
                                                                app.closeDialog();
                                                                setResetEmail(email);
                                                            });
                                                    }}
                                                    onRejectClick={() => app.closeDialog()}
                                                />
                                            );
                                            return;
                                        }

                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                    .finally(() => setProgress(false));
                            }}
                        >
                            <h2>Вход</h2>
                            <TextField autoComplete="username" placeholder="Логин" value={login} onChange={e => setLogin(e.target.value)} required />
                            <OutlinedInput
                                autoComplete="current-password"
                                placeholder="Пароль"
                                type={isPasswordShown ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsPasswordShown(!isPasswordShown)}
                                            edge="end"
                                        >
                                            {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                            />
                            <Stack gap={1}>
                                <LoadingButton
                                    children="Войти"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ fontWeight: "bold", fontSize: "0.88rem" }}
                                    loading={progress}
                                />
                                <Button
                                    children="Зарегистрироваться"
                                    color="secondary"
                                    variant="outlined"
                                    size="small"
                                    sx={{ fontWeight: "bold", borderColor: "#3f8cff", py: "6px" }}
                                    onClick={handleClickOpen}
                                />
                            </Stack>
                            <RegistrationModal
                                selectedValue={selectedValue}
                                open={registerOpen}
                                onClose={handleClose}
                            />
                            <EnterCodeModal
                                selectedValue={selectedValue}
                                open={enterCodeOpen}
                                onClose={() => {
                                    setEnterCodeOpen(false);
                                }}
                            />
                        </Stack>
                        <ExtraInfo variant="login" />
                        <Typography
                            component={Link}
                            to="https://avtomasla.su/"
                            sx={{ display: "flex", justifyContent: "center", color: "red", fontWeight: "bold", pt: 1 }}
                        >
                            Вернуться на главную страницу
                        </Typography>
                    </Paper>
                </Box>
            </Container>
        </LoginBackground>
    );
};
export default themed('LoginView', observer(LoginView));