import React, {useContext, useMemo, useRef, useState} from "react";
import {FC} from "react";
import dayjs from "dayjs";
import {Box, Button, Link, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {AppContext, UserContext} from "../../store";

type Props = {

};

let _isSending = false;

const ReconciliationRangePicker: FC<Props> = () => {
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const fileRef = useRef<HTMLInputElement>();

    const [isSending, setIsSending] = useState(_isSending);

    const [value, setValue] = useState(() => {
        let start = dayjs().startOf('month');

        const diff = (start.month() + 3) % 3;
        if(diff === 0)
            start = start.subtract(3, 'months').startOf('month');
        else
            start = start.subtract(diff, 'months');

        return { start, end: dayjs() };
    });

    const qval = useMemo(() => ({
        start: value.start?.format('YYYYMMDD') || '',
        end: value.end?.format('YYYYMMDD') || '',
    }), [ value ]);

    const sendTooltip = `Здесь можно приложить заполненный скан или фото акта сверки.`;

    return (<Stack sx={{ p: 1 }} spacing={1}>
        <Stack component="form" direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="subtitle1">Выберите период</Typography>
                <Box
                    ref={fileRef}
                    component="input"
                    type="file"
                    name="file"
                    sx={{ position: 'absolute', top: -99999 }}
                    onChange={e => {
                        const file: File = e.target.files[0];
                        if(!file) return;
                        e.target.value = null;

                        (() => {
                            _isSending = true;
                            setIsSending(_isSending);
                            app.alert('Идёт отправка...', { time: null });
                            user.sendReconciliation(file)
                                .catch(() => app.alert('Ошибка отправки файла', { severity: 'error' }))
                                .then(() => app.alert('Отправка успешно завершена', { severity: 'success' }))
                                .finally(() => (_isSending = false, setIsSending(_isSending)))
                            ;
                        })();

                    }}
                />
                <Tooltip title={sendTooltip} placement="top">
                    <Box sx={{ml: 1, width: 73}}>
                        {!isSending && (
                            <Link
                                component="button"
                                onClick={e => {
                                    e.preventDefault();
                                    fileRef.current.click();
                                }}
                            >
                                Отправить
                            </Link>
                        )}
                        {isSending && (
                            <Typography variant="body1" >Отправка...</Typography>
                        )}
                    </Box>
                </Tooltip>
        </Stack>
        <DatePicker
            maxDate={value.end?.subtract(1, 'day')}
            label="Начало"
            value={value.start}
            onChange={v => setValue({...value, start: v?.isValid() ? v : undefined})}
            renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
            minDate={value.start?.add(1, 'day')}
            label="Конец"
            value={value.end}
            onChange={v => setValue({...value, end: v?.isValid() ? v : undefined})}
            renderInput={(params) => <TextField {...params} />}
        />
        <Button
            disabled={!value.start || !value.end}
            variant="contained"
            color="secondary"
            href={`/reconciliation?start=${qval.start}&end=${qval.end}`}
            target="_blank"
        >Получить</Button>
    </Stack>);
}

export default ReconciliationRangePicker;