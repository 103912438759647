

class TemporalPasswordError extends Error {
    constructor(
        public readonly confirmationToken: string,
    ) {
        super();
    }
}

export default TemporalPasswordError;