import { Box, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { FC, useContext } from "react";
import { UserContext } from "../../store";
import { formatPrice as $ } from "../../utils";
import { observer } from "mobx-react-lite";

type Props = {
};

const DebtInfo: FC<Props> = () => {
    const theme = useTheme();
    const user = useContext(UserContext);

    return (<Stack spacing={1}>
        {user.debtInfo && (<>
            <Box>
                <Typography fontSize="10px" fontWeight="600" color="#8F95B2" component="div">
                    Дебиторская задолженность:
                </Typography>
                <Typography component="div" variant="body1" color={theme.palette.text.secondary}>
                    (на {user.debtInfo.updatedAt})
                </Typography>
                <Typography fontSize="14px" fontWeight="bold" component="span" color={theme.palette.warning.main}>
                    {$(user.debtInfo.debt)}
                </Typography>{" "}
            </Box>
            {user.debtInfo.overdebt ? (<>
                <Box>
                    <Typography fontSize="10px" fontWeight="600" color="#8F95B2" component="div">
                        В т.ч. просроченная:
                    </Typography>
                    <Typography component="div" fontSize="14px" fontWeight="bold" color={theme.palette.warning.main}>
                        {$(user.debtInfo.overdebt)}
                    </Typography>
                </Box>
            </>) : (
                user.debtInfo.paydate && (<>
                    <Box>
                        <Typography fontSize="10px" fontWeight="600" color="#8F95B2" component="div">
                            Следующая оплата до:
                        </Typography>
                        <Typography component="div" fontSize="14px" fontWeight="bold" color="#8F95B2">
                            {user.debtInfo.paydate}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography fontSize="10px" fontWeight="600" color="#8F95B2" component="div">
                            Сумма оплаты:
                        </Typography>
                        <Typography component="div" fontSize="14px" fontWeight="bold" color="#8F95B2">
                            {$(user.debtInfo.paysum)}
                        </Typography>
                    </Box>
                </>)
            )}
        </>)}
    </Stack>);
};

export default observer(DebtInfo);