import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import image from "../../../images/retail.jpg"
import { GarageContext } from "../../../store";
import { LoadingButton } from "@mui/lab";

type Props = {

};

const services = [
    'Замена масла в АКПП',
    'Замена охлаждающей жидкости',
    'Замена тормозной жидкости',
    'Замена масла в ГУР',
    'Раскоксовка колец поршневой группы',
    'Заправка климатической системы',
];

const RecordDialog: FC<Props> = () => {
    const theme = useTheme();
    const garage = useContext(GarageContext);
    const [isLoading, setIsLoading] = useState(false);

    const items = garage.items;


    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value: string) => {
        setOpen(false);
    };
    const [car, setCar] = useState(undefined);
    const handleChange = (event: SelectChangeEvent) => {
        setCar(event.target.value);
    };

    const [selectedService, setSelectedService] = useState('');

    const handleChangeSelect = (event: SelectChangeEvent) => {
        setSelectedService(event.target.value as string);
    };
    return (<>
        <Button
            variant="outlined"
            sx={{
                fontWeight: "bold",
                border: "1px solid",
                width: "135px"
            }}
            onClick={handleClickOpen}
        >
            Запись в сервис
        </Button>
        <Dialog onClose={handleClose} open={open} maxWidth={false} sx={{ '& .MuiPaper-root': { borderRadius: "1rem" } }}
        >

            <Box sx={{ display: "flex", gap: 2, padding: 2, flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                <Box component="img" src={image} sx={{ maxWidth: 400, maxHeight: 300, borderRadius: "1rem", width: "100%" }} />
                <Stack
                    component="form"
                    sx={{ gap: 2, width: { xs: "100%", md: 300 } }}
                    onSubmit={e => {
                        e.preventDefault();
                        setIsLoading(true);
                        garage.bookService(e.currentTarget)
                            .finally(() => {
                                setIsLoading(false);
                            })
                            .then(() => {
                                setOpen(false);
                            })
                            ;
                    }}
                >
                    <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>Запись авто в сервис</Typography>
                    {items?.list.length ? <FormControl fullWidth variant="outlined">
                        <InputLabel>Авто</InputLabel>
                        <Select required
                            name="stateCode"
                            value={car ? `${car}` : undefined}
                            onChange={handleChange}
                        >
                            {items?.list.map(v => (
                                <MenuItem value={v.stateCode}>{v.name}</MenuItem>
                            ))}
                            <MenuItem value="otherCar">Другой авто</MenuItem>
                        </Select>
                    </FormControl> : <TextField placeholder="Номер вашего ТС" name="stateCode" />}
                    <FormControl>
                        <InputLabel htmlFor="select">Услуги</InputLabel>
                        <Select required
                            name="service"
                            value={selectedService}
                            label="Услуги"
                            onChange={handleChangeSelect}
                        >
                            {services.map(v => (
                                <MenuItem key={v} value={v}>{v}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <DatePicker
                        label="Выберите дату записи"
                        value={value}
                        onChange={setValue}
                        renderInput={(params) => <TextField required name="date" {...params} />}
                    /> */}
                    <TextField name="comment" rows={4} multiline label="Комментарий" variant="outlined" />
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        sx={{
                            backgroundColor: theme.palette.info.contrastText,
                            color: "white",
                            "&: hover": { backgroundColor: "#6C5DD3" }
                        }}
                        children="Заказать звонок"
                    />
                </Stack>

            </Box>
        </Dialog >
    </>);
};

export default observer(RecordDialog);