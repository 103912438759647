import {FC, useContext} from "react";
import * as React from "react";
import WideCard from "../WideCard";
import { Item } from "../../../common/item";
import {AppContext, CartContext, UserContext} from "../../../store";
import WideCardStepperDoubleAction from "../WideCardStepperDoubleAction";
import ItemCardModal from "../../ItemCardModal";

type Props = {
    readonly item: Item;
    readonly inCart: boolean;
    readonly count: number;
    readonly onCountChange: (count: number) => void;
    readonly onAddToCartClick: () => void;
    readonly onRemoveFromCartClick: () => void;
    readonly onFavoritesClick: () => void;
    readonly favorite: boolean;
}

const WaitListWideCard: FC<Props> = (p) => {
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const cart = useContext(CartContext);

    return (
        <WideCard
            imgSrc={p.item.imgList}
            label={p.item.label}
            onFavoritesClick={p.onFavoritesClick}
            favorite={p.favorite}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            defaultStock={p.item.stockDefaultCount}
            defaultStockDate={p.item.stockDefaultDate}
            inCartCount={cart.itemsCount(user.user.id, p.item.id)}
            count={p.count}
            onClick={() => {
                app.showDialog(<ItemCardModal item={p.item} />)
            }}
            action={
                <WideCardStepperDoubleAction
                    count={p.count}
                    onCountChange={p.onCountChange}
                    price={p.item.price}
                    stock={p.item.count}
                    inCart={p.inCart}
                    onRemoveFromCartClick={p.onRemoveFromCartClick}
                    onAddToCartClick={p.onAddToCartClick}
                />
            }
        />
    );
}

export default WaitListWideCard;