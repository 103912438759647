import { FC } from "react";
import { Dayjs } from "dayjs";
import { BoxProps, Stack, Typography } from "@mui/material";

type Props = BoxProps & {
    readonly date: Dayjs;
    readonly variant?: 'date' | 'dateTime'
};

const Date: FC<Props> = ({ variant = "dateTime", date, component = 'span', ...props }) => {

    return (<>
        {variant === "dateTime" &&
            <Stack component={component} direction={{ xs: 'row', lg: 'column' }} alignItems="center" {...props}>
                <Typography component="span" variant="body1">
                    {date.format('DD.MM.YYYY')}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        ml: { xs: 1, md: undefined }
                    }}
                >
                    {date.format('HH:mm')}
                </Typography>
            </Stack>
        }
        {variant === "date" && <Typography component="span" variant="body1"> {date.format('DD.MM.YYYY')}</Typography>}

    </>);
}

export default Date;