import { FC } from "react";
import LoginBackground from "../../LoginView/LoginBackground";
import { Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import Logo from "../../../Logo";
import { observer } from "mobx-react-lite";

type Props = {};

const EmailSentView: FC<Props> = (p) => {

    return (
        <LoginBackground>
            <Container fixed>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                    <Logo size="large" disableLink />
                    <Paper component={Stack} sx={{ p: 2, mt: 2, width: 257 }} spacing={2} elevation={1}>
                        <Typography variant="body1">Пароль успешно сброшен! Теперь можно воспользоваться им для входа в аккаунт.</Typography>

                        <Button
                            children="Войти"
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => location.href = '/'}
                        />
                    </Paper>
                </Box>
            </Container>
        </LoginBackground>
    );
}

export default observer(EmailSentView);