import {FC} from "react";
import {Order} from "../index";
import TableRow from "../ListTable/TableBody/TableRow";

type Props = {
    readonly item: Order;
}

const OrdersTableRow: FC<Props> = (p) => {
    
    let comment = '';
    if(p.item.stateCode)
        comment += p.item.stateCode + (p.item.comment ? ': ' : '');
    if(p.item.comment)
        comment += p.item.comment;

    return (
        <TableRow
            id={p.item.id}
            humanId={p.item.humanId}
            date={p.item.date}
            payUntil={p.item.payUntil}
            price={p.item.totalPrice}
            priceBeforeDiscount={p.item.totalPriceBeforeDiscount}
            status={p.item.status}
            comment={comment}
            bonus={p.item.bonus}
            recipient={p.item.recipient}
        />
    );
}

export default OrdersTableRow;