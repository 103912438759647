import { FC } from "react";
import { BonusTransaction } from "../Imports";
import TableRowRetail from "../TableRow";

type Props = {
    readonly item: BonusTransaction;
}

const OrdersTableRowBonusRetail: FC<Props> = (p) => {

    return (
        <TableRowRetail
            id={p.item.id}
            type={p.item.type}
            orderId={p.item.orderId}
            date={p.item.date}
            message={p.item.message}
            delta={p.item.delta}
            deactivated={p.item.deactivated}
        />
    );
}

export default OrdersTableRowBonusRetail;