import {observer} from "mobx-react-lite";
import {Stack} from "@mui/material";
import {useContext, useMemo} from "react";
import {AppContext, CartContext, FavoritesContext, UserContext} from "../../../../store";
import PromoPageWideCard from "../../../Item/PromoPageWideCard";
import { Item } from "../../../../common/item";

type Props = {
    readonly items: ReadonlyArray<Readonly<Item>>;
    readonly onItemCountChange: (item: Readonly<Item>, count: number) => void;
    readonly onItemClick?: (item: Readonly<Item>) => void;
    readonly targetPrices?: {id: string, price: number}[];
}

const ItemsView = (p: Props) => {
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);

    const newPrices = useMemo(() => {
        if(!p.targetPrices) return {};

        const res: Record<number, number> = {};
        for(const v of p.targetPrices)
            res[v.id] = v.price;

        return res;
    }, [ p.targetPrices ]);


    return (
        <Stack spacing={2}>
            {p.items.map(item => {

                const favorite = favorites.includes(user.user?.id, item.id);
                const inCart = cart.includes(user.user?.id, item.id);

                let innerItem = item;
                if(newPrices[item.id]) {
                    if(Math.abs(newPrices[item.id] - item.price) >= 0.01) {
                        innerItem = {
                            ...item, oldPrice: item.price, price: newPrices[item.id],
                        };
                    }
                }

                return ( // TODO: List на момент коммита тут не годится
                    <PromoPageWideCard
                        key={`${item.id}_${item.price?.toFixed(2) || 0}`}
                        item={innerItem}
                        inCart={inCart}
                        favorite={favorite}
                        count={item.quantity}
                        onClick={() => {
                            p.onItemClick && p.onItemClick(item);
                        }}
                        onCountChange={c => p.onItemCountChange(item, c)}
                        onFavoritesClick={async () => {
                            if(favorite) {
                                await favorites.remove(item, user.user?.id);
                                app.alert(`Удалено из избранного`);
                            }
                            else {
                                await favorites.add(item, user.user?.id);
                                app.alert(`Добавлено в избранное`);
                            }

                        }}
                        onAddToCartClick={async () => {
                            await cart.addWithAmount(item, item.quantity || 1, user.user?.id);
                            app.alert(`Добавлено в корзину`);
                        }}
                    />
                );
            })}
        </Stack>
    );
}

export default observer(ItemsView);