import { FC } from "react";
import { Box, Divider, Stack } from "@mui/material";
import Price from "../Price";
import * as React from "react";
import LineThrough from "../../LineThrough";

type Props = {
    readonly count: number;
    readonly price: number;
    readonly oldPrice?: number;
}

const BatchItemsPrice: FC<Props> = ({ count, price, oldPrice }) => {

    return (
        <Stack
            justifyContent="center"
            divider={<Divider sx={{ my: '6px' }} />}
            sx={{ height: 66, overflow: 'hidden', mt: 1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {!!oldPrice && (
                    <LineThrough sx={{ mr: 1 }}>
                        <Price value={oldPrice} compact />
                    </LineThrough>
                )}
                <Price
                    compact={count > 1}
                    value={price}
                />
            </Box>
            {count > 1 ? (
                <Price value={price * count} />
            ) : undefined}
        </Stack>
    );
}

export default BatchItemsPrice;