import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment, OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { AppContext, AuthContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import { APIError } from "../../../store/app";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ExtraInfo from "./ExtraInfo";
import TemporalPasswordError from "../../../store/auth/TemporalPasswordError";
import { CanceledError } from "axios";
import EmailCheckingIcon from "@mui/icons-material/HourglassTop";
import EmailErrorIcon from "@mui/icons-material/PriorityHighRounded";
import EmailCorrectIcon from "@mui/icons-material/Done";
import { debounce } from "lodash";
import RegistrationModal, { EnterCodeModal as RegisterEnterCodeModal } from "./Registration";
import ResetPasswordModal, { EnterCodeModal as ResetEnterCodeModal } from "./ResetPassword";
import BgImg from "../../../images/retailLoginBg.png"
import Logo from "../../../components/Logo";
import { MuiTelInput } from "mui-tel-input";
import ResetPassword from "./ResetPassword";

type Props = {

};

let checkEMailAbort: AbortController;

const PasswordResetDialog = (p: {
    readonly token: string;
    readonly onAcceptClick: (email: string) => Promise<any>;
    readonly onRejectClick: () => void;
}) => {
    const theme = useTheme();
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [emailState, setEmailState] = useState<'notEntered' | 'checking' | 'incorrect' | 'correct'>('notEntered');

    const checkEmailDebounced = useMemo(() => debounce((email) => {
        checkEMailAbort = new AbortController();
        auth.validateEmail(email, p.token,
            { signal: checkEMailAbort.signal }
        )
            .then(ok => setEmailState(ok ? 'correct' : 'incorrect'))
            .catch((e: APIError | CanceledError<any>) => {
                if (e instanceof CanceledError) return;

                setEmailState('incorrect');
                app.alert('Непредвиденная ошибка', { severity: 'error' });
            });

    }, 2000), [1]);

    useEffect(() => {
        if (checkEMailAbort) {
            checkEMailAbort.abort();
            checkEMailAbort = undefined;
        }
        checkEmailDebounced.cancel();

        if (!email.length) {
            setEmailState('notEntered');
            return;
        }

        setEmailState('checking');
        checkEmailDebounced(email);

    }, [email]);

    return (
        <Box>
            <Typography variant="h6" color="error">
                Внимание!
            </Typography>
            <Typography sx={{ mt: 1 }}>
                Ваш текущий пароль требует сброса.
                К сожалению, вы не сможете пользоваться сайтом, пока не создадите новый пароль.
            </Typography>
            <TextField
                disabled={isLoading}
                label="E-Mail"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value.trim())}
                sx={{ my: 1, width: '100%' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {emailState == 'checking' && (<EmailCheckingIcon color="info" />)}
                            {emailState == 'incorrect' && (<EmailErrorIcon color="error" />)}
                            {emailState == 'correct' && (<EmailCorrectIcon color="success" />)}
                        </InputAdornment>
                    ),
                }}
            />
            <Box sx={{ minHeight: 20, textAlign: 'right' }}>
                {emailState !== 'notEntered' ? undefined : <Typography sx={{ color: theme.palette.warning.main }} children="Укажите почту" />}
                {emailState !== 'checking' ? undefined : <Typography sx={{ color: theme.palette.info.main }} children="Проверяем..." />}
                {emailState !== 'incorrect' ? undefined : <Typography sx={{ color: theme.palette.error.main }} children="Проверьте правильность написания почты" />}
                {emailState !== 'correct' ? undefined : <Typography sx={{ color: theme.palette.success.main }} children="Почта указана верно" />}
            </Box>
            <Typography>
                После нажатия на кнопку ниже мы вышлем письмо для сброса пароля на указанную вами почту.
            </Typography>
            <LoadingButton
                disabled={emailState !== 'correct'}
                loading={isLoading}
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => {
                    setIsLoading(true);
                    p.onAcceptClick(email)
                        .finally(() => setIsLoading(false));
                }}
                children="Выслать письмо"
            />
            <Button
                disabled={isLoading}
                sx={{ mt: 2 }}
                variant="contained"
                color="error"
                onClick={p.onRejectClick}
                children="Не надо!"
            />
        </Box>
    );
};

const LoginViewRetail: FC<Props> = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const app = useContext(AppContext);
    const auth = useContext(AuthContext);
    const [progress, setProgress] = useState(false);

    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [resetEmail, setResetEmail] = useState(null as string | null);


    const isLoggedIn = auth.isLoggedIn;


    const [registerOpen, setRegisterOpen] = useState(false);
    const [registerEnterCodeOpen, setRegisterEnterCodeOpen] = useState(false);
    const [resetOpen, setResetOpen] = useState(false);
    const [resetEnterCodeOpen, setResetEnterCodeOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const isSmallMobile = useMediaQuery('(max-width: 460px)', { noSsr: true });
    const isLaptop = useMediaQuery('(max-width: 1100px)', { noSsr: true });

    const handleClickOpen = () => {
        setRegisterOpen(true);
    };

    const handleChange = (value) => {
        if (value.length > 12) {
            value = value.slice(0, 12);
            value.value = value;
        }

        if (!value || value.trim() === '') {
            setLogin('+7');
        } else {
            setLogin(value);
        }
    }

    useEffect(() => {
        app.setTitle('Вход');
    });

    useEffect(() => {
        if (!isLoggedIn) return;
        navigate('/');

    }, [isLoggedIn]);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: isLaptop ? "column" : "row" }}>
            <Box component="img" sx={{ width: isLaptop ? "100%" : "50%", height: isLaptop ? "200px" : "100vh", backgroundSize: "cover", objectFit: "cover" }} src={BgImg}></Box>
            <Container fixed sx={{ width: "50%" }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isLaptop ? "start" : "center",
                        alignItems: 'center',
                        height: '100vh',
                        pt: isLaptop && "46px"
                    }}
                >
                    <Logo size="large" disableLink />
                    <Typography
                        fontSize={40}
                        lineHeight="45px"
                        fontWeight="bold"
                        sx={{ mt: isSmallMobile ? 2 : 5, textAlign: "center", maxWidth: "426px" }}
                    >
                        Электронная сервисная книжка
                    </Typography>
                    <Paper sx={{ p: 2, m: 2, width: "386px" }} elevation={1}>
                        <Stack
                            component="form"
                            aria-autocomplete="both"
                            spacing={2}
                            onSubmit={async e => {
                                e.preventDefault();
                                setProgress(true);
                                auth.logIn(login, password)
                                    .catch((e: APIError | TemporalPasswordError) => {
                                        if (e instanceof TemporalPasswordError) {
                                            app.showDialog(
                                                <PasswordResetDialog
                                                    token={e.confirmationToken}
                                                    onAcceptClick={(email) => {
                                                        return auth.sendPasswordResetEmail(email, e.confirmationToken)
                                                            .then(() => {
                                                                app.closeDialog();
                                                                setResetEmail(email);
                                                            });
                                                    }}
                                                    onRejectClick={() => app.closeDialog()}
                                                />
                                            );
                                            return;
                                        }

                                        app.alert(e.response.data.meta.message, { severity: 'error' });
                                    })
                                    .finally(() => setProgress(false));
                            }}
                        >
                            <h2>Вход</h2>
                            <MuiTelInput
                                name="phone"
                                defaultCountry="RU"
                                label="Телефон"
                                placeholder='+7XXXXXXXXXX'
                                required
                                disableFormatting
                                value={login}
                                onChange={handleChange}
                                sx={{ "& .MuiIconButton-root": { display: "none" } }}
                            />
                            <Box sx={{ width: "100%" }}>
                                <OutlinedInput
                                    autoComplete="current-password"
                                    placeholder="Пароль"
                                    type={isPasswordShown ? 'text' : 'password'}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setIsPasswordShown(!isPasswordShown)}
                                                edge="end"
                                            >
                                                {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required
                                    sx={{ width: "100%" }}
                                />
                                <Button variant="text" color="secondary" onClick={() => setResetOpen(true)}>
                                    Забыли пароль?
                                </Button>
                            </Box>

                            <Stack gap={1}>
                                <LoadingButton
                                    children="Войти"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ fontWeight: "bold", borderColor: "#3f8cff", py: "6px" }}
                                    loading={progress}
                                />
                                <Button
                                    children="Зарегистрироваться"
                                    color="secondary"
                                    variant="outlined"
                                    size="small"
                                    sx={{ fontWeight: "bold", borderColor: "#3f8cff", py: "6px" }}
                                    onClick={handleClickOpen}
                                />
                            </Stack>
                            <RegistrationModal
                                selectedValue={selectedValue}
                                open={registerOpen}
                                onClose={(value: string) => {
                                    if (value === 'success') {
                                        setRegisterEnterCodeOpen(true);
                                    }
                                    setRegisterOpen(false);
                                }}
                            />
                            <RegisterEnterCodeModal
                                selectedValue={selectedValue}
                                open={registerEnterCodeOpen}
                                onClose={() => {
                                    setRegisterEnterCodeOpen(false);
                                }}
                            />
                            <ResetPasswordModal
                                selectedValue={selectedValue}
                                open={resetOpen}
                                onClose={(value: string) => {
                                    if (value === 'success') {
                                        setResetEnterCodeOpen(true);
                                    }
                                    setResetOpen(false);
                                }}
                            />
                            <ResetEnterCodeModal
                                selectedValue={selectedValue}
                                open={resetEnterCodeOpen}
                                onClose={() => {
                                    setResetEnterCodeOpen(false);
                                }}
                            />
                        </Stack>
                        <ExtraInfo variant="login" />
                        <Typography
                            component={Link}
                            to="https://avtomasla.su/"
                            sx={{ display: "flex", justifyContent: "center", color: "red", fontWeight: "bold", pt: 1 }}
                        >
                            Вернуться на главную страницу
                        </Typography>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};
export default observer(LoginViewRetail)