import {FC} from "react";
import {Order} from "../index";
import TableRow from "../ListTable/TableBody/TableRow";

type Props = {
    readonly item: Order;
}

const OrdersTableRow: FC<Props> = (p) => {

    return (
        <TableRow
            id={p.item.id}
            humanId={p.item.humanId}
            date={p.item.date}
            payUntil={p.item.payUntil}
            price={p.item.totalPrice}
            priceBeforeDiscount={p.item.totalPriceBeforeDiscount}
            status={p.item.status}
            comment={p.item.comment}
            bonus={p.item.bonus}
            recipient={p.item.recipient}
            vehicleName={p.item.vehicleName}
            vehicleMileage={p.item.vehicleMileage}
            vehicleStateCode={p.item.vehicleStateCode}
        />
    );
}

export default OrdersTableRow;