import {FC, useState} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    Grid, Stack,
    TextField, Typography
} from "@mui/material";
import * as React from "react";
import image from "../../images/callme.png";
import Stepper from "../Stepper";
import {LoadingButton} from "@mui/lab";

export type Data = {
    readonly name: string;
    readonly phone: string;
    readonly from: number;
    readonly to: number;
};

type Props = DialogProps & {
    readonly onDataSend: (data: Data) => void;
    readonly isLoading?: boolean;
};

const CallMeDialog: FC<Props> = ({ onDataSend, isLoading, ...props }) => {
    let [ name, setName ] = useState('');
    let [ phone, setPhone ] = useState('');
    const [ from, setFrom ] = useState(10);
    const [ to, setTo ] = useState(17);

    return (
        <Dialog {...props}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm>
                        <Box
                            component="img"
                            src={image as any}
                            sx={{
                                borderRadius: '8px',
                                width: { xs: '100%', sm: 234 },
                                height: { xs: '40vw', sm: 'auto' },
                                objectFit: { xs: 'cover', sm: 'contain' },
                                objectPosition: 'center',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm>
                        <Stack onSubmit={e => {
                            e.preventDefault();
                            name = name.trim();
                            phone = phone.trim();
                            if(!name.length || !phone.length) return;

                            onDataSend({ name, phone, from, to });

                        }} component="form" spacing={2}>
                            <Typography variant="h5" >
                                Заказать звонок
                            </Typography>
                            <Stack spacing={1}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    placeholder="ФИО"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    placeholder="Номер телефона"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </Stack>
                            <Typography variant="subtitle1">
                                В какое время позвонить:
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1">
                                    с
                                </Typography>
                                <Stepper value={from} min={1} max={24} onChange={setFrom} />
                                <Typography variant="body1">
                                    до
                                </Typography>
                                <Stepper value={to} min={1} max={24} onChange={setTo} />
                            </Box>
                            <LoadingButton loading={isLoading} type="submit" sx={{ width: '100%' }} variant="contained" color="secondary">Позвоните мне</LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CallMeDialog;