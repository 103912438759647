import { SxProps, styled, tableCellClasses } from "@mui/material";
import {TableCell as MuiTableCell} from "@mui/material";
import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
    readonly sx?: SxProps,
}

const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: 8,
        maxWidth: 300
    },
}));

const TableCellRetail: FC<Props> = ({sx, children}) => {

    return (
        <StyledTableCell 
            component="th" 
            scope="row" 
            align="center"
            sx={sx}
        >
            {children}
        </StyledTableCell>
    );
}

export default TableCellRetail;