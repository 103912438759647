import {Checkbox, ListItem, ListItemButton, ListItemIcon} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import * as React from "react";
import {BaseProp} from "../";
import {FC} from "react";

type CheckboxValue = {
    id: number;
    value: string;
    num: number;
    checked: boolean;
};

interface ItemClickEvent extends React.MouseEvent {
    itemId: number;
}

export type CheckboxProp = BaseProp & {
    type: 'checkbox';
    values?: CheckboxValue[];
};

type Props = {
    readonly prop: CheckboxProp;
    readonly onItemClick?: (e: ItemClickEvent) => void;
};

const CheckBox: FC<Props> = (props) => {
    const {values} = props.prop;

    return (
        <List dense component="div" disablePadding>
            {values.map(({id: vid, value, num, checked}) => (
                <ListItem dense key={vid} disablePadding>
                    <ListItemButton
                        dense
                        disableRipple
                        onClick={_e => {
                            if (!props.onItemClick) return;

                            const e: ItemClickEvent = _e as any;
                            e.itemId = vid;
                            props.onItemClick(e);
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={!!checked}
                                sx={{
                                    '& .MuiSvgIcon-root': {fontSize: 20},
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={`${value}${num ? ` (${num})` : ''}`}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default CheckBox;