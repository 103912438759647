import {FC, useContext} from "react";
import WideCard from "../WideCard";
import { Item } from "../../../common/item";
import WideCardStepperAction from "../WideCardStepperAction";
import {AppContext, BonusContext, CartContext, UserContext} from "../../../store";
import ItemCardModal from "../../ItemCardModal";
import { observer } from "mobx-react-lite";

type Props = {
    readonly item: Item;
    readonly inCart: boolean;
    readonly favorite: boolean;
    readonly count: number;
    readonly onAddToCartClick: () => void;
    readonly onFavoritesClick: () => void;
    readonly onCountChange?: (value: number) => void;
}

const RecommendedWideCard: FC<Props> = (p) => {
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const cart = useContext(CartContext);

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.count || 1,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    return (
        <WideCard
            imgSrc={p.item.imgList}
            label={p.item.label}
            favorite={p.favorite}
            onFavoritesClick={p.onFavoritesClick}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            defaultStock={p.item.stockDefaultCount}
            defaultStockDate={p.item.stockDefaultDate}
            inCartCount={cart.itemsCount(user.user.id, p.item.id)}
            count={p.count}
            onClick={() => {
                app.showDialog(<ItemCardModal item={p.item} />)
            }}
            action={
                <WideCardStepperAction
                    count={p.count}
                    price={p.item.price}
                    onCountChange={p.onCountChange}
                    stock={p.item.count}
                    inCart={p.inCart}
                    onAddToCartClick={p.onAddToCartClick}
                    outOfStock={p.item.count <= 0}
                    bonusValue={bonusValue}
                />
            }
        />
    );
}

export default observer(RecommendedWideCard);