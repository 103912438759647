import {createContext} from "react";
import {action, computed, makeObservable, observable, override, toJS} from "mobx";
import {Store, merge} from "./index";
import axios, {AxiosResponse} from "axios";
import { Item as CatalogItem } from "../common/item";
import dayjs, {Dayjs} from "dayjs";
import {APIResponseData} from "./app";
import {request} from "../utils";
import {Bucket} from "./base/bucket";
import {pass, Context as PromoContext} from "../../ts-shared/promo";
import {indexBy} from "../../ts-shared/utils";
import { calculateOrderBonusInfo, Item as BonusItem } from "../../ts-shared/bonus";
import { User as PredicateUser } from "../../ts-shared/predicates";

type Item = CatalogItem & {

}

export type Group = {
    promoId: number,
    name: string,
    items: Item[],
    count: number,
};

export type Address = {
    readonly id: number;
    readonly name?: string;
    readonly address: string;
    readonly schedule?: { id: string, date: Dayjs }[];
}

export type State = {
    items?: {
        list: Item[];
    }
    readonly info?: {
        count: number;
    }
    readonly addresses?: {
        readonly list: Address[];
    };
};

export class Cart extends Bucket<State, 'cart'> {

    _uncheckedItems: Record<string, number> = {};

    constructor(store: Store) {
        super(store, {
           endpointUrl: '/cart',
           tableName: 'cart',
        });

        store.on('order_created', async (userId: number) => {
            this.removeManyWithAmount(this.checkedItems.map(v => ({ item: v, amount: v.quantity })), userId);
            this.checkAll();
        })

        makeObservable(this, {
            setItemAmount: action,
            addresses: computed,
            _itemsWithPromoPass: computed,
            promoItems: computed,
            promoGroups: computed,
            summary: override,
            checkedSummary: computed,
            _uncheckedItems: observable,
            checkItem: action,
            checkFullItem: action,
            checkItems: action,
            checkedItemsById: computed,
            checkAll: action,
            uncheckAll: action,
            isAllChecked: computed,
            isAllUnchecked: computed,
            checkedItems: computed,
            checkedPromoGroups: computed,
            checkedPromoItems: computed,
            bonusSummary: computed,
        });
    }
    
    override setState(state: State | undefined): void {
        if(state?.addresses) {
            const addresses = state.addresses.list;
            for(const addr of addresses) {
                for(const sch of addr.schedule) {
                    sch.date = dayjs(sch.date);
                }
            }
        }
        
        this._state = merge(this._state, state || {});
    }

    public async uploadXLS(file: File, userId: number) {
        const fd = new FormData;
        fd.append('file', file);

        const res: AxiosResponse<APIResponseData<{
            readonly items: { item: Item, amount: number }[];
            readonly updatedAt: string;
        }>> = await axios.post(`${this.config.endpointUrl}/excel`, fd);

        const { items } = res.data.data;
        const updatedAt = dayjs(res.data.data.updatedAt);

        await this.getBucket(userId).addMany(items.map(it => ({
            item: JSON.stringify(it.item),
            itemId: it.item.id,
            count: it.amount,
            code: it.item.code,
            price: it.item.price,
            priceDiscount: (it.item as any).priceDiscount,
            zeroLeft: it.item.count > 0 ? 0 : 1,
            stock: it.item.count,
            bonusFactor: it.item.bonusFactor,
            maxBonusDiscountFactor: it.item.maxBonusDiscountFactor,
        })));
        this.updateTimestamp(userId, updatedAt);
        await this.refreshState(userId);
    }

    async removeAddress(addressId: number) {

        await request({
            method: 'DELETE',
            url: `/address/${addressId}`,
        });

        const idx = this._state.addresses.list.findIndex(a => a.id === addressId);
        const list = toJS(this._state.addresses.list);
        list.splice(idx, 1);
        this._store.setState({ cart: { addresses: { list } } });
    }

    get addresses() {

        if(!this._state.addresses) {
            request({
                method: 'GET',
                url: this.config.endpointUrl,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            })
            .then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.addresses;
    }

    itemsCount(userId: number, itemId: string): number {
        this.syncWithBackend(userId);

        return this._itemsByCode[itemId]?.quantity || 0;
    }

    async setItemAmount(item: Readonly<CatalogItem>, amount: number, userId: number) {

        await this.getBucket(userId).updateByCode(item.code, { count: amount });
        await this.refreshState(userId);

        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    async setManyAmount(items: { item: Readonly<CatalogItem>, amount: number }[], userId: number) {
        const bucket = this.getBucket(userId);
        const byIds = indexBy(items, v => v.item.id, (p, c) => p.amount += c.amount);
        await Promise.all(Object.values(byIds).map(it => bucket.updateByCode(it.item.id, { count: it.amount })));

        await this.refreshState(userId);
        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    private async _doAddWithAmount(item: Readonly<CatalogItem>, amount: number, userId: number) {
        if(amount <= 0) return;

        const bucket = this.getBucket(userId);

        const itemInBucket = await bucket.findByCode(item.id);
        if(itemInBucket) {
            this.checkFullItem(item.id);
            await bucket.updateByCode(item.id, {count: itemInBucket.count + amount});
            return;
        }

        await bucket.add({
            item: JSON.stringify(item),
            itemId: item.id,
            code: item.id,
            price: item.price,
            priceDiscount: (item as any).priceDiscount,
            zeroLeft: item.count > 0 ? 0 : 1,
            count: amount,
            stock: item.count,
        });
    }

    private async _doRemoveWithAmount(item: Readonly<CatalogItem>, amount: number, userId: number) {
        const bucket = this.getBucket(userId);

        const itemInBucket = await bucket.findByCode(item.id);
        if(!itemInBucket) return;

        if(itemInBucket.count <= amount) {
            await bucket.removeByCode(item.id);
            setTimeout(() => this.checkFullItem(item.id), 100); // setTimeout, чтобы не было установки галочки в UI сразу после удаления
            return;
        }

        await bucket.updateByCode(item.id, {count: itemInBucket.count - amount});
        this.checkFullItem(item.id)
    }

    async removeWithAmount(item: Readonly<CatalogItem>, amount: number, userId: number) {

        await this._doRemoveWithAmount(item, amount, userId);
        await this.refreshState(userId);
        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    async addWithAmount(item: Readonly<CatalogItem>, amount: number, userId: number) {
        if(amount <= 0) return;

        await this._doAddWithAmount(item, amount, userId);
        await this.refreshState(userId);
        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    async addManyWithAmount(items: { item: Readonly<CatalogItem>, amount: number }[], userId: number) {
        const byIds = indexBy(items, v => v.item.id, (p, c) => p.amount += c.amount);
        await Promise.all(Object.values(byIds).map(it => this._doAddWithAmount(it.item, it.amount, userId)));

        await this.refreshState(userId);
        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    async removeManyWithAmount(items: { item: Readonly<CatalogItem>, amount: number }[], userId: number) {
        const byIds = indexBy(items, v => v.item.id, (p, c) => p.amount += c.amount);
        await Promise.all(Object.values(byIds).map(it => this._doRemoveWithAmount(it.item, it.amount, userId)));

        await this.refreshState(userId);
        this.updateBackendDebounced(await this.updateTimestamp(userId), userId);
    }

    override async updateBackend(time: Dayjs, userId: number) {
        const items = await this.getBucket(userId).getAll();

        const fd = new FormData;
        fd.append('ts', time.unix()+'');
        if(items.length) {
            for(const item of items) {
                fd.append(`items[${item.code}]`, `${item.count}`);
            }
        }

        await axios.post(this.config.endpointUrl, fd);
    }

    override refreshState(userId: number) {
        return this.getBucket(userId).getAll()
        .then(list => {
            this._store.setState({
                [this.config.tableName]: {
                    items: { list: list.map(item => ({...JSON.parse(item.item), quantity: item.count })) }
                }
            });
        });
    }

    private _makeSummary(
        items: ReadonlyArray<Readonly<CatalogItem>>,
        promoGroups: ReadonlyArray<Readonly<Group>>,
        promoItems: ReadonlyArray<Readonly<Item>>,
    ) {

        const calc = (items: ReadonlyArray<Readonly<Item>>) => {
            let num = 0; let price = 0;
            for(const item of items) {
                num += item.quantity;
                if(item.price) {
                    price += item.price * Math.max(0, Math.min(item.quantity, item.count));
                }
            }
            return [price, num];
        }

        const [ priceWithoutDiscount, totalItems ] = calc(items);

        const baseCountById: Record<number, number> = {};
        let priceWithDiscount = 0;
        for(const g of promoGroups) {
            for(const it of g.items) {
                const realCount = Math.min(it.quantity, it.count);
                priceWithDiscount += it.price * realCount;
                baseCountById[it.id] = realCount;
            }
        }
        for(const it of promoItems) {
            const realCount = Math.min(it.quantity, it.count - (baseCountById[it.id] || 0));
            priceWithDiscount += it.price * realCount;
        }

        return {
            totalItems,
            priceWithDiscount,
            priceWithoutDiscount
        };
    }

    override get summary() {
        const rawItems = this.items()?.list;
        if(!rawItems) return rawItems as undefined;
        const groups = this.promoGroups;
        if(!groups) return groups as undefined;
        const items = this.promoItems;
        if(!items) return items as undefined;

        return this._makeSummary(rawItems, groups, items);
    }

    get checkedSummary() {
        const rawItems = this.checkedItems;
        if(!rawItems) return rawItems as undefined;

        const checkedGroups = this.checkedPromoGroups;
        if(!checkedGroups) return checkedGroups as undefined;
        const checkeditems = this.checkedPromoItems;
        if(!checkeditems) return checkeditems as undefined;

        return this._makeSummary(rawItems, checkedGroups, checkeditems);
    }

    get promoGroups() {
        return this._itemsWithPromoPass?.result.groups;
    }
    get promoItems() {
        return this._itemsWithPromoPass?.result.items;
    }

    get checkedPromoGroups() {
        return this._itemsWithPromoPass?.checkedResult.groups;
    }
    get checkedPromoItems() {
        return this._itemsWithPromoPass?.checkedResult.items;
    }


    private _makePromoPass(items: ReadonlyArray<Readonly<CatalogItem>>, user: Readonly<PredicateUser>, context: Readonly<PromoContext>) {
        const passed = pass(items.map(v => ({
            id: v.id, count: v.quantity, price: v.price, stock: v.count,
        })), user, context);

        const itemsById = indexBy(items, v => v.id);

        const toRealItem = (v: { id: string, count: number, price: number }): Item => {
            const item = itemsById[v.id];
            const newItem: Mutable<Item> = {...item, quantity: v.count };
            if(Math.abs(item.price - v.price) >= 0.01) {
                newItem.oldPrice = item.price;
                newItem.price = v.price;
            }

            return newItem;
        }

        const groups: Group[] = [];

        for(const group of passed.groups) {

            const list: Item[] = [];

            for(const item of group.affectedItems) {
                list.push(toRealItem(item));
            }

            groups.push({
                promoId: group.promoId,
                name: context.promoList[group.promoId].name,
                items: list,
                count: group.count,
            });
        }

        return { groups, items: passed.items.map(v => ({
                ...toRealItem(v),
                baseCount: itemsById[v.id].quantity - v.count,
            })
        )};
    }

    get _itemsWithPromoPass() {
        const user = this._store.user.user;
        if(!user) return user as undefined;

        const items = this.items()?.list;
        if(!items) return items as undefined;
        const context = this._store.catalog.promoContext;
        if(!context) return context as undefined;

        const checkedItems = this.checkedItems;
        if(!checkedItems) return checkedItems as undefined;

        const bonusesEnabled = this._store.bonus.status?.enabled;
        if(bonusesEnabled === undefined) return bonusesEnabled as undefined;

        const u = user;
        const passUser = {
            id: u.id, type: u.type, matrixCode: u.matrixCode,
            parentPriceCode: u.parentPriceCode, bonusesEnabled
        };

        const itemsRes =   this._makePromoPass(items, passUser, context);
        const checkedRes = this._makePromoPass(checkedItems, passUser, context);

        return {
            result: itemsRes,
            checkedResult: checkedRes,
        };
    }

    get checkedItemsById(): Readonly<Record<number, number>> {
        const byId = this._itemsByCode;

        const unchecked = this._uncheckedItems;
        const checked: Record<number, number> = {};

        for(const id in byId) {
            checked[id] = byId[id].quantity - (unchecked[id] || 0);
        }

        return checked;
    }

    get checkedItems() {
        const items = this.items()?.list;
        if(!items) return items as undefined;

        const checked = this.checkedItemsById;

        const res: CatalogItem[] = [];
        for(const item of items) {
            const quantity = checked[item.id];
            if(quantity <= 0) continue;

            res.push({...item, quantity});
        }

        return res;
    }

    get bonusSummary() {
        const user = this._store.user.user;
        if(!user) return user as undefined;

        const bonusStatus = this._store.bonus.status;
        if(bonusStatus === undefined) return bonusStatus as undefined;

        const promoItems = this.checkedPromoItems;
        if(promoItems === undefined) return promoItems as undefined;

        const promoGroups = this.checkedPromoGroups;
        if(promoGroups === undefined) return promoGroups as undefined;

        const context = this._store.catalog.promoContext;
        if(!context) return context as undefined;

        const items: BonusItem[] = [];
        for(const g of promoGroups) {
            for(const it of g.items) {
                items.push({ 
                    id: it.id, 
                    outOfStock: it.count <= 0, 
                    price: it.price, 
                    quantity: it.quantity,
                    bonusFactor: it.bonusFactor,
                    maxDiscountFactor: it.maxBonusDiscountFactor,
                });
            }
        }
        for(const it of promoItems) {
            items.push({ 
                id: it.id, 
                outOfStock: it.count <= 0, 
                price: it.price, 
                quantity: it.quantity,
                bonusFactor: it.bonusFactor,
                maxDiscountFactor: it.maxBonusDiscountFactor,
            });
        }

        const passUser: PredicateUser = {
            id: user.id, type: user.type, matrixCode: user.matrixCode, 
            parentPriceCode: user.parentPriceCode, bonusesEnabled: bonusStatus.enabled,
        };

        
        const info = calculateOrderBonusInfo(items, bonusStatus.balance, passUser, context);

        return info;
    }

    checkFullItem(id: string) {
        delete this._uncheckedItems[id];
    }

    checkItem(id: string, checked: number) {
        this.checkItems([{id, checked}]);
    }

    checkItems(items: {id: string, checked: number}[]) {
        const byId = this._itemsByCode;

        for(const v of items) {
            const item = byId[v.id];

            if(v.checked >= item.quantity)
                delete this._uncheckedItems[v.id];
            else
                this._uncheckedItems[v.id] = item.quantity - v.checked;
        }
    }

    get isAllChecked() {
        return Object.keys(this._uncheckedItems).length === 0;
    }

    get isAllUnchecked() {
        const byId = this._itemsByCode;
        const unchecked = this._uncheckedItems;

        for(const id in byId) {
            if(!unchecked[id] || unchecked[id] < byId[id].quantity)
                return false;
        }

        return true;
    }

    checkAll() {
        this._uncheckedItems = {};
    }

    uncheckAll() {
        const byId = this._itemsByCode;
        this._uncheckedItems = {};
        const unchecked = this._uncheckedItems;

        for(const id in byId)
            unchecked[id] = byId[id].quantity;
    }

}

export const Context = createContext<Cart>(undefined);