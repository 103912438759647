import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import * as React from "react";

export type Breadcrumb = {
    readonly name: string;
    readonly to?: string;
};

type Props = {
    readonly items: Breadcrumb[];
}

const Breadcrumbs = (p: Props) => {

    return (
        <MuiBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ py: 2, display: 'inline-flex' }}
        >
            {p.items.map((b, index) => {
                return b.to ? (
                    <Link key={index} to={b.to}>
                        <Typography sx={{ color: "#8F95B2", transition: "0.5s", '&:hover': { textDecoration: "underline", fontWeight: "bold" } }}>{b.name}</Typography>
                    </Link>
                ) : (
                    <Typography key={index} color="text.primary">
                        <Typography sx={{ color: "#8F95B2", transition: "0.5s", '&:hover': { textDecoration: "underline", fontWeight: "bold" } }} > {b.name}</Typography>
                    </Typography>
                )
            })}
        </MuiBreadcrumbs >
    );
}

export default Breadcrumbs;