import {
    Box, Button,
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    InputAdornment, Link,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import EmailCheckingIcon from "@mui/icons-material/HourglassTop";
import EmailErrorIcon from "@mui/icons-material/PriorityHighRounded";
import EmailNotConfirmedIcon from "@mui/icons-material/WarningAmberRounded";
import EmailConfirmedIcon from "@mui/icons-material/Done";
import { APIError, AppContext } from "../../../store/app";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { CanceledError } from "axios";
import { NotificationsContext } from "../../../store";
import { LoadingButton } from "@mui/lab";
import SuccessIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { observer } from "mobx-react-lite";
import img from "../../../images/waitList.png"
import { Link as RouterLink, } from "react-router-dom";


let checkEMailAbort: AbortController;
let notifyMeAbort: AbortController;

function ConfirmationDialog(p: { email: string, onSendSuccess: () => void, onSendFail: () => void }) {
    const app = useContext(AppContext);
    const notification = useContext(NotificationsContext);

    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    return (<>
        {!isSent && (<>
            <Typography variant="subtitle1">Подтверждение адреса E-Mail</Typography>
            <br />
            <Typography>
                Для подтверждения электронной почты мы вышлем сообщение по адресу <strong>{p.email}</strong>. <br />
                После нажатия кнопки ниже проверьте указанный почтовый ящик и следуйте дальнейшим указаниям. <br /><br />

                <LoadingButton
                    size="medium"
                    variant="outlined"
                    color="primary"
                    loading={isSending}
                    onClick={() => {
                        setIsSending(true);
                        notification.sendValidationEmail(p.email)
                            .then(() => {
                                setIsSent(true);
                                p.onSendSuccess();
                            })
                            .catch((e: APIError | CanceledError<any>) => {
                                if (e instanceof CanceledError) return;

                                p.onSendFail();
                                if (e.response?.status >= 500)
                                    app.alert('Непредвиденная ошибка', { severity: 'error' });
                            })
                            .finally(() => setIsSending(false))
                            ;
                    }}
                >
                    Отправить сообщение с подтверждением
                </LoadingButton>
            </Typography>
        </>)}
        {isSent && (<>
            <Typography variant="subtitle1">Успешно! </Typography>
            <br />
            <SuccessIcon color="success" sx={{ fonsSize: 54 }} />
            <br />
            <Typography>
                Сообщение с подтверждением E-Mail успешно отправлено по адресу <strong>{p.email}</strong>. <br />
                Проверьте указанный почтовый ящик. Когда выполните действия из полученного письма, обновите эту вкладку, чтобы увидеть прикрепленный адрес. <br /><br />
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={() => window.location.reload()}
                >
                    Обновить страницу
                </Button>
            </Typography>
        </>)}

    </>);
}

const EmailButtonCart = () => {

    const app = useContext(AppContext);
    const notifications = useContext(NotificationsContext);
    const notify = notifications.notify;
    const theme = useTheme()
    const [email, setEmail] = useState('');

    const [notifyMe, setNotifyMe] = useState(!!notify?.enabled);
    const [emailState, setEmailState] = useState<'notEntered' | 'checking' | 'incorrect' | 'notVerified' | 'verifying' | 'verified'>('notEntered');

    useEffect(() => {

        if (notify?.email) {
            setEmail(notify.email);
            setEmailState('verified');
        }
        if (notify?.enabled)
            setNotifyMe(notify.enabled);

    }, [notify]);

    const checkEmailDebounced = useMemo(() => debounce((email) => {
        checkEMailAbort = new AbortController();
        notifications.checkEmail(email,
            { signal: checkEMailAbort.signal }
        )
            .then(ok => setEmailState(ok ? 'notVerified' : 'incorrect'))
            .catch((e: APIError | CanceledError<any>) => {
                if (e instanceof CanceledError) return;

                setEmailState('incorrect');
                app.alert('Непредвиденная ошибка', { severity: 'error' });
            });

    }, 2000), [1]);

    const setNotifyMeDebounced = useMemo(() => debounce(
        async (enabled: boolean) => {
            notifyMeAbort = new AbortController();

            await notifications.setNotificationsEnabled(enabled, { signal: notifyMeAbort.signal });
        },
        1000),
        [1]
    );

    useEffect(() => {
        if (notifyMeAbort) {
            notifyMeAbort.abort();
            notifyMeAbort = undefined;
        }
        setNotifyMeDebounced.cancel();
        if (!notify || notify.enabled === notifyMe) return;

        if (notify.email && email !== notify.email)
            setEmail(notify.email);

        setNotifyMeDebounced(notifyMe);



    }, [notifyMe, notify]);

    useEffect(() => {
        if (checkEMailAbort) {
            checkEMailAbort.abort();
            checkEMailAbort = undefined;
        }
        checkEmailDebounced.cancel();
        if (!notify) return;
        if (notify.email === email) {
            setEmailState('verified');
            return;
        }

        if (!email.length) {
            setEmailState('notEntered');
            return;
        }

        setEmailState('checking');
        checkEmailDebounced(email);

    }, [email]);

    return (
        <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", smm: "row" }, alignItems: "center" }}>
            <Box
                component="img"
                src={img}
                sx={{
                    width: { xs: "100%", smm: 350 },
                    objectFit: 'contain'
                }}
            />
            <Stack direction="column" maxWidth={300}>
                <Typography color={theme.palette.warning.main} fontWeight="bold" fontSize={30} pt="15px">Упс!</Typography>
                <Typography py="15px">Товар уже раскупили. Но мы добавили его в лист ожидания. Оставьте свою почту, чтобы не пропустить поступление</Typography>
                <Collapse in={notifyMe}>
                    <Box>
                        <TextField
                            label="E-Mail"
                            name="email"
                            value={email}
                            onChange={e => setEmail(e.target.value.trim())}
                            sx={{ my: 1, width: '100%' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {emailState == 'checking' && (<EmailCheckingIcon color="info" />)}
                                        {emailState == 'incorrect' && (<EmailErrorIcon color="error" />)}
                                        {['notVerified', 'verifying'].includes(emailState) && (<EmailNotConfirmedIcon color="warning" />)}
                                        {emailState == 'verified' && (<EmailConfirmedIcon color="success" />)}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Typography variant="body1" color="text.secondary">
                        {emailState == 'notEntered' && <>
                            Укажите почту, чтобы продолжить.
                        </>}
                        {emailState == 'checking' && <>
                            Секундочку, мы проверяем ваш E-Mail на правильность...
                        </>}
                        {emailState == 'incorrect' && <>
                            Данный адрес E-Mail неправильный. Пожалуйста, убедитесь, что в нем нет опечаток
                        </>}
                        {emailState == 'notVerified' && <>
                            Чтобы убедиться, что этот E-Mail принадлежит вам, мы вышлем на него сообщение с
                            подтверждения. После этого вы сможете получать на него уведомления
                        </>}
                        {emailState == 'verifying' && <>
                            На этот E-Mail выслано сообщение с подтверждением. Пожалуйста, проверьте вашу почту, чтобы
                            подтвердить его и начать получать уведомления.
                        </>}
                        {emailState == 'verified' && <>
                            На этот E-Mail мы будем присылать вам уведомления.
                        </>}
                    </Typography>
                </Collapse>
                <FormGroup>
                    <FormControlLabel
                        sx={{ '& .MuiTypography-root': { fontSize: "12px" } }}
                        control={<Checkbox disabled={!notify} checked={notifyMe} onChange={e => setNotifyMe(e.target.checked)} />}
                        label="Получать уведомления о поступлении"
                    />
                </FormGroup>
                <Box sx={{ display: "flex", gap: 1, '& .MuiButton-root': { minWidth: 51 } }}>
                    <Button disabled={emailState == 'notVerified' ? false : true} variant="contained" sx={{ backgroundColor: "#3F8CFF", color: "white" }}>
                        <Link
                            underline="none"
                            sx={{ color: "white" }}
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                app.showDialog(
                                    <ConfirmationDialog
                                        email={email}
                                        onSendSuccess={() => setEmailState('verifying')}
                                        onSendFail={() => setEmailState('notVerified')}
                                    />
                                );
                            }}
                        >
                            Подвердить почту
                        </Link>
                    </Button>
                    <Box component={RouterLink} to="/waitList">
                        <Button variant="contained" sx={{ backgroundColor: "#8F95B2", color: "white" }}>Лист ожидания</Button>
                    </Box>
                </Box>
            </Stack>
        </Box>

    );
};

export default observer(EmailButtonCart);