import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
} from "@mui/material";
import { createElement, FC} from "react";
import { Order } from "../index";
import { observer } from "mobx-react-lite";
import { ListItemItem, Patch } from "../../../../components/List";
import TableHeadRow from "./TableHead/TableRow";

type Props = {
    readonly items: ListItemItem<Order>[];
    readonly component: FC<{ readonly item: Order, readonly events?: Record<string, CallableFunction>, patch?: any }>;
    readonly events?: Record<string, CallableFunction>;
    readonly patch?: Patch;
    readonly keyResolver: (item: any) => number | string;
}

const ListTableRetail: FC<Props> = ({ items, component, events, patch, keyResolver }) => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead
                    sx={{
                        '& .MuiTableCell-root.MuiTableCell-head': {
                            backgroundColor: "rgba(108, 93, 211, 0.10)",
                            color: "#8F95B2",
                            fontSize: "14px",
                            fontWeight: "bold"
                        }
                    }}
                >
                    <TableHeadRow />
                </TableHead>
                <TableBody>
                    {items.map(item => {
                        const key = keyResolver(item);
                        const p = patch && patch[key];

                        return createElement(component, { item: item.item, key, events, patch: p });
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default observer(ListTableRetail);