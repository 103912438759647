import { FC, SyntheticEvent, useContext, useRef, useState } from "react";
import { Link as RouterLink, } from "react-router-dom";

import { Box, useTheme, Button, Typography, Link, Accordion, AccordionSummary, AccordionDetails, styled, TooltipProps, Tooltip, tooltipClasses, } from "@mui/material";
import NoImageIcon from "../../icons/noimage.svg"
import { Tree, TreeLeaf } from "../../common/tree";
import { CatalogContext, UserContext } from "../../store";
import LoadingPaper from "../LoadingPaper";
import { observer } from "mobx-react-lite";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubCategoriesIcon from "../../icons/catalogSubcategories.svg"
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
    readonly tree?: Tree;
    readonly sector?: string;
    readonly path?: string;
    readonly basePath?: string;
    readonly filters?: Record<number, any>;
    readonly onFiltersChange?: (value: Record<number, any>) => void;
    readonly search?: string;
    readonly group?: string;
    readonly sort?: string;
    readonly filterDisabled?: boolean;
    readonly loadNextPage?: () => void;
};

const TooltipStyle = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#e6e8f0',
        color: 'black',
        borderRadius: "1rem",
        paddingRight: "20px",
        width: "14rem"
    },
}));

const onLink = (n: TreeLeaf): string => {
    if (!Object.keys(n.children).length) return n.aliasfull;

    return onLink(Object.values(n.children)[0]);
}

const Catalog: FC<Props> = p => {

    const theme = useTheme()
    const catalog = useContext(CatalogContext);
    const user = useContext(UserContext);

    const topCategories = catalog.getTreeLevel(p.sector)?.list;
    const topCategoriesObj = Object.keys(topCategories || {})
    const path = p.path || topCategoriesObj[0];
    const topCategory = path && topCategories && topCategories[path.split('/', 2)[0]];

    const [expanded, setExpanded] = useState<string | false>(path);

    const handleChange =
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    //       to={Object.keys(n.children).length ? `/catalog/` + Object.values(n.children).map(q => Object.values(q.children)[0]?.aliasfull) : `/catalog/${n.aliasfull}`}
    return (<>
        <Box sx={{ ml: 1, borderRadius: "16px" }} >
            {topCategories ? (<>
                {Object.values(topCategories).map(n => (
                    <Accordion
                        key={n.id}
                        disableGutters
                        expanded={expanded === n.alias}
                        onChange={handleChange(n.alias)}
                        sx={{
                            boxShadow: 'none',
                            '&:before': {
                                display: 'none',
                            },
                            '& .MuiAccordionSummary-root ': { minHeight: 40, marginRight: 1 }
                        }}
                    >
                        <Typography
                            component={RouterLink}
                            to={'/catalog/' + (
                                Object.values(n.children).length
                                    ? onLink(n)
                                    : n.aliasfull
                            )}
                        >
                            <AccordionSummary
                                expandIcon={Object.keys(n.children).length ? <ExpandMoreIcon /> : undefined}
                                aria-controls={`ac-prop-${n}-content`}
                                id={`ac-prop-${n}-header`}
                                sx={{ padding: 0, '& .MuiAccordionSummary-content': { margin: "0" } }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: '#8F95B2',
                                        p: 0,
                                    }}
                                >
                                    {n.imgList ? (
                                        <Box
                                            component="img"
                                            src={n.imgList}
                                            sx={{
                                                width: "20px",
                                                height: "20px",
                                                objectPosition: 'center',
                                                marginRight: 1,
                                            }}
                                        />
                                    ) : (
                                        <Box marginRight="9px">
                                            <NoImageIcon width="20px" height="20px" />
                                        </Box>
                                    )}
                                    <Button
                                        sx={{
                                            textAlign: "start",
                                            color: n.alias === topCategory?.alias ? "black" : "#8F95B2",
                                            fontWeight: n.alias === topCategory?.alias ? "bold" : "normal",
                                            fontSize: "1rem",
                                            transition: "0.3s",
                                            '&:hover': { textDecoration: "underline" }
                                        }}
                                    >
                                        {n.label}
                                    </Button>
                                </Box>
                            </AccordionSummary>
                        </Typography>
                        {
                            Object.values(n.children)?.map(b => (
                                <TooltipStyle
                                    key={b.id}
                                    placement="right-end"
                                    title={!Object.values(b.children).length ? '' : Object.values(b.children).map(t => (
                                        <Box
                                            key={t.id}
                                            sx={{
                                                pl: 1,
                                                py: 1,
                                            }}>
                                            <Link
                                                underline="none"
                                                component={RouterLink}
                                                to={`/catalog/${t.aliasfull}`}
                                                sx={{
                                                    color: "#8F95B2",
                                                    borderRadius: "0 16px 16px 0",
                                                    '&:hover': { color: theme.palette.info.contrastText },
                                                    justifyContent: "start",
                                                    textAlign: "start",
                                                    width: "max-content",
                                                    maxWidth: "200px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 1,
                                                        color: "black",
                                                        "&:hover": { borderRadius: "14px", fontWeight: "bold", backgroundColor: "white" }
                                                    }}
                                                >
                                                    <ArrowIcon sx={{ width: "10px" }} />
                                                    <Typography
                                                        sx={{
                                                            fontWeight: t.aliasfull === `${path}/` ? "bold" : "normal",
                                                            fontSize: "0.8rem",
                                                            lineHeight: "200%"
                                                        }}
                                                    >
                                                        {t.label}
                                                    </Typography>
                                                </Box>
                                            </Link >
                                        </Box>
                                    ))}
                                >
                                    <AccordionDetails key={b.id} sx={{ padding: 0, mb: "5px", ml: 1 }} >
                                        <TooltipStyle
                                            placement="right"
                                            title={''}
                                            sx={{ "& .MuiBox-root": { border: "none" }, "& .MuiTypography-root": { color: "black" } }}
                                        >
                                            <Box display="flex" gap={1}>
                                                <SubCategoriesIcon />
                                                <Typography
                                                    component={RouterLink}
                                                    to={Object.keys(b.children).length > 0 ? (`/catalog/` + Object.values(b.children)[0].aliasfull) : `/catalog/${b.aliasfull}`}
                                                    sx={{
                                                        mr: 1,
                                                        fontSize: "0.81rem",
                                                        color: path.startsWith(b.aliasfull.substring(0, b.aliasfull.length - 1)) ? "black" : "#8f95b2",
                                                        lineHeight: "200%",
                                                        fontWeight: path.startsWith(b.aliasfull.substring(0, b.aliasfull.length - 1)) ? "bold" : "normal",
                                                        '&:hover': { textDecoration: "underline", fontWeight: "bold" }
                                                    }}
                                                >
                                                    {b.label}
                                                </Typography>
                                            </Box>
                                        </TooltipStyle>
                                    </AccordionDetails>
                                </TooltipStyle >
                            ))
                        }
                    </Accordion >
                ))
                }
            </>) : (<LoadingPaper />)}

        </Box >
    </>);

}

export default observer(Catalog);