import {FC, useContext} from "react";
import WideCard from "../WideCard";
import { Item } from "../../../common/item";
import WideCardStepperAction from "../WideCardStepperAction";
import {Box, Checkbox, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {BonusContext} from "../../../store";
import dayjs from "dayjs";

type Props = {
    readonly item: Item;
    readonly onRemoveFromCartClick?: () => void;
    readonly onFavoritesClick: () => void;
    readonly onCountChange?: (value: number) => void;
    readonly count: number;
    readonly disallowOverstock?: boolean;
    /** Указывается, если этот товар уже добавлен в корзину в рамках какой-либо группы */
    readonly baseCount?: number;
    readonly favorite: boolean;
    readonly checked?: boolean;
    readonly onCheckChange?: (checked: boolean) => void;
    readonly onClick?: () => void;
}

const CartWideCard: FC<Props> = (p) => {
    const baseCount = p.baseCount || 0;
    const realStock = Math.max(0, p.item.count - baseCount) || undefined;
    const absentCount = Math.max(p.count - realStock, 0) || undefined;

    const bonus = useContext(BonusContext);
    const bonusValue = bonus.countBonusForItem({
        id: p.item.id,
        outOfStock: p.item.count <= 0,
        price: p.item.price,
        quantity: p.count,
        bonusFactor: p.item.bonusFactor,
        maxDiscountFactor: p.item.maxBonusDiscountFactor,
    });

    return (<Box sx={{ display: 'flex' }}>
        <WideCard
            sx={{ flexGrow: 1 }}
            isPromotional={!!p.item.isPromotional}
            imgSrc={p.item.imgList}
            label={p.item.label}
            onFavoritesClick={p.onFavoritesClick}
            favorite={p.favorite}
            articl={p.item.articl}
            cnpCode={p.item.cnp_code}
            packageSize={p.item.pkg}
            stock={p.item.count}
            defaultStock={p.item.stockDefaultCount}
            defaultStockDate={p.item.stockDefaultDate ? dayjs(p.item.stockDefaultDate) : undefined} // TODO: Костыль для уже добавленных в корзину товаров
            inCartCount={baseCount}
            count={p.count}
            onClick={p.onClick}
            action={
                <>
                    <WideCardStepperAction
                        isPromotional={!!p.item.isPromotional}
                        disallowOverstock={p.disallowOverstock}
                        count={p.count}
                        baseCount={baseCount}
                        price={p.item.price}
                        oldPrice={p.item.oldPrice}
                        stock={p.item.count}
                        onCountChange={p.onCountChange}
                        onRemoveFromCartClick={p.onRemoveFromCartClick}
                        outOfStock={p.item.count <= 0}
                        bonusValue={bonusValue}
                    />
                </>
            }
        >
            {realStock && absentCount && (<Box sx={{ mt: 1 }}>
                <Typography color="error">В наличии {realStock}, под заказ {absentCount} </Typography>
                <Typography>
                    По стоимости и срокам доставки недостающих единиц уточняйте у оператора
                </Typography>
            </Box>)}
            {!!p.item.isPromotional && (
                <Typography color="text.secondary">
                    Этот товар не подлежит продаже вне рамок акции.
                </Typography>
            )}
        </WideCard>
        {p.checked !== undefined && (
            <Checkbox
                sx={{ alignSelf: 'center' }}
                onClick={e => e.stopPropagation()}
                checked={p.checked}
                onChange={p.onCheckChange && (e => {
                    p.onCheckChange(e.target.checked);
                })}
            />
        )}
    </Box>);
}

export default observer(CartWideCard);