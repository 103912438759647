import {Box, Slider, Stack, styled, TextField} from "@mui/material";
import * as React from "react";
import {BaseProp} from "../index";
import {FC} from "react";

export type RangeProp = BaseProp & {
    type: 'range';
    from?: number;
    to?: number;
    settings: {
        min: number;
        max: number;
    }
};

type Props = {
    readonly prop: RangeProp;
    readonly onChange?: (e: any, from: number, to: number) => void;
};

const RangeField = styled(TextField)({
    '& input': {
        margin: 0,
        backgroundColor: 'transparent',
    },
});

const Range: FC<Props> = (props) => {
    const { prop } = props;
    const { settings, from, to } = prop;

    return (<Stack spacing={2} sx={{px:2, mt: 1}}>
        <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            divider={<span>-</span>}
        >
            <RangeField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                placeholder={`${settings.min} ₽`}
                size="small"
                variant="outlined"
                value={from ?? ''}
                onChange={(e) => {
                    if (!props.onChange) return;

                    props.onChange(e, +e.target.value || undefined, to);
                }}
            />
            <RangeField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                placeholder={`${settings.max} ₽`}
                size="small"
                variant="outlined"
                value={to ?? ''}
                onChange={(e) => {
                    if (!props.onChange) return;

                    props.onChange(e, from, +e.target.value || undefined);
                }}
            />
        </Stack>
        <Box sx={{px: 1}}>
            <Slider
                getAriaLabel={() => 'Диапазон цены'}
                min={settings.min}
                max={settings.max}
                value={[from ?? settings.min, to ?? settings.max]}
                onChange={(e, v) => {
                    if (!props.onChange) return;
                    const [from, to] = v as number[];
                    props.onChange(e, from, to);
                }}
                valueLabelDisplay="auto"
                getAriaValueText={() => 'Text'}
            />
        </Box>
    </Stack>);
};

export default Range;